import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
});

const theme = {
  ...rawTheme,
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '5rem' //'6rem'
      }
    },
    h2: {
      ...rawTheme.typography.h2,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '3rem' //'3.75rem'
      }
    },
    h3: {
      ...rawTheme.typography.h3,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '2.5rem' //'3rem'
      }
    },
    h4: {
      ...rawTheme.typography.h4,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.75rem' //'2.125rem'
      }
    },
    h5: {
      ...rawTheme.typography.h5,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem' // '1.5rem'
      }
    },
    h6: {
      ...rawTheme.typography.h6,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.125rem' // '1.25rem'
      }
    },
  },
};

export default theme;
