import { combineReducers } from 'redux'
import { alphaReducer } from '../alpha'
import trainerModelReducer from './reducers/trainer'
import courseModelReducer from './reducers/course'

export const appReducer = combineReducers({
  alpha: alphaReducer,
  trainer: trainerModelReducer,
  course: courseModelReducer
})
