import React, { Component, useState, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import { connect } from 'react-redux'
import { Route, Link, withRouter, BrowserRouter as Router, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { getCourseIndex, fetchCourseIndex } from './data/reducers/course'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { getBusinessInfo, fetchBusinessInfo } from './alpha/data/reducers/business'

import { withAppBase } from './alpha'
import NavigationController, { NavigationContext } from './components/ui/NavigationController'

import Header from './Header'
import BottomNavBar from './BottomNavBar'

import Home from './pages/Home'
import User from './pages/User'
import Inbox from './pages/Inbox'
import Chat from './pages/Chat'
import Story from './pages/Story'
import Page from './pages/Page'
import Article from './pages/Article'
import Course from './pages/Course'
import CourseDetail from './pages/CourseDetail'
import Trainer from './pages/Trainer'
import NotFound from './pages/NotFound'
import TrainerProfile from './pages/TrainerProfile'
import Footer from './pages/Footer'
import ArticleDetail from './pages/ArticleDetail'


const styles = theme => ({
  root: {
//    width: '100%',
//    display: 'flex',
//    justifyContent: 'center'
  },
  '@global': {
    '.MuiTypography-body1': {
      lineHeight:'1.4',
    },
    '.MuiRating-root':{
      color: '#4b62e3'
    },
    '.MuiRating-root.Mui-disabled':{
      opacity: '1'
    },
    '.MuiDrawer-paperAnchorRight':{
      [theme.breakpoints.down('xs')]:{
        left: 0
      }
    },
    '.MuiTypography-colorTextSecondary':{
      [theme.breakpoints.down('xs')]:{
        color: 'inherit'
      }
    },
    '.MuiDialog-paperFullWidth':{
      [theme.breakpoints.down('xs')]:{
        width: '100%',
        height: 'auto'
      }
    },
    '.MuiDialog-paper':{
      [theme.breakpoints.down('xs')]:{
        margin: 0
      }
    }
  },
})

const Main = ({ params, theme, classes, history, business, fetchCoursesList }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  const navigationContext = useContext(NavigationContext)  
      
  useEffect(() => {  
    fetchCoursesList()
  })
    
  return (      
    <div className={classes.root}>
      <Switch>
        <Route exact path="/" component={Page}/>
        <Route path="/story/:storyKey" component={Page}/>
        <Route exact path="/page/:pageKey" component={Page}/>
        <Route path="/page/:pageKey/story/:storyKey" component={Page}/>
        
        <Route path="/blog/story/:storyKey" component={Home}/>
        <Route path="/blog/:categoryKey/story/:storyKey" component={Home}/>
        <Route path="/blog/:categoryKey/product/:productKey" component={Home}/>
        <Route path="/blog/:categoryKey" component={Home}/>
        <Route path="/blog" component={Home}/>
        
        <Route path="/trainer" exact component={Trainer}/>
        <Route path="/trainer/:profileKey" component={TrainerProfile}/>
        {/* <Route path="/trainer/:profileKey/course/:topicKey/:courseKey" component={TrainerProfile}/>
        <Route path="/trainer/:profileKey/article/:topicKey/:articleKey" component={TrainerProfile}/> */}

        <Route exact path="/article" component={Article}/>
        {/* <Route exact path="/article/:articleKey" component={Article}/> */}
        <Route path="/article/:articleKey" component={ArticleDetail}/>
        {/* <Route path="/article/:topicKey" component={Article}/>
        <Route path="/article/:topicKey/:articleKey" component={Article}/>
        <Route path="/article/:topicKey/:articleKey/trainer/:trainerKey" component={Article}/> */}

        <Route path="/course" exact component={Course}/>
        <Route path="/course/:topicKey" exact component={Course}/>
        <Route path="/course/:topicKey/:courseKey" component={CourseDetail}/>
        {/* <Route path="/course/:topicKey/:courseKey/trainer/:trainerKey" component={CourseDetail}/> */}

        <Route path="/inbox" component={Inbox}/>
        <Route path="/chat/:key" component={Chat}/>
        
        <Route path="/user" component={User}/>
        
        <Route path="/pay_callback" component={Page}/>
        <Route component={NotFound}/>
      </Switch>
    </div>
  )
}

const App = (props) => {
  return (
    <NavigationController
      header={(<Header business={ props.business.data } />)}
      bottomNavBar={(<BottomNavBar />)}
      >
      <Main
        { ...props }
        />
      <Footer business={ props.business.data }/>
    </NavigationController>
  )
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)

  return {
    params: urlParams,
    user: state.user,
    visitor: state.visitor,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
//  let callbackUrl = process.env.REACT_APP_PUBLIC_URL + ownProps.location.path
  let callbackUrl = window.location.href
//  alert(callbackUrl)
  
  return {
    fetchCoursesList: (refresh=false) => {
      dispatch(fetchCourseIndex('courses', 'default', null, null, refresh))
    },
  }
}

export default withAppBase(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(App))
  )
)
