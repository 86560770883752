import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import NextArrow from '../ui/NextArrow'
import PrevArrow from '../ui/PrevArrow'
import CourseTopic from '../cards/CourseTopic';
import Fade from 'react-reveal/Fade';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  Link
} from "react-router-dom";

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionCategoryCourse:{
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    [theme.breakpoints.down('xs')]:{
      // marginBottom: theme.spacing(1)
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 1280,
    zIndex: 0,
    margin:"0 auto",
    [theme.breakpoints.down('xs')]:{
      display: 'none',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      padding: 0,
    }
  },
  titleSection:{
      
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    marginTop: '70px',
    color: 'white',
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      display: 'block',
      marginBottom: 0,
      marginTop: theme.spacing(4)
    }
  },
  totalCourses:{
    color: 'white',
    fontWeight: '100',
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      display: 'block !important'
    }
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308',
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      display: 'block !important'
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight:'bold'
  },
  activeItem: {
    background: '#3f51b5',
    color: 'white'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CategoryCourse = ({ classes, topicKey ,background_images, courseList, history, courseDefaultList }) => {

    const [open, setOpen] = React.useState(false);
    let keys = ''
    if(courseDefaultList.data.records){
      keys = courseDefaultList.data.records.categories.find(({ key }) => key === topicKey)
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const chooseTopic = (key) => {
      history.push('/course/'+key)
      setOpen(false)
    }
    
    const settings = {
      initialSlide: keys ? keys.id-1 : 0,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 3,
      variableWidth: true,
      nextArrow: <NextArrow type='course' />,
      prevArrow: <PrevArrow type='course'/>,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            // slidesToShow: 1,
            // slidesToScroll: 2,
            arrows: false,
            rows: 1,
            slidesPerRow: 2,
            dots: true,
            centerMode: true,
            infinite: true,
            centerPadding: "30px",
            slidesToShow: 2,
          }
        },
      ]
    };

    let title = '';
    // let background = ['c9b04eff','4ea6caff','940505ff','540694ff','089481ff','1b9409ff']
    let categoryName = topicKey !== null ? topicKey : 'all-topics'; 
    let topics = topicKey !== null ? topicKey : 'default';
    let backgroundImage = background_images !== undefined ? background_images : 'https://media.pasartrainer.com/45104640091720/image/content/contentfield/9dd1aa2c-df97-4b3d-8547-7fd82283f590.jpeg'
    return (
        <div className={clsx(classes.sectionCategoryCourse,classes.dark)} style={{backgroundImage:`url(`+backgroundImage+`)`}}>
            <Container>
            <div styles={{background:"url(/card_4ea6ca_active.png)",display:'none'}} />
                <div className={classes.titleSection}>
                    <Slider {...settings} className={classes.carouselItem}>
                    { courseDefaultList.lastFetched &&
                      courseDefaultList.data.records.categories.map(item => {
                        // item.background = (background[Math.floor(Math.random() * background.length-2)] !== undefined ? background[Math.floor(Math.random() * background.length)] : '4ea6caff')
                        return <CourseTopic item={item} history={history} topicKey={topics}/>
                      }
                        // <Fade>
                        // </Fade>
                    )
                    }
                    </Slider>
                    {courseList.data.records &&
                    <>
                    {categoryName === 'all-topics' &&
                    <Fade>
                      <Typography variant='h2' className={classes.categoryName}>
                          All Topics
                      </Typography>
                    </Fade>
                    }
                    {categoryName === 'default' &&
                    <Fade>
                      <Typography variant='h2' className={classes.categoryName}>
                          All Topics
                      </Typography>
                    </Fade>
                    }
                    {categoryName !== 'all-topics' &&  categoryName !== 'default' &&
                    <Fade>
                    <Typography variant='h2' className={classes.categoryName}>
                        {courseList.data.records.categories[0].name}
                    </Typography>
                    </Fade>
                    }
                    <Typography variant='h4' className={classes.totalCourses}>
                    {courseList.data.records ?
                      courseList.data.records.courses.length +' Courses' : ''
                    }
                    </Typography>
                    {/* {categoryName !== 'all-topics' &&
                      <Link to='/course' className={classes.viewAll}> */}
                        <Fade bottom>
                          <Typography variant='h5' className={classes.viewAll} onClick={handleClickOpen}>
                            Browse Different Topic
                          </Typography>
                        </Fade>
                      {/* // </Link> */}
                    {/* } */}
                    </>
                  }
                </div>
            </Container>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    Choose a Topic
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <List>
              { courseDefaultList.lastFetched &&
                courseDefaultList.data.records.categories.map(item =>
                  <>
                  {topics === item.key &&
                  <>
                    <ListItem button onClick={() => chooseTopic(item.key)}>
                      {item.key === 'default' &&
                        <ListItemText primary="All Topics" secondary={item.size+" Courses"}/>
                      }
                      {item.key !== 'default' &&
                        <ListItemText primary={item.name} secondary={item.size+" Courses"}/>
                      }
                      <ListItemSecondaryAction>
                        <CheckIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider></Divider>
                    </>
                  }
                  {topics !== item.key &&
                  <>
                    <ListItem button onClick={() => chooseTopic(item.key)}>
                      {item.key === 'default' &&
                        <ListItemText primary="All Topics" secondary={item.size+" Courses"}/>
                      }
                      {item.key !== 'default' &&
                        <ListItemText primary={item.name} secondary={item.size+" Courses"}/>
                      }
                    </ListItem>
                    <Divider></Divider>
                  </>
                  }
                  </>
              )}
              </List>
            </Dialog>
        </div>
    )
}

CategoryCourse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CategoryCourse);