import React, { useState, useRef, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { NavigationContext } from '../ui/NavigationController'
import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'  

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      minHeight: 0
    }    
  },  
  titleBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1300,
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    marginTop: theme.spacing(1),  
    marginLeft: theme.spacing(1) * 2,
    marginBottom: theme.spacing(1),
    fontWeight: 800
  },
  actionBar: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,    
    paddingLeft: theme.spacing(1) * 2,
    paddingRight: theme.spacing(1) * 2,

    [theme.breakpoints.up('sm')]: {
      position: 'static',
      paddingTop: theme.spacing(1) * 3,
      paddingBottom: theme.spacing(1) * 3,
    }    
    
  },    
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1) * 2,
    width: '100%'
  },
  textField: {
    width: '100%'
  }
})

const TitleBar = ({ classes, title }) => (
  <Box className={classes.titleBar}>
    <Typography className={classes.titleText} variant='h6'>
    { title }
    </Typography>
  </Box>
)

const ActionBar = ({ classes, saveForm, cancelForm }) => {
  return (
    <Box className={classes.actionBar} boxShadow={10}>
          <Grid container spacing={1}>    
            <Grid item xs={6}>
              <Button variant="contained" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={saveForm} >
                Simpan
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" color="secondary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={cancelForm}>
                Batal
              </Button>
            </Grid>
          </Grid>
    </Box>
  )
}

const ContactForm = ({ theme, classes, inline, initial, saveForm, cancelForm }) => {

  const navigationContext = useContext(NavigationContext)
  
  let smUp = useMediaQuery(theme.breakpoints.up('sm'))  
  
  if (!initial || initial === undefined)
    initial = {}
  
  const [contact, setContact] = useState(initial)
  const [errorTexts, setErrorTexts] = useState({})
  
  const enableLastName = initial && initial['last_name'] && initial['last_name'] !== undefined
  
  const validateEmail = (email) => {
  }
  
  const isValid = (val, required=true, type=null) => {
    if (!val || val == undefined)
      val = ""
    else
      val = val.trim()
    if (required && val.length == 0)
      return false
    if (val.length > 0)
      if (type == 'email') {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(val).toLowerCase()))
          return false
      }
    
    return true
  }
    
  const validateAndSave = () => {
    let errors = {}
    if (!isValid(contact.first_name, true))
      errors['first_name'] = 'Masukan nama anda'
    if (!isValid(contact.mobile_no, true))
      errors['mobile_no'] = 'Nomor HP anda tidak valid'
    if (!isValid(contact.email, false, 'email'))
      errors['email'] = 'Alamat email anda tidak valid'

    if (Object.keys(errors).length > 0)
      setErrorTexts(errors)
    else
      saveForm(contact)
  }  
  
  useEffect(() => {

    if (!smUp && !inline) {    
      
      // Note: showBottomMenu must be called on every render
      // otherwise, address will get old data.
      // Make sure we don't give [] parameter to useEffect
      navigationContext.showBottomMenu(
        <ActionBar classes={classes} 
          saveForm={ () => validateAndSave() }
          cancelForm={ () => cancelForm() }
          />
      )
    }
  }, [contact])
  
  useEffect(() => {
    // Hide only when the component is unloaded by giving
    // '[]' parameter to useEffect
    return () => {
      navigationContext.showTitleBar(null)
      navigationContext.showBottomMenu(null)
    }
  }, [])

  const contactField = (fieldName) => {
    if (contact && contact !== undefined && fieldName in contact)
      return contact[fieldName]
    else
      return ""    
  }

  const handleChange = name => event => {
    setContact({
      ...contact,
      [name]: event.target.value
      })
  } 
    
  const [subdistricts, setSubdistricts] = useState({
      visible: false,
      searchText: "",
      isFetching: false,
      data: []
    })
    
  return (
    <div className={classes.root} style={ inline && { minHeight:0 }}>
      <form className={classes.form}>
        <TextField
          id="first_name"
          label={enableLastName ? "Nama Depan" : "Nama"}
          value={contactField('first_name')}
          error={'first_name' in errorTexts ? true : false}
          onChange={handleChange('first_name')}
          className={classes.textField}
          margin="normal"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        
        { enableLastName &&
        <TextField
          id="last_name"
          label="Nama Belakang (Opsional)"
          value={contactField('last_name')}
          error={'last_name' in errorTexts ? true : false}
          onChange={handleChange('last_name')}
          className={classes.textField}
          margin="normal"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        }
        
        <TextField
          id="mobile_no"
          label="Nomor HP"
          value={contactField('mobile_no')}
          error={'mobile_no' in errorTexts ? true : false}
          onChange={handleChange('mobile_no')}
          className={classes.textField}
          margin="normal"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        <TextField
          id="email"
          label="Alamat Email (Opsional)"
          value={contactField('email')}
          error={'email' in errorTexts ? true : false}
          onChange={handleChange('email')}
          className={classes.textField}
          margin="normal"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
      </form>


      { inline &&
        <Box style={{padding: theme.spacing(2) }}>
          <Button variant="contained" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={ () => validateAndSave() } >
            Kirim
          </Button>
        </Box>
      }

      { !inline && smUp &&      
        <ActionBar classes={classes} 
          saveForm={ () => validateAndSave() }
          cancelForm={ () => cancelForm() }
          />
      }
      
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ContactForm))
