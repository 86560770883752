import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionLandingHero:{
    // background: '#acacac',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundSize:'cover',
    minHeight:'80vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      display: 'block',
      paddingTop:theme.spacing(1),
      paddingBottom:theme.spacing(1),
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
    marginTop: '70px',
    marginBottom: theme.spacing(12),
    color: 'white'
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: 'white'
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      background: '#0000007a',
      position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30vh',
    left: 0
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  detailAbout:{
    fontWeight: 100,
    width: '60%',
    margin: '0 auto',
    marginBottom: theme.spacing(6)
  },
  chevronDown:{
    //   marginTop: theme.spacing(8),
      borderRadius: '50%',
      border: '3px solid white',
      fontSize: '80px',
      color: 'white'
  },
  contactSection:{
      background: 'white',
      boxShadow: '0px 0px 30px 10px rgba(194,194,194,1)'
  },
  contactDetailSection:{
      padding: theme.spacing(12),
      border: '1px solid #ebebeb',
      [theme.breakpoints.down('xs')]:{
        padding: theme.spacing(6)
      }
  },
  titleContact:{
      marginBottom: theme.spacing(6)
  },
  btnContact:{
    width: '50%',
    height: '50px',
    [theme.breakpoints.down('xs')]:{
      width: '100%'
    }
  },
  noPaddingMobile:{
    [theme.breakpoints.down('xs')]:{
      padding: 0
    }
  }
});

const ContactUs = ({ classes, topicKey ,background_images, trainerList, history, onClick }) => {

    // let backgroundImage = background_images !== undefined ?  background_images : 'https://images.unsplash.com/photo-1481151500463-1fa2dd2d5dbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1206&q=80'
    return (
        <div className={clsx(classes.sectionLandingHero,classes.dark)} style={{background:'transparent'}}>
            <Container className={classes.noPaddingMobile}>
                <Grid container className={classes.contactSection}>
                    <Grid items xs className={classes.contactDetailSection}>
                      <Fade>
                        <Typography variant='h4' className={classes.titleContact}>
                            How can we help your organization?
                        </Typography>
                        <Button variant='contained' color='primary' className={classes.btnContact} onClick={onClick}>
                            Contact Us
                        </Button>
                      </Fade>
                    </Grid>
                    <Grid items xs className={classes.contactDetailSection}>
                      <Fade>
                        <Typography variant='h4'className={classes.titleContact}>
                            How can we help a professional like you?
                        </Typography>
                        <Button variant='contained' color='primary' className={classes.btnContact} onClick={onClick}>
                            Contact Us
                        </Button>
                      </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContactUs);