import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import NextArrow from '../ui/NextArrow'
import PrevArrow from '../ui/PrevArrow'
import TrainerSliderCard from '../cards/TrainerSliderCard';
import { getTrainerIndex, fetchTrainerIndex } from '../../data/reducers/trainer'
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";

const styles = theme =>({
    dark: {
        color: 'white'
    },
    light: {
        color: 'black'
    },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerList:{
      background: 'white',
    minHeight:'0vh',
    minWidth:'100%',
    display:"flex",
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]:{
      background: 'transparent'
    }
  },
  carouselItem:{
    maxWidth: 1200,
    border: 0,
    height: '80vh',
    [theme.breakpoints.down('md')]:{
        height: '80vh',
        margin: 0,
        maxWidth: '100%'
    }
  },
  titleTrainerList:{
      fontSize: '36px',
      fontWeight: '400',
      textAlign: 'left',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('md')]:{
          fontSize: '24px',
        //   margin: 0,
        //   marginTop: theme.spacing(3),
        //   marginBottom: theme.spacing(3),
        //   marginLeft: theme.spacing(3)
      }
  },
  containerTrainerList:{
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('xs')]:{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
  },
  slickTrainer:{
      width: 1000,
  },
  sliderHide:{
    [theme.breakpoints.down('xs')]:{
      // marginLeft: theme.spacing(1) * -2,
      // marginRight: theme.spacing(1) * -2
      display:'none'
    }
  },
  desktopHidden:{
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      // marginLeft: theme.spacing(1) * -2,
      // marginRight: theme.spacing(1) * -2
      display:'block',
      marginLeft: '-16px',
      marginRight: '-16px'
    }
  },
  moreTrainers:{
    color: '#E75A0B',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]:{
      marginTop: theme.spacing(3)
    }
  },
  noDecoration:{
    textDecoration: 'none'
  }
});

const TrainerSlide = ({ classes, fetchTrainerList, trainerList, history, hashKey, theme, body, items, variant, images, background_images, link_to, link_title, openLink }) => {
    
    useEffect(() => {
        fetchTrainerList()    
    },[])

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 1.05,
        initialIndex: 0,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              centerMode: false
            }
          },
        ]
        // variableWidth: true,
      };

    let title = '';
    return (
      // <Fade>
        <div className={clsx(classes.sectionTrainerList,classes.light)}>
            <Container className={classes.containerTrainerList}>  
              <Markdown source={ body }/>
              {trainerList.lastFetched && trainerList.data.records.length > 1 &&
                <Slider {...settings} className={classes.sliderHide}>
                    { trainerList.lastFetched ?
                        trainerList.data.records.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                     ) 
                     :
                     <p>...</p>
                   }
                </Slider>
                }
                {trainerList.lastFetched && trainerList.data.records.length === 1 &&
                  <Grid container>
                    <Grid items xs={12} className={classes.itemTrainer}>
                    { trainerList.lastFetched ?
                        trainerList.data.records.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                    ) 
                    :
                    <p>...</p>
                  }
                    </Grid>
                  </Grid>
                }
                <div className={classes.desktopHidden}>
                  <Grid container>
                    <Grid items xs={12} className={classes.itemTrainer}>
                    { trainerList.lastFetched ?
                        trainerList.data.records.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                     ) 
                     :
                     <p>...</p>
                   }
                    </Grid>
                  </Grid>
                  <Link to='/trainer' className={classes.noDecoration}>
                    <Typography variant='h5' className={classes.moreTrainers}>
                      Learn More About Our Trainers
                    </Typography>
                  </Link>
                </div>
            </Container>
        </div>
      // </Fade>
    )
}
  
  const mapStateToProps = (state, ownProps) => {
    return { 
      trainerList: getTrainerIndex(state, 'trainer', 'default'),
    }
  } 
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchTrainerList: (refresh=false) => {
        dispatch(fetchTrainerIndex('trainer', 'default', null, refresh))
      },
    }
  }
  
  TrainerSlide.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true})(TrainerSlide))