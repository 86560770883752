import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import Grid from '@material-ui/core/Grid'
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import TimerIcon from '@material-ui/icons/Timer';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  courseFeatureList:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 2
  },
  fabIconLeft: {
    marginRight: theme.spacing(1)
  },
  fabIconRight: {
    marginLeft: theme.spacing(1)
  },
  totalCourses:{
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  featuredList:{
    // margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    // backgroundColor: 'white',
    // textAlign: 'center',
    // boxShadow: '-5px 5px 5px 0px rgba(184,180,184,1)',
    // height: '200px'
  },
  iconFeatured:{
    fontSize: '96px',
    marginBottom: theme.spacing(3),
    border: '2px solid #767676',
    padding: theme.spacing(3),
    borderRadius: '50%',
    color: '#224A85',
    [theme.breakpoints.between('sm','md')]:{
      padding: theme.spacing(1),
      fontSize: '60px'
    }
  },
  titleFeaturedList:{
    // height: '80px',
    // [theme.breakpoints.down('xs')]:{
    //   height: '60px'
    // }
  },
  descFeaturedList:{
    // height: '100px'
  },
  titleFeatured:{
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    color: '#767676'
  },
  descFeatured:{
    color: '#b6b5b5'
  },
  aboutProgram:{
    paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding: '0 16px'
    }
  },
  descriptionProgram:{
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding: 0
    },
    [theme.breakpoints.between('sm','md')]:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
    
  },
  centerMobile:{
    [theme.breakpoints.down('xs')]:{
      margin: '0 auto'
    }
  }
});

const CourseFeature = ({ classes, courseDetailList, history, openLink, trainingDays, moduleData, courseType }) => (
    <div className={classes.courseFeatureList}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} md={4} sm={6}>
            <div className={classes.featuredList}>
              <Grid container>
                <Grid items sm={2} md={3} xs={4} className={classes.centerMobile}>
                  <TimerIcon className={classes.iconFeatured}/>
                </Grid>
                <Grid items sm={10} md={9} xs={8}>
                  <div className={classes.descriptionProgram}>
                    <div className={classes.titleFeaturedList}>
                      <Typography variant='h5' className={classes.titleFeatured}>
                        {trainingDays} Day Program / {moduleData} Lesson Modules
                      </Typography>
                    </div>
                    <div className={classes.descFeaturedList}>
                      <Typography variant='body1' className={classes.descFeatured}>
                        This program course consists of {moduleData} Lesson Modules and can be effectively & sufficiently delivered in {trainingDays} training days.
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <div className={classes.featuredList}>
              <Grid container>
                  <Grid items sm={2} md={3} xs={4} className={classes.centerMobile}>
                    <AssignmentOutlinedIcon className={classes.iconFeatured}/>
                  </Grid>
                  <Grid items sm={10} md={9} xs={8}>
                    <div className={classes.descriptionProgram}>
                      <div className={classes.titleFeaturedList}>
                        <Typography variant='h5' className={classes.titleFeatured}>
                          {courseType || 'Workbook'}
                        </Typography>
                      </div>
                      <div className={classes.descFeaturedList}>
                        <Typography variant='body1' className={classes.descFeatured}>
                          Set of materials to ensure the knowledge is well-received and the training result can be assessed properly for further use.
                        </Typography>
                      </div>
                    </div>
                  </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <div className={classes.featuredList}>
              <Grid container>
                  <Grid items sm={2} md={3} xs={4} className={classes.centerMobile}>
                    <AccessibilityIcon className={classes.iconFeatured}/>
                  </Grid>
                  <Grid items sm={10} md={9} xs={8}>
                    <div className={classes.descriptionProgram}>
                      <div className={classes.titleFeaturedList}>
                        <Typography variant='h5' className={classes.titleFeatured}>
                          In-Class, Instructor-Led
                        </Typography>
                      </div>
                      <div className={classes.descFeaturedList}>
                        <Typography variant='body1' className={classes.descFeatured}>
                          Delivery method to ensure training effectiveness by encouraging thoughtful group discussions and direct Q&A to the expert in the classroom.
                        </Typography>
                      </div>
                    </div>
                  </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )

  CourseFeature.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles, { withTheme: true })(CourseFeature);