import ls from 'local-storage'
import { combineReducers } from 'redux'
import { createReducer } from './utility'

// Data structure

const orderRecordInitial = {
  key: null,
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: { status: null },
}

const orderListInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: {
    count: 0,
    records: [ ],
  }
}

// Actions
const ACTION = {
  requestOrderList: "requestOrderList",
  receiveOrderList: "receiveOrderList",
  errorRequestOrderList: "errorRequestOrderList",
  requestOrderRecord: "requestOrderRecord",
  receiveOrderRecord: "receiveOrderRecord",
  errorRequestOrderRecord: "errorRequestOrderRecord"
}

export const getOrderList = (state) => {
  return state.alpha.order.list
}

export const getOrderRecord = (state, key) => {
  if (key in state.alpha.order.records)
    return state.alpha.order.records[key]
  return null
}

// Async action creator

export const fetchOrderList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getOrderList(getState())
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/sales/api/order/"
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id

//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestOrderList())
    
    return fetch(url, { 
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveOrderList(json)))
      .catch(error => dispatch(errorRequestOrderList(error)))
  }
}

export const fetchOrderRecord = (key, refresh=false) => {
  return (dispatch, getState) => {
    let res = getOrderRecord(getState(), key)
    if (res && (res.isFetching || (res.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/sales/api/order/" + key + "/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestOrderRecord(key)); 
    return fetch(url, { 
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveOrderRecord(key, json)))
      .catch(error => dispatch(errorRequestOrderRecord(key, error)));
  }
};

// Synchronous Action Creators

export const requestOrderList = () => ({
  type: ACTION.requestOrderList,
})

export const receiveOrderList = (json) => {

  let result = {
    count: json.length,
    records: json
  }

//  alert("orderList: " + JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveOrderList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestOrderList = (error) => ({
  type: ACTION.errorRequestOrderList,
  error
})

export const requestOrderRecord = (key) => ({
  type: ACTION.requestOrderRecord,
  key
})

export const receiveOrderRecord = (key, json) => {

  let result = json
  
//  alert("Order Record:" + JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveOrderRecord,
    key,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorRequestOrderRecord = (key, error) => ({
  type: ACTION.errorRequestOrderRecord,
  key,
  error
})

// Reducers for Conversation

const orderRecordReducer = createReducer(orderRecordInitial, {
  [ACTION.requestOrderRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveOrderRecord]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestOrderRecord]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

const orderListReducer = createReducer(orderListInitial, {
  [ACTION.requestOrderList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveOrderList]: (state, action) => {
  
      return {
        ...state,
        isFetching: false,
        data: action.result,
        lastFetched: action.receivedAt,
      }        
    },
  [ACTION.errorRequestOrderList]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
})

function orderRecordByKeyUpdater(state, action) {
  let key = action.key
  return {
    ...state,
    [key]: orderRecordReducer(state[key], action)
  }
}

const orderRecordByKeyReducer = createReducer({}, {
  [ACTION.requestOrderRecord]: orderRecordByKeyUpdater,
  [ACTION.receiveOrderRecord]: orderRecordByKeyUpdater,
  [ACTION.errorRequestOrderRecord]: orderRecordByKeyUpdater,
})

// Combine list and records reducer

const orderReducer = combineReducers({
  list: orderListReducer,
  records: orderRecordByKeyReducer,
});

export default orderReducer