import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { connect } from 'react-redux'
import { getContentIndex, fetchContentIndex } from '../../alpha/data/reducers/content'

import StoryCard from '../cards/StoryCard'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import Button from '../components/Button'
import Button from '@material-ui/core/Button'
//import Typography from '../components/Typography'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Markdown from '../ui/Markdown'

const styles = theme => ({
  root: {
    display: 'flex',
//    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  items: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

const BlogFeed = ({ classes, theme, body, items, variant, images, background_images, blogList, categoryList, categoryKey, fetchBlogList, fetchCategoryList, openLink, link_to, link_title }) => {

  let rootStyle = {}
  
  useEffect(() => {
    fetchBlogList()
  }, [])

  let sm = useMediaQuery(theme.breakpoints.up('sm'))
  let md = useMediaQuery(theme.breakpoints.up('md'))
  
  let itemsPerRow = 1
  if (md)
    itemsPerRow = 3
  else if (sm)
    itemsPerRow = 2
  
  let filteredItems = null
  if (blogList.lastFetched) {
    let rowCount = Math.floor(blogList.data.records.length / itemsPerRow)
    if (rowCount <= 0)
      filteredItems = blogList.data.records
    else
      filteredItems = blogList.data.records.slice(0, rowCount * itemsPerRow)
  }

  return (
    <section className={classes.root} style={rootStyle}>
      <Container className={classes.container}>
        <Box align="center">
          <Markdown source={ body } />
        </Box>
        <div className={classes.items}>
          <Grid container className={classes.storyList} container spacing={3}>
            { filteredItems ?
              filteredItems.map(item =>
                <Grid item xs={12} sm={6} md={4}>
                  <StoryCard item={item} onClick={() => openLink('story/' + item.key)}/>
                </Grid>
              )
              :
              <p>...</p>
            }
          </Grid>
        </div>
        { link_to && link_to !== undefined &&
          <Box align="center">
            <p/>
            <Button variant="contained" color="primary" onClick={ () => openLink(link_to) }>
              { link_title }
            </Button>
          </Box>
        }        
      </Container>
    </section>
  );
}

BlogFeed.propTypes = {
  classes: PropTypes.object.isRequired,
}

function categoryKeyFromProps(props) {
  let key = null
  if (props.categoryKey !== undefined)
    key = props.categoryKey
  else if (props.match && props.match.params.categoryKey !== undefined)
    key = props.match.params.categoryKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let categoryKey = categoryKeyFromProps(ownProps)
  let indexKey = categoryKey ? categoryKey : 'default'

  return { 
    blogList: getContentIndex(state, 'blog', indexKey),
    categoryKey: categoryKey,
    categoryList: getContentIndex(state, 'blog-category', 'default'),
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let categoryKey = categoryKeyFromProps(ownProps)
  let indexKey = categoryKey ? categoryKey : 'default'

  return {
    fetchBlogList: (refresh=false) => {
      let params = categoryKey ? { category_f: categoryKey } : null
      dispatch(fetchContentIndex('blog', indexKey, params, refresh))
    },
    fetchCategoryList: (refresh=false) => {
      dispatch(fetchContentIndex('blog-category', 'default', null, refresh))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true})(BlogFeed))
