import React from 'react'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
  heading: {
    marginTop: '.5em',
    marginBottom: '.5em'
  },
  paragraph: {
    marginBottom: '.5em'
  },    
  strong: {
    fontWeight: 800
  },
});

const options = {
  overrides: {
    h1: { component: props => <Typography gutterBottom variant="h1" {...props} /> },
    h2: { component: props => <Typography gutterBottom variant="h2" {...props} /> },
    h3: { component: props => <Typography gutterBottom variant="subtitle1" {...props} /> },
    h4: { component: props => <Typography gutterBottom variant="caption" paragraph {...props} /> },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};


const Markdown = (props) => {
  let { classes, dark=false } = props
  
  let headingStyles = {}
  let paragraphStyles = {}
  let strongStyles = {}
  if (dark) {
//    headingStyles['color'] = '#ccc'
    paragraphStyles['color'] = '#bbb'
    // strongStyles['color'] = '#fff'
  }
  else {
//    headingStyles['color'] = '#000'    
    // paragraphStyles['color'] = '#777'
    // strongStyles['color'] = '#000'
  }
  
  let { openLink } = props
  
  const renderLink = (props) => {
  /*
    if (props.children.length == 0) {
      if (props.href.startsWith("product/")) {
        let productKeys = props.href.substring("product/".length).split(",")
          
        let result = []
        productKeys.forEach((key) => {
          fetchProductRecord(key)
  
          if (key in products) {
            let p = products[key]
            if (p.lastFetched)
              result.push(p)
          }
        })
          
        return (
            <Grid container spacing={1}>
            {            
              result.map((product) =>
              <Grid item xs={6}>
                <ProductCard product={product.data}
                  onClick={() => openLink("product/" + product.data.key ) }
                />
              </Grid>
              )
            }
            </Grid>
          )
      }
    }
    */
  
    return (
      <a href={ props.href }>{ props.children }</a>
    )
  }
  
  
  const renderers = {
    heading: (props) => <Typography gutterBottom className={classes.heading} style={headingStyles} variant={ 'h' + (props.level) }> { props.children } </Typography>,
    paragraph: (props) => <Typography gutterBottom className={ classes.paragraph } style={paragraphStyles} variant='body1'> { props.children } </Typography>, 
    listItem: (props) => <li className={ classes.paragraph } style={paragraphStyles}><Typography variant='body1'> { props.children } </Typography></li>, 
    strong: (props) => <Typography display='inline' variant='inherit' className={ classes.strong } style={strongStyles}>{ props.children } </Typography>,
    link: renderLink
  }

  return <ReactMarkdown options={options} {...props} renderers={renderers} />;
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.alpha.catalog.records
  }
} 


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Markdown))
