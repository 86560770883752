import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import {
  Link
} from "react-router-dom";
import CoursesDetailCard from '../cards/CoursesDetailCard';
import Fade from 'react-reveal/Fade';
import { relative } from 'path';
import moment from 'moment'

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionCourseHero:{
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover',
    minHeight:'50vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    // textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      minHeight: '50vh',
      paddingTop: theme.spacing(8),
      paddingBottom: 0,
      backgroundPosition: 'right',
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
      
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold'
  },
  titleArticle:{
    // color:'white',
    fontWeight: 'bold !important'
  },
  authorArticle:{
    // color: 'white'
  },
  detailArticleSection:{
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minHeight: '100vh',
    background: 'white'
  },
  bodyArticle:{
    marginTop: theme.spacing(3),
  }
});

const ArticleDetailHero = ({ classes, articleKey , articleDetail, background_images }) => {


    let backgroundImage = background_images !== undefined ? background_images : 'https://images.unsplash.com/photo-1529400971008-f566de0e6dfc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'  
    return (
      <>
      {articleDetail &&
      <>
        <div className={clsx(classes.sectionCourseHero,classes.dark)} style={{backgroundImage: 'url('+backgroundImage+')'}}>
            <Container>
                {/* <Typography variant='h3'>
                    {articleKey}
                </Typography> */}
            </Container>
        </div>
        <div className={classes.detailArticleSection}>
          <Container>
            <Typography variant='h3' className={classes.titleArticle}>
                {articleDetail.data.title}
            </Typography>
              <Typography variant='h5' className={classes.authorArticle}>
                {articleDetail.data.author || 'PasarTrainer'} | { moment(articleDetail.data.created_at).format('LLL')}
              </Typography>
            <Typography variant='body1' className={classes.bodyArticle}>
                {articleDetail.data.body || ''}
            </Typography>
          </Container>
        </div>
        </>
        }
      </>
    )
}

ArticleDetailHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ArticleDetailHero);