import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchConversationList, WSSTATE } from '../alpha/data/reducers/conversation'
import { getBusinessInfo } from '../alpha/data/reducers/business'
import { withStyles } from '@material-ui/core/styles'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'
import Chat from './Chat'

import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// UI
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: theme.spacing.unit * 2
  },
  pageTitle: {
    color: '#000',
    fontWeight: 800,
    padding: theme.spacing.unit,
    paddingTop: theme.spacing(4),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }    
  },  
  sectionHeader: {
    marginBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    color: '#999',
  },
  sectionFooter: {
    marginTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
  },
  sectionDivider: {
    minHeight: theme.spacing.unit * 3,
  },  
  sectionBody: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  sectionContent: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    alignItems: 'center',
    width: '100',
  },  
  sectionContentAction: {
  },
  bottomPadding: {
    minHeight: 100
  },  
})

export const isChatOnline = () => {
  var date = new Date()
  var utc = date.getTime() + (date.getTimezoneOffset() * 60000)
  var localOffset = 7
  var localDate = new Date(utc + (3600000*localOffset))

  let day = localDate.getDay()
  let hour = localDate.getHours()
  
  if (day >= 1 && day <= 5)
    if (hour >= 8 && hour < 18)
      return true
      
  return false
}

export const getChatOnlineStatus = () => {
  var date = new Date()
  var utc = date.getTime() + (date.getTimezoneOffset() * 60000)
  var localOffset = 7
  var localDate = new Date(utc + (3600000*localOffset))

  let day = localDate.getDay()
  let hour = localDate.getHours()
  
  let status
  if (day >= 1 && day <= 5) { 
    if (hour >= 8 && hour < 18)
      status = "Online"
    else if (hour < 8)
      status = "Kembali jam 8"
    else if (day < 5)
      status = "Kembali besok pagi"
    else
      status = "Kembali hari Senin"
  }
  else if (day == 0)
    status = "Kembali besok"
  else
    status = "Kembali hari Senin"
    
  return status
}

const ConversationItem = ({ classes, business, item, onClick }) => {

  let title = business.name
  let sender = ""
  let preview = "..."
  let date = ""
  let stat = ""
  
//  alert(JSON.stringify(item, null, 2))
  
  if (item.messages.length) {
    title = item.data.parties.map(p => {
      if (p.agent) {
        let name = p.agent.first_name
        if (p.agent.last_name && p.agent.last_name !== undefined)
          name += " " + p.agent.last_name
        if (p.agent.is_service_user)
          name += "" //" (Bot)"
        else
          name += " (Agent)"
//          name += " (" + business.name + ")"
        return name
      }
      else
        return null
    }).reduce((p, n) => {
      if (p && n)
        return p + ", " + n
      else if (n)
        return n
      else
        return p
    })
    
    if (!title && item.data.queue)
      title = item.data.queue.name
    
    if (!title || title.length <= 0)
      title = "You"
  
//    alert(JSON.stringify(item, null, 2))
    let msg = item.messages[item.messages.length-1]
    preview = msg.data.body
    if (msg.data.attachments && msg.data.attachments !== undefined && msg.data.attachments.length > 0) {
        preview = "Photo"
    }
    
    if (preview.startsWith("{") && preview.endsWith("}"))
      preview = JSON.parse(preview)['text']
      
    if (preview)
      preview = preview.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, '$1')
    
    if (msg.isSending)
      date= "Sending..."
    else {
      if (msg.data.sent_at)
        date = moment(msg.data.sent_at).fromNow(true)
      
      if (msg.data.sender.contact)
        sender = "You"
    }

//    if (msg.data.agent)
//      sender = msg.data.agent.first_name
//    else if (msg.data.service)
//      sender = msg.data.service.first_name
  }
  
  let newCount = item.data.new_count
  
  if (item.data.new_count)
    stat = " (New)"
  else if (item.data.unread_count)
    stat = " (Not Read)"
  else if (item.messages.length)
//    stat = " (Read)"   
    stat = ""

  return (
    <ListItem alignItems="flex-start" onClick={() => onClick(item)}>
      <ListItemAvatar>
        <Avatar alt="You" src="" />
      </ListItemAvatar>
      <ListItemText
        primary={ 
          <>
            <Typography>
            { title }
            </Typography>
            
          </>
        }
        secondary={
          <React.Fragment>
            <Typography
              component="p"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
              noWrap={false}
            >
              { sender && ( 
                <> { sender }: </>
              )}
              { preview }
            </Typography>
            <br/>
            { date } { stat }
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
            <Badge color='primary' badgeContent={newCount}>&nbsp;</Badge>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const ConversationList = ({ classes, business, conversations, onClick }) => (

  <Box className={classes.sectionBody}>
    <Divider/>
    { conversations.length > 0 ?
      <List className={classes.list}>
        { conversations.length
          ? conversations
            .map(item =>
              <ConversationItem classes={classes} business={business} item={item} onClick={() => onClick(item)} />
            )
            .reduce((prev, next) => [prev,        
              <Divider variant="inset" component="li" />    
              , next]
            )
          :
            <></>
        }
      </List>
      :
      <Box width='100%' className={classes.sectionContent}>
        <Box flexGrow={1} color='#999'>
          <Typography component="div" variant='body1'>
            Belum ada riwayat chat.
          </Typography>
        </Box>
      </Box>        
    }
    <Divider/>
  </Box>
)

const ChatHeader = ({ classes, business, showConversation, wsState }) => {

  let state = "Disconnected"
  if (wsState == WSSTATE.Connected)
    state = "You are online"
  else if (wsState == WSSTATE.Connecting)
    state = "Connecting..."

  return (
  <Box className={classes.chatHeader}>
    <Box width='100%' className={classes.sectionContent} display='flex' flexDirection='column'>
      <Box width='100%' display='flex' justifyContent='center'>
        <Box flexGrow={1}>
          <Typography variant='h6' className={classes.accountName}>
          <Box fontWeight={800} color='#000'>{ business.name }</Box>
          </Typography>
          <Typography variant='p' className={classes.accountName}>
          { state }
          </Typography>
          {
//          Chat dengan <Box fontWeight={800} color='#000' component='span'>{ business.name }</Box>
          }
        </Box>
        <Box width='10rem'>
        <Button variant='contained' color='primary' fullWidth onClick={()=>showConversation(0)}>
          Mulai Chat Baru
        </Button>
        </Box>
      </Box>
      <Box width='100%' display='flex' display='none'>
        <Box>
          <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>      
        </Box>
        <Box flexGrow={1}>
          <Typography component="div" variant='body1' gutterBottom>
            {business.name}
          </Typography>
          <Button variant='contained' color='primary' onClick={()=>showConversation(0)}>
            Mulai Chat Baru
          </Button>
        </Box>
      </Box>
    </Box>  
  </Box>
  )
}
  
const InboxView = ({ theme, classes, history, business, lastFetched, wsState, fetchConversationList, isFetching, conversations, newConversation }) => {
  const navigationContext = useContext(NavigationContext)
  const [hasBottomMenu, setHasBottomMenu] = useState(false)
  
  useEffect(() => {
  /*
    navigationContext.showTitle("Chat")
    
    if (!popupContent && !isFetching && lastFetched) {
      navigationContext.showBottomMenu(
        <Fab variant="extended" color="secondary" size="medium" aria-label="New Chat" className={classes.fab} onClick={ () => showConversation(0) }>
          New Chat
        </Fab>
      )      
    }
  */
  })

  // This will be called on component init  
  useEffect(() => {
    fetchConversationList()

    return () => {
      // Clean up
      navigationContext.showBottomMenu(null)
    }        
  }, [])

  useEffect(() => {
    navigationContext.setLoading(isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [isFetching])
    
  let sm = useMediaQuery(theme.breakpoints.up('sm'))
  
  const showConversation = (conversationKey) => {
    if (wsState != WSSTATE.Connected) {
      alert("Can not start chat while you are not online. Please check your internet connection.")
      return
    }
  
//    alert('showConversation:'+ conversationKey)
//    history.push("/chat/" + conversationKey)
    if (sm)
      navigationContext.showPopupContent(
        <Chat conversationKey={conversationKey} openChat={(key)=>showConversation(key)} />
        )
    else
      showPopup(
        <Chat conversationKey={conversationKey} openChat={(key)=>showConversation(key)} />
        )
  }
  
  const [popupContent, setPopupContent] = useState(null)
  
  const showPopup = (content) => {
    navigationContext.showBottomMenu(null)
    setPopupContent(content)
  }
  const closePopup = () => {
    setPopupContent(null)
  }  
  
  return (
    <div>
      <Helmet>
        <title>Inbox - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    
    <PageBase popupContent={popupContent} closePopup={closePopup}>
      <div className={classes.root}>
      
        <Box className={classes.pageTitle}>
        </Box>
        
        <ChatHeader classes={classes} business={business} showConversation={showConversation} wsState={wsState} />

        <div className={classes.sectionDivider} />
        
        <Box className={classes.sectionHeader} di>
          <Typography variant="body1">Riwayat Chat
          </Typography>
        </Box>
      
        <ConversationList classes={classes} business={business} conversations={ conversations } onClick={ (conversation) => showConversation(conversation.key) } />            

        <div className={classes.bottomPadding}/>
      </div>
    </PageBase>
    </div>
    )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.alpha.conversationList,
    business: getBusinessInfo(state).data    
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConversationList: () => {
      dispatch(fetchConversationList(true))
    }
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxView)))