import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

import Markdown from '../ui/Markdown'

const styles = theme => ({
  root: {
    display: 'flex',
//    backgroundColor: theme.palette.secondary.light,
//    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

const ChatForm = ({ classes, theme, body, variant, images, background_images, openLink }) => {

  let rootStyle = {}
  let inputProps = {}
  let inputLabelProps = { shrink: true }
  let dark = false

  if (variant == 'Dark') {
    dark = true
    rootStyle['backgroundColor'] = '#555'
    rootStyle['color'] = theme.palette.common.white
    inputProps['style'] = {
      color: theme.palette.common.white,
      backgroundColor: '#777'
    }
    inputLabelProps['style'] = {
      color: "#ccc"
    }
  }
  
  return (
    <section className={classes.root} style={rootStyle}>
      <Container className={classes.container}>
      <Grid container>
        <Grid xs={12}>
          <Box align="center">
            <Markdown source={ body } dark={ dark } />
          </Box>
        </Grid>
        <Grid xs={12}>
          <Box align="center">
            <p/>
            <Button variant="contained" color="primary" size="medium" aria-label="" onClick={() => openLink('chat')}>
              <QuestionAnswerIcon />
              &nbsp;&nbsp;Chat Sekarang
            </Button>
          </Box>
        </Grid>          
      </Grid>
      </Container>
    </section>
  );
}

ChatForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ChatForm))
