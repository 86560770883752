import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ReadMoreReact from 'read-more-react';
import { Box } from '@material-ui/core';
import {
  Link
} from "react-router-dom";

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  avatarImage:{
    margin: '0 auto',
    width: '150px',
    height: '150px'
  },
  courseImg:{
    height: '180px',
    width: '180px',
    [theme.breakpoints.down('xs')]:{
      margin: '0 auto',
      height: '110px',
      width: '110px'
    },
    [theme.breakpoints.between('sm','md')]:{
      // height: '70%',
      height: '150px',
      width: '100%'
    }
  },
  courseData:{
    marginTop: theme.spacing(1)
  },
  iconPlay:{
    fontSize: '3rem',
    [theme.breakpoints.down('xs')]:{
      fontSize: '2.6rem'
    }
  },
  courseName:{
    fontWeight: 'bold',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]:{
      // textAlign:'center'
      marginTop:theme.spacing(1)
    }
  },
  totalDays:{
    color: '#9C9E9B',
    [theme.breakpoints.down('xs')]:{
      // textAlign:'center'
    }
  },
  categoryName:{
    // color: '#9C9E9B',
    color: 'black',
    // fontWeight: 'bold',
    [theme.breakpoints.down('xs')]:{
      color: 'black'
      // textAlign: 'center'
    }
  },
  levelCourse:{
    color: '#ff6f38',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]:{
      // textAlign:'center',
    },
  },
  courseLink:{
    cursor: 'pointer',
    background: 'white',
    marginBottom: theme.spacing(1)
  },
  introductionButton:{
    display:'none',
      background: 'rgb(63, 81, 181)',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: '5px',
      borderRadius: '5em',
      color: 'white',
      height: '60px',
      alignItems: 'center',
      width: '155px',
      cursor: 'pointer',
      [theme.breakpoints.between('sm','md')]:{
        width: '50%'
      },
      [theme.breakpoints.between('xs','sm')]:{
        // textAlign:'center',
        height: '60px',
        marginBottom: theme.spacing(3),
        width: '70%'
      },
      [theme.breakpoints.down('xs')]:{
          // textAlign:'center',
          // margin: '0 auto',
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          width: '70%'
      }
  },
  infoCourse:{
    [theme.breakpoints.down('xs')]:{
      textAlign:'center',
      display: 'none'
    },
    [theme.breakpoints.between('sm','md')]:{
      textAlign:'left',
    }
  },
  courseContainer:{
    padding: theme.spacing(1.5),
    height: '100%',
    [theme.breakpoints.down('xs')]:{
      padding: 0
    }
  },
  courseBackground:{
    background: 'white',
    // height: '45vh',
    height: '100%',
    width:'100%',
    cursor: 'pointer',
    // margin: '0 16px',
    padding: theme.spacing(1),
    [theme.breakpoints.between('sm','md')]:{
      // height: '50vh',
      padding: 0
    },
    [theme.breakpoints.down('xs')]:{
      // height: '55vh',
      height: '100%',
      padding: '0 12px',
      margin: 0
    }
  },
  summaryCourse:{
    // cursor: 'pointer',
    color: '#9C9E9B',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]:{
      display: 'none'
    },
    [theme.breakpoints.between('sm','md')]:{
      // marginTop: theme.spacing(2),
      display: 'block'
    },
  },
  dialogVideo:{
    height: '100vh',
    [theme.breakpoints.down('xs')]:{
        height: '30vh'
    }
},
iframeYoutube:{
  position:'absolute',
  top:0,
  left:0,
  bottom:0,
  right:0,
  width:'100%',
  height:'100%',
  border:'none',
  margin:0,
  padding:0,
  overflow:'hidden',
  zIndex:999999
},
boxSummary:{
  display:'none',
  [theme.breakpoints.down('xs')]:{
    display:'block',
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(1),
    color: '#9C9E9B',
    // marginTop: theme.spacing(-4)
  },
  [theme.breakpoints.between('sm','md')]:{
    display:'none',
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(1),
    color: '#9C9E9B',
    paddingBottom: theme.spacing(2)
  }
},
boxSummaryMb:{
  display:'none',
  [theme.breakpoints.down('xs')]:{
    display:'none',
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(1),
    color: 'white',
    // marginTop: theme.spacing(-4)
  }
},
muted:{
  color:'#999'
}
,
readMore:{
  fontWeight: 'bold',
  color: 'black',
  cursor: 'pointer',
  [theme.breakpoints.down('xs')]:{
    display:'none'
  },
  [theme.breakpoints.between('sm','md')]:{
    marginTop: theme.spacing(-2),
    display: 'none'
  },
},
readMoreMobile:{
  fontWeight: 'bold',
  color: 'black',
  cursor: 'pointer',
  display: 'none',
  [theme.breakpoints.down('xs')]:{
    // display:'block'
  }
},
readMoreMobileM:{
  fontWeight: 'bold',
  color: 'black',
  cursor: 'pointer',
  display: 'none',
  [theme.breakpoints.down('xs')]:{
    // display:'block'
    // color: 'white'
  }
},
inlineLevel:{
  display: 'flex'
},
infoLevel:{
  width: '0.8em',
  height: '0.8em',
  color: '#9C9E9B',
  [theme.breakpoints.between('xs','sm')]:{
    display: 'none'
  },
  [theme.breakpoints.down('xs')]:{
    display: 'none'
  }
},
dataCourses:{
  marginTop: theme.spacing(1),
  paddingLeft: '20px !important',
  [theme.breakpoints.down('xs')]:{
    marginTop: 0,
    paddingLeft: 0,
  }
},
viewCourseDesktop:{
  display: 'none',
  [theme.breakpoints.up('md')]:{
    display: 'block'
  }
},
viewCourseMobile:{
  display: 'block',
  [theme.breakpoints.up('md')]:{
    display: 'none'
  }
}
})

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CourseCard = ({ classes, item, topicKey, history }) => {
  const [open, setOpen] = React.useState(false);
  const [urlVideo, setUrlVideo] = React.useState('https://www.youtube.com/embed/GsGnU7_Pp0c?autoplay=1&rel=0')
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let topic = topicKey || 'all-topics'

  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("course")) {
      link = "/" + linkTo
    }
    history.push(link)
  }

  let summary = item.summary ? item.summary.substring(0,170) : ''
  let resultSummary = summary.substr(0, Math.min(summary.length, summary.lastIndexOf(" ")))
  let level = item.level.name ? item.level.name : item.level_name

  return (
    <Container className={classes.courseContainer}>
    <Grid container spacing={3} className={classes.courseBackground}>
      <Grid item xs={4} sm={4} md={4}>
        {item.primary_image_url !== null &&
          <>
          <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
            <div style={{background:`url(`+item.primary_image_url+`)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} className={classes.courseImg}>
            </div>
          </Link>
          </>
        }
        {item.primary_image_url === null &&
        <>
          <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
            <div style={{background:`url(https://www.mandlpaints.com/wp-content/uploads/2018/09/Ash-Grey.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} className={classes.courseImg}>
            </div>
          </Link>
        </>
        }
      </Grid>
      <Grid item xs={8} sm={8} md={8} className={classes.dataCourses}>
      <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Typography variant='h5' className={classes.courseName}>
          {item.name}
        </Typography>
      </Link>
        {item.category &&
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+item.category.key}>
          <Typography variant="body1" className={classes.categoryName}>{item.category.name}</Typography>
        </Link>
        }
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Typography variant='body2' className={classes.muted}>
          {item.course_code}
        </Typography>
        </Link>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
          <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
            <Typography variant="body2" className={classes.totalDays}>{item.training_days} Days In-class Training</Typography>
          </Link>
          </Grid>
          <Grid item xs={6} sm={6}>
          <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
            <div className={classes.inlineLevel}>
            <Typography variant="body2" className={classes.levelCourse}>{item.level.name || item.level_name} Level
            </Typography>
            {level === 'Basic' &&
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Basic Level</Typography>
                    {"Suitable for professionals with 0-4 years of experience"}
                  </React.Fragment>
                }
              >
                <InfoOutlinedIcon className={classes.infoLevel}/>
              </HtmlTooltip>
            }
            {level !== 'Basic' &&
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Intermediate Level</Typography>
                    {"Suitable for professionals with 4+ years of experience or 2+ years in related role"}
                  </React.Fragment>
                }
              >
                <InfoOutlinedIcon className={classes.infoLevel}/>
              </HtmlTooltip>
            }
            </div>
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.introductionButton} onClick={()=> handleClickOpen()}>
            <Grid item md={4} xs={4}>
              <PlayArrowIcon className={classes.iconPlay}/>
            </Grid>
            <Grid item md={8} xs={8}>
              <Typography variant='body2' className={classes.durationVideo}>1:07</Typography>
              <Typography variant='body2' className={classes.labelProfile}>Introduction</Typography>
            </Grid>
        </Grid>
        {item.summary &&
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Typography variant="body1" className={classes.summaryCourse}>
        {/* <ReadMoreReact text={item.summary || ''}
                min='0'
                ideal='0'
                max='100'
                readMoreText='Read More...'/> */}
          {resultSummary}
        </Typography>
        </Link>
        }
        {/* {summary.length > 0 &&
        <Typography variant="body2" className={classes.readMore} onClick={()=>openLink("course/"+topic+"/"+item.key)}>
            Read More...
        </Typography>
        } */}
        <Box mt={2} className={classes.viewCourseDesktop}>
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
          <Button variant='contained' size='large' color='primary' fullWidth='true'>View Course</Button>
        </Link>
        </Box>
      </Grid>
      {item.summary &&
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.boxSummary}>
          {/* <ReadMoreReact text={item.summary || ''}
                  min='0'
                  ideal='50'
                  max='50'
                  readMoreText='Read More...'/> */}
            {item.summary}
        </Typography>
        {summary.length > 0 &&
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Typography variant="body2" className={classes.readMoreMobile} onClick={()=>openLink("course/"+topic+"/"+item.key)}>
            Read More...
        </Typography>
        </Link>
        }
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Box mt={1} mb={1} className={classes.viewCourseMobile}>
          <Button variant='contained' size='large' color='primary' fullWidth='true'>View Course</Button>
        </Box>
        </Link>
      </Grid>
      }
      {!item.summary &&
        <Grid item xs={12}>
        <Typography variant="body1" className={classes.boxSummaryMb}>
        This training will teach participants on how to analyze and understand certain industries. In this training class, participants will learn how to
        </Typography>
        <Link style={{textDecoration:'none', color: 'black'}} to={"/course/"+topic+"/"+item.key}>
        <Typography variant="body2" className={classes.readMoreMobileM}>
            Read More...
        </Typography>
        </Link>
      </Grid>
      }
    </Grid>  
    <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent className={classes.dialogVideo}>
                    <iframe className={classes.iframeYoutube} src={urlVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </DialogContent>
            </Dialog>
    </Container>
  )
}

CourseCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourseCard);
