import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Rating from '@material-ui/lab/Rating';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  itemTrainer:{
    width: "100%",
    height: "90vh",
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      height: '55vh'
    }
},
})


const TrainerDetailCard = ({ classes, item, onClick, name, headline }) => {
  
  return (
    <div>
        <div className={classes.itemTrainer} style={{background:'url('+item.image_url+')',backgroundSize:'cover',backgroundRepeat:'no-repeat', backgroundPosition:'center center'}}>
        </div>
    </div>
  )
}

TrainerDetailCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrainerDetailCard);
