import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import Button from '../components/Button' 
import Button from '@material-ui/core/Button'
//import Typography from '../components/Typography'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

import Markdown from '../ui/Markdown'

import { sendMessage } from '../../alpha/data/reducers/conversation'

const styles = theme => ({
  root: {
    display: 'flex',
//    backgroundColor: theme.palette.secondary.light,
//    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

const ContactForm = ({ classes, theme, body, variant, images, background_images, sendMessage }) => {

  let rootStyle = {}
  let inputProps = {}
  let inputLabelProps = { shrink: true }
  let dark = false

  if (variant == 'Dark') {
    dark = true
    rootStyle['backgroundColor'] = '#555'
    rootStyle['color'] = theme.palette.common.white
    inputProps['style'] = {
      color: theme.palette.common.white,
      backgroundColor: '#777'
    }
    inputLabelProps['style'] = {
      color: "#ccc"
    }
  }
  
  const [contact, setContact] = useState({})
  const [errorTexts, setErrorTexts] = useState({})

  const isValid = (val, required=true, type=null) => {
    if (!val || val == undefined)
      return false
    val = val.trim()
    if (required && val.length == 0)
      return false
    if (type == 'email') {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(val).toLowerCase()))
        return false
    }
    
    return true
  }
    
  const validateAndSave = () => {
    let errors = {}
    if (!isValid(contact.email, true, 'email'))
      errors['email'] = 'Alamat email anda tidak valid'
    if (!isValid(contact.first_name, true))
      errors['first_name'] = 'Nama depan anda tidak valid'
    if (!isValid(contact.mobile_no, true))
      errors['mobile_no'] = 'Nomor HP anda tidak valid'
    if (!isValid(contact.message, true))
      errors['message'] = 'Masukkan pesan anda'

    if (Object.keys(errors).length > 0)
      setErrorTexts(errors)
    else
      sendMessage(contact.message)
  }  
  
  const contactField = (fieldName) => {
    if (contact && contact !== undefined && fieldName in contact)
      return contact[fieldName]
    else
      return ""    
  }

  const handleChange = name => event => {
    setContact({
      ...contact,
      [name]: event.target.value
      })
  } 

  return (
    <section className={classes.root} style={rootStyle}>
      <Container className={classes.container}>
      <Grid container>
        <Grid xs={12}>
          <Box align="center">
            <Markdown source={ body } dark={ dark } />
          </Box>
        </Grid>
        <Grid xs={12}>
          <form className={classes.form}>
            <TextField
              id="first_name"
              label="Nama"
              color="inherit"
              value={contactField('first_name')}
              error={'first_name' in errorTexts ? true : false}
              onChange={handleChange('first_name')}
              className={classes.textField}
              margin="normal"
              variant="filled"
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              fullWidth
            />
            <TextField
              id="email"
              label="Alamat Email"
              value={contactField('email')}
              error={'email' in errorTexts ? true : false}
              onChange={handleChange('email')}
              className={classes.textField}
              margin="normal"
              variant="filled"
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              fullWidth
            />
            <TextField
              id="mobile_no"
              label="Nomor HP"
              value={contactField('mobile_no')}
              error={'mobile_no' in errorTexts ? true : false}
              onChange={handleChange('mobile_no')}
              className={classes.textField}
              margin="normal"
              variant="filled"
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              fullWidth
            />
            <TextField
              id="message"
              label="Pesan"
              value={contactField('message')}
              error={'message' in errorTexts ? true : false}
              onChange={handleChange('message')}
              className={classes.textField}
              margin="normal"
              variant="filled"
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              multiline
              rows={4}
              fullWidth
            />
          </form>
        </Grid>
        <Grid xs={12}>
            <p/>
            <Button variant="contained" color="primary" size="medium" aria-label="" fullWidth onClick={validateAndSave}>
              Kirim
            </Button>
        </Grid>          
      </Grid>
      </Container>
    </section>
  );
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendMessage: (message) => {
      dispatch(sendMessage(0, message))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ContactForm))
