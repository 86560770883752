import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const businessInfoInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: { }
}

// Actions
const ACTION = {
  requestBusinessInfo: "requestBusinessInfo",
  receiveBusinessInfo: "receiveBusinessInfo",
  errorRequestBusinessInfo: "errorRequestBusinessInfo",
}

// Async action creator

export const getBusinessInfo = (state, businessId='0') => {
  if (businessId in state.alpha.business) 
    return state.alpha.business[businessId]
  
  return businessInfoInitial
}

export const fetchBusinessInfo = (businessId='0', refresh=false) => {
  return (dispatch, getState) => {
    let res = getBusinessInfo(getState(), businessId)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    let url = process.env.REACT_APP_GOAPP_API_URL + "/business/api/business/" + businessId + "/"
    let url = process.env.REACT_APP_GOAPP_API_URL + "/directory/api/business/" + businessId + "/"

    let api_key = process.env.REACT_APP_GOAPP_API_KEY
        
//    alert(url)
          
    dispatch(requestBusinessInfo(businessId)); 
    return fetch(url, { 
          headers: { 'X-API-Key': api_key }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveBusinessInfo(businessId, json)))
      .catch(error => dispatch(errorRequestBusinessInfo(businessId, error)));
  }
};

// Synchronous Action Creators for Business Info

export const requestBusinessInfo = (businessId) => ({
  type: ACTION.requestBusinessInfo,
  businessId,
});

export const receiveBusinessInfo = (businessId, json) => {

  return {
    type: ACTION.receiveBusinessInfo,
    businessId,
    result: json,
    receivedAt: Date.now()
  };
}

export const errorRequestBusinessInfo = (businessId, error) => ({
  type: ACTION.errorRequestBusinessInfo,
  businessId,
  error
});

// Reducers for Business

const businessReducer = createReducer(businessInfoInitial, {
  [ACTION.requestBusinessInfo]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveBusinessInfo]: (state, action) => {
//      alert(JSON.stringify(action, null, 2))
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestBusinessInfo]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function businessByIdUpdater(state, action) {
  let key = action.businessId
  return {
    ...state,
    [key]: businessReducer(state[key], action)
  }
}

const businessByIdReducer = createReducer({}, {
  [ACTION.requestBusinessInfo]: businessByIdUpdater,
  [ACTION.receiveBusinessInfo]: businessByIdUpdater,
  [ACTION.errorRequestBusinessInfo]: businessByIdUpdater,
})

export default businessByIdReducer
