import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

const productRecordInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  didInvalidate: false,
  data: { }
}

const productIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  path: null,
  data: { }
}

// Actions
const ACTION = {
  fetchProductIndex: "fetchProductIndex",
  requestProductIndex: "requestProductIndex",
  receiveProductIndex: "receiveProductIndex",
  errorRequestProductIndex: "errorRequestProductIndex",
  invalidateProductIndex: "invalidateProductIndex",
  fetchProductRecord: "fetchProductRecord",
  requestProductRecord: "requestProductRecord",
  receiveProductRecord: "receiveProductRecord",
  errorRequestProductRecord: "errorRequestProductRecord",
  invalidateProductRecord: "invalidateProductRecord",
}


export const indexKeyFor = (path, params) => {
  let key = path
//  alert(JSON.stringify(params))
  if (params)
    key += Object.keys(params).map(key =>
      "&" + key + "=" + params[key]).join("")

  return key
}

export const hasCatalog = (state) => {
  let products = getProductIndex(state, 'default', null)
  if (products.lastFetched && products.data.count > 0)
    return true
  else
    return false
}

// Async action creator

export const getProductIndex = (state, path, params) => {
  let key = indexKeyFor(path, params)
  if (key in state.alpha.catalog.indexes) {
    return state.alpha.catalog.indexes[key]
  }
  
  return productIndexInitial
}

export const fetchProductIndex = (path, params=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getProductIndex(getState(), path, params)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
//    if (!path)
//      path = "/"
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/catalog/api/product/?path=" + path
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    dispatch(requestProductIndex(path, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductIndex(path, params, json)))
      .catch(error => dispatch(errorRequestProductIndex(path, params, error)));
  }
};

export const getProductRecord = (state, recordKey) => {
  if (recordKey in state.alpha.catalog.records)
      return state.alpha.catalog.records[recordKey]
  
  return productRecordInitial
}


export const fetchProductRecord = (recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getProductRecord(getState(), recordKey)
    if (res.isFetching || (res.lastFetched && !res.didInvalidate && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/catalog/api/product/" + recordKey + "/"
      
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")

    dispatch(requestProductRecord(recordKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveProductRecord(recordKey, params, json)))
      .catch(error => dispatch(errorRequestProductRecord(recordKey, params, error)));
  }
};

// Synchronous Action Creators for Product Index/Listing

export const invalidateProductIndex = (path, params) => ({
  type: ACTION.invalidateProductindex,
  path
});

export const requestProductIndex = (path, params) => ({
  type: ACTION.requestProductIndex,
  path,
  params
});

export const receiveProductIndex = (path, params, json) => {

  let result = {
    path: path,
    count: json.length,
    records: json
  }
  
//  alert("receiveProductIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveProductIndex,
    path,
    params,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProductIndex = (recordKey, params, error) => ({
  type: ACTION.errorRequestProductIndex,
  recordKey,
  params,
  error
});

export const invalidateProductRecord = (recordKey) => ({
  type: ACTION.invalidateProductRecord,
  recordKey
});

export const requestProductRecord = (recordKey, params) => ({
  type: ACTION.requestProductRecord,
  recordKey,
  params
});

export const receiveProductRecord = (recordKey, params, json) => {

  let result = json

  return {
    type: ACTION.receiveProductRecord,
    recordKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestProductRecord = (recordKey, params, error) => ({
  type: ACTION.errorRequestProductRecord,
  recordKey,
  params,
  error
});

// Reducers for Product Index

const productIndexReducer = createReducer(productIndexInitial, {
  [ACTION.requestProductIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveProductIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProductIndex]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProductIndex]: (state, action) => {
      if (!state.isFetching)
        return {
          ...state,
          lastFetched: null,
        }
      else
        return state
    },    
})

function productIndexByKeyUpdater(state, action) {
  let key = indexKeyFor(action.path, action.params)
  return {
    ...state,
    [key]: productIndexReducer(state[key], action)
  }
}

const productIndexByKeyReducer = createReducer({}, {
  [ACTION.requestProductIndex]: productIndexByKeyUpdater,
  [ACTION.receiveProductIndex]: productIndexByKeyUpdater,
  [ACTION.errorReceiveProductIndex]: productIndexByKeyUpdater,
  [ACTION.invalidateProductIndex]: productIndexByKeyUpdater,
})


// Reducers for Product Record

const productRecordReducer = createReducer(productRecordInitial, {
  [ACTION.requestProductRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true,
      }
    },
  [ACTION.receiveProductRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        didInvalidate: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestProductRecord]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.invalidateProductRecord]: (state, action) => {
      return {
        ...state,
        didInvalidate: true,
      }
    },    
})

function productRecordByKeyUpdater(state, action) {
  let key = action.recordKey
  return {
    ...state,
    [key]: productRecordReducer(state[key], action)
  }
}

const productRecordByKeyReducer = createReducer({}, {
  [ACTION.requestProductRecord]: productRecordByKeyUpdater,
  [ACTION.receiveProductRecord]: productRecordByKeyUpdater,
  [ACTION.errorReceiveProductRecord]: productRecordByKeyUpdater,
  [ACTION.invalidateProductRecord]: productRecordByKeyUpdater,
})

// Combine all catalog reducer

const catalogReducer = combineReducers({
  indexes: productIndexByKeyReducer,
  records: productRecordByKeyReducer,
});

export default catalogReducer
