import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from '../../alpha/data/reducers/utility'

// Data structure

const courseRecordInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  data: { }
}

const courseIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: { records: null }
}

// Actions
const ACTION = {
  requestCourseIndex: "requestCourseIndex",
  receiveCourseIndex: "receiveCourseIndex",
  errorRequestCourseIndex: "errorRequestCourseIndex",
  requestCourseRecord: "requestCourseRecord",
  receiveCourseRecord: "receiveCourseRecord",
  errorRequestCourseRecord: "errorRequestCourseRecord"
}

// Async action creator

export const getCourseIndex = (state, modelKey, indexKey='default') => {
  if (modelKey in state.course) {  
    if(indexKey in state.course[modelKey].indexes){
      return state.course[modelKey].indexes[indexKey]
    }
  }
  
  return courseIndexInitial
}

export const fetchCourseIndex = (modelKey, indexKey, params=null,courseKey=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getCourseIndex(getState(), modelKey, indexKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
    
    let url = null  
    
    if(indexKey !== 'default' && courseKey != null){
      url = process.env.REACT_APP_GOAPP_API_URL +"/course/api/course/"+courseKey+"/"
    }else{
      url = process.env.REACT_APP_GOAPP_API_URL +"/course/api/course/"
    }
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "?" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    dispatch(requestCourseIndex(modelKey, indexKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveCourseIndex(modelKey, indexKey, params, json)))
      .catch(error => dispatch(errorRequestCourseIndex(modelKey, indexKey, params, error)));
  }
};

export const getCourseDetailIndex = (state, modelKey, indexKey='default') => {
  if (modelKey in state.course) {  
    if(indexKey in state.course[modelKey].indexes){
      return state.course[modelKey].indexes[indexKey]
    }
  }
  
  return courseIndexInitial
}

export const fetchCourseDetailIndex = (modelKey, indexKey, params=null,courseKey=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getCourseDetailIndex(getState(), modelKey, indexKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
    
    let url = null  
    
    if(indexKey !== 'default' && courseKey != null){
      url = process.env.REACT_APP_GOAPP_API_URL +"/course/api/course/"+courseKey+"/"
    }else{
      url = process.env.REACT_APP_GOAPP_API_URL +"/course/api/course/"
    }
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "?" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    dispatch(requestCourseDetailIndex(modelKey, indexKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveCourseDetailIndex(modelKey, indexKey, params, json)))
      .catch(error => dispatch(errorRequestCourseDetailIndex(modelKey, indexKey, params, error)));
  }
};

export const getCourseRecord = (state, modelKey, recordKey) => {
  if (modelKey in state.course) {
    if (recordKey in state.course[modelKey].records)
      return state.course[modelKey].records[recordKey]
  }
  
  return courseRecordInitial
}


export const fetchCourseRecord = (modelKey, recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getCourseRecord(getState(), modelKey, recordKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/course/api/course/" + recordKey + "/"
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    dispatch(requestCourseRecord(modelKey, recordKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key }            
        })
      .then(response => response.json())
      .then(json => dispatch(receiveCourseRecord(modelKey, recordKey, params, json)))
      .catch(error => dispatch(errorRequestCourseRecord(modelKey, recordKey, params, error)))
  }
};

// Synchronous Action Creators for Course Index

export const requestCourseIndex = (modelKey, indexKey, params) => ({
  type: ACTION.requestCourseIndex,
  modelKey,
  indexKey,
  params
});

export const receiveCourseIndex = (modelKey, indexKey, params, json) => {

  let result = {
    // count: json.courses.length,
    records: json
  }
//  alert("receiveCourseIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveCourseIndex,
    modelKey,
    indexKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestCourseIndex = (modelKey, indexKey, params, error) => ({
  type: ACTION.errorRequestCourseIndex,
  modelKey,
  indexKey,
  params,
  error
});

export const requestCourseDetailIndex = (modelKey, indexKey, params) => ({
  type: ACTION.requestCourseIndex,
  modelKey,
  indexKey,
  params
});

export const receiveCourseDetailIndex = (modelKey, indexKey, params, json) => {

  let result = {
    // count: json.courses.length,
    records: json
  }
//  alert("receiveCourseIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveCourseIndex,
    modelKey,
    indexKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestCourseDetailIndex = (modelKey, indexKey, params, error) => ({
  type: ACTION.errorRequestCourseIndex,
  modelKey,
  indexKey,
  params,
  error
});

export const requestCourseRecord = (modelKey, CourseKey, params) => ({
  type: ACTION.requestCourseRecord,
  modelKey,
  CourseKey,
  params
});

export const receiveCourseRecord = (modelKey, CourseKey, params, json) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveCourseRecord,
    modelKey,
    CourseKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestCourseRecord = (modelKey, CourseKey, params, error) => ({
  type: ACTION.errorRequestCourseRecord,
  modelKey,
  CourseKey,
  params,
  error
});

// Reducers for Course Index

const courseIndexReducer = createReducer(courseIndexInitial, {
  [ACTION.requestCourseIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveCourseIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestCourseIndex]: (state, action) => {
      alert("Request course index failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function courseIndexByKeyUpdater(state, action) {
  let key = action.indexKey
  return {
    ...state,
    [key]: courseIndexReducer(state[key], action)
  }
}

const courseIndexByKeyReducer = createReducer({}, {
  [ACTION.requestCourseIndex]: courseIndexByKeyUpdater,
  [ACTION.receiveCourseIndex]: courseIndexByKeyUpdater,
  [ACTION.errorReceiveCourseIndex]: courseIndexByKeyUpdater,
})


// Reducers for Course Record

const courseRecordReducer = createReducer(courseRecordInitial, {
  [ACTION.requestCourseRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveCourseRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestCourseRecord]: (state, action) => {
      alert("Request Course record failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function courseRecordByKeyUpdater(state, action) {
  let key = action.courseKey
  return {
    ...state,
    [key]: courseRecordReducer(state[key], action)
  }
}

const courseRecordByKeyReducer = createReducer({}, {
  [ACTION.requestCourseRecord]: courseRecordByKeyUpdater,
  [ACTION.receiveCourseRecord]: courseRecordByKeyUpdater,
  [ACTION.errorRequestCourseRecord]: courseRecordByKeyUpdater,
})

// Combine all Course reducer

const courseReducer = combineReducers({
  indexes: courseIndexByKeyReducer,
  records: courseRecordByKeyReducer,
});

// Course model by key reducer

function courseModelReducer(state={}, action) {

  if (action.type in ACTION) {  
    let modelKey = action.modelKey
    let newState = {
      ...state,
      [modelKey]: courseReducer(state[modelKey], action)
    }
//    alert(JSON.stringify(newState, null, 2))
    return newState
  }
  
  return state
}

export default courseModelReducer
