import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from '../../alpha/data/reducers/utility'

// Data structure

const trainerRecordInitial = {
  isFetching: false,
  isPartial: false,
  isError: false,
  lastFetched: null,
  data: { }
}

const trainerIndexInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  data: { records: null }
}

// Actions
const ACTION = {
  requestTrainerIndex: "requestTrainerIndex",
  receiveTrainerIndex: "receiveTrainerIndex",
  errorRequestTrainerIndex: "errorRequestTrainerIndex",
  requestTrainerRecord: "requestTrainerRecord",
  receiveTrainerRecord: "receiveTrainerRecord",
  errorRequestTrainerRecord: "errorRequestTrainerRecord"
}

// Async action creator

export const getTrainerIndex = (state, modelKey, indexKey='default') => {
  if (modelKey in state.trainer) {
    if (indexKey in state.trainer[modelKey].indexes)
      return state.trainer[modelKey].indexes[indexKey]
  }
  
  return trainerIndexInitial
}

export const fetchTrainerIndex = (modelKey, indexKey, params=null, profileKey=null, refresh=false) => {
  return (dispatch, getState) => {
    let res = getTrainerIndex(getState(), modelKey, indexKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    if (!indexKey)
      indexKey = "default"
    
    let url = null
  
    if(indexKey !== 'default' && profileKey != null){
      url = url = process.env.REACT_APP_GOAPP_API_URL +"/trainer/api/trainer/"+profileKey+"/"
    }else{
      url = process.env.REACT_APP_GOAPP_API_URL +"/trainer/api/trainer/"
    }
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
//    alert("Fetching: " + url)

    dispatch(requestTrainerIndex(modelKey, indexKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainerIndex(modelKey, indexKey, params, json)))
      .catch(error => dispatch(errorRequestTrainerIndex(modelKey, indexKey, params, error)));
  }
};

export const getTrainerRecord = (state, modelKey, recordKey) => {
  if (modelKey in state.trainer) {
    if (recordKey in state.trainer[modelKey].records)
      return state.trainer[modelKey].records[recordKey]
  }
  
  return trainerRecordInitial
}


export const fetchTrainerRecord = (modelKey, recordKey, refresh=false, params=null) => {
  return (dispatch, getState) => {
    let res = getTrainerRecord(getState(), modelKey, recordKey)
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
  
    let url = process.env.REACT_APP_GOAPP_API_URL + "/trainer/api/trainer/" + recordKey + "/"
    let api_key = process.env.REACT_APP_GOAPP_API_KEY
    
    if (params)
      url += Object.keys(params).map(key =>
        "&" + key + "=" + params[key]).join("")
        
    dispatch(requestTrainerRecord(modelKey, recordKey, params)); 
    return fetch(url, { 
              headers: { 'X-API-Key': api_key }            
        })
      .then(response => response.json())
      .then(json => dispatch(receiveTrainerRecord(modelKey, recordKey, params, json)))
      .catch(error => dispatch(errorRequestTrainerRecord(modelKey, recordKey, params, error)))
  }
};

// Synchronous Action Creators for Trainer Index

export const requestTrainerIndex = (modelKey, indexKey, params) => ({
  type: ACTION.requestTrainerIndex,
  modelKey,
  indexKey,
  params
});

export const receiveTrainerIndex = (modelKey, indexKey, params, json) => {

  let result = {
    count: json.length,
    records: json
  }
//  alert("receiveTrainerIndex")
//  alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveTrainerIndex,
    modelKey,
    indexKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainerIndex = (modelKey, indexKey, params, error) => ({
  type: ACTION.errorRequestTrainerIndex,
  modelKey,
  indexKey,
  params,
  error
});

export const requestTrainerRecord = (modelKey, TrainerKey, params) => ({
  type: ACTION.requestTrainerRecord,
  modelKey,
  TrainerKey,
  params
});

export const receiveTrainerRecord = (modelKey, TrainerKey, params, json) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveTrainerRecord,
    modelKey,
    TrainerKey,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestTrainerRecord = (modelKey, TrainerKey, params, error) => ({
  type: ACTION.errorRequestTrainerRecord,
  modelKey,
  TrainerKey,
  params,
  error
});

// Reducers for Trainer Index

const trainerIndexReducer = createReducer(trainerIndexInitial, {
  [ACTION.requestTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainerIndex]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTrainerIndex]: (state, action) => {
      alert("Request trainer index failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function trainerIndexByKeyUpdater(state, action) {
  let key = action.indexKey
  return {
    ...state,
    [key]: trainerIndexReducer(state[key], action)
  }
}

const trainerIndexByKeyReducer = createReducer({}, {
  [ACTION.requestTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.receiveTrainerIndex]: trainerIndexByKeyUpdater,
  [ACTION.errorReceiveTrainerIndex]: trainerIndexByKeyUpdater,
})


// Reducers for Trainer Record

const trainerRecordReducer = createReducer(trainerRecordInitial, {
  [ACTION.requestTrainerRecord]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveTrainerRecord]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isPartial: false,
        lastFetched: action.receivedAt,
        data: action.result
      }        
    },
  [ACTION.errorRequestTrainerRecord]: (state, action) => {
      alert("Request Trainer record failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    }
})

function trainerRecordByKeyUpdater(state, action) {
  let key = action.trainerKey
  return {
    ...state,
    [key]: trainerRecordReducer(state[key], action)
  }
}

const trainerRecordByKeyReducer = createReducer({}, {
  [ACTION.requestTrainerRecord]: trainerRecordByKeyUpdater,
  [ACTION.receiveTrainerRecord]: trainerRecordByKeyUpdater,
  [ACTION.errorRequestTrainerRecord]: trainerRecordByKeyUpdater,
})

// Combine all Trainer reducer

const trainerReducer = combineReducers({
  indexes: trainerIndexByKeyReducer,
  records: trainerRecordByKeyReducer,
});

// Trainer model by key reducer

function trainerModelReducer(state={}, action) {

  if (action.type in ACTION) {  
    let modelKey = action.modelKey
    let newState = {
      ...state,
      [modelKey]: trainerReducer(state[modelKey], action)
    }
//    alert(JSON.stringify(newState, null, 2))
    return newState
  }
  
  return state
}

export default trainerModelReducer
