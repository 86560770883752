import React, {useState} from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Box from '@material-ui/core/Box'
import { Container } from '@material-ui/core';
import Markdown from '../ui/Markdown'
import Fade from 'react-reveal/Fade';
import { display } from '@material-ui/system';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionJoinTrainer:{
    // background: 'black',
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    background: 'white',
    [theme.breakpoints.down('xs')]:{
        margin: 0,
        padding: 0,
    }
  },
  divJoinTrainer:{
    textAlign: 'left',
    [theme.breakpoints.down('xs')]:{
        textAlign: 'center'
    }
  },
  titleJoinTrainer:{
      marginBottom: theme.spacing(8),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]:{
          fontSize: '36px',
          marginBottom: theme.spacing(3),
          textAlign: 'center',
          marginRight: 0
      }
  },
  descJoinTrainer:{
      fontWeight: 'bold',
      color: '#7A7A7A',
      marginBottom: '2rem'
  },
  itemJoinTrainer:{
      textAlign: 'center',
      fontSize: '24px'
  },
  iconJoinTrainer:{
      fontSize: '48px'
  },
  JoinTrainerFeature:{
      textAlign: 'center',
      [theme.breakpoints.down('xs')]:{
        marginBottom: theme.spacing(6)
    }
  },
  detailIcon:{
      [theme.breakpoints.down('md')]:{
          fontSize: '16px',
      }
  },
  detailJoinTrainer:{
    [theme.breakpoints.down('md')]:{
        marginBottom: theme.spacing(3)
    }
  },
  imageContent:{
    minHeight: '50vh',
    [theme.breakpoints.down('sm')]:{
        height: '50vh',
        marginTop: theme.spacing(3),
        // display:'none'
    }
  }
});

const TopContent = ({props,classes,variant,body, items, link_to, link_title, maxHeight, openLink, background_images}) => {

    let rootStyle = {}
    let image = ''

    if(variant !== 'dark'){
        rootStyle['color'] = 'black'
        rootStyle['background'] = 'white'
        image = background_images || 'https://images.unsplash.com/photo-1516534775068-ba3e7458af70?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'
    }else{
        rootStyle['color'] = 'white'
        rootStyle['background'] = 'black'
        image = background_images || 'https://images.unsplash.com/photo-1472417583565-62e7bdeda490?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
    }

    // if(maxHeight === 'full'){
    //     rootStyle['minHeight'] = '100vh'
    // }

    return (
        <div className={classes.sectionJoinTrainer} style={rootStyle}>
            <Container>
                <Grid container spacing={10}>
                <Grid item md={6} xs={12} className={classes.imageContent} style={{background:'url('+image+')',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Fade>
                    <div className={classes.divJoinTrainer}>
                        <Markdown source={ body } />
                        <div className={classes.JoinTrainerFeature}>
                        <Grid container spacing={5} alignItems="center" justify="space-evenly" alignItems="baseline">
                            { items !== undefined && items.map((item) => (
                                <Grid item xs={4} sm={4} md={4}>
                                    <div className={classes.item}>
                                        <Box align="center">
                                            <FavoriteIcon style={{fontSize:'3rem'}}/>        
                                            <Markdown source={ item.body }/>
                                        </Box>
                                    </div>
                                </Grid>
                                )) 
                            }
                            { link_to && link_to !== undefined &&
                        <Box align="center" mt={3}>
                            <p/>
                            <Button variant="contained" color="primary" onClick={ openLink } size='large'>
                            { link_title }
                            </Button>
                        </Box>
                        }    
                        </Grid>    
                        </div>
                    </div>
                    </Fade>
                </Grid>
            </Grid>
            </Container>
        </div>
    )
}

TopContent.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles, { withTheme: true })(TopContent);