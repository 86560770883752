import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerStep:{
    // background: '#acacac',
    // paddingTop: theme.spacing(9),
    // marginBottom: theme.spacing(3),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    // alignItems:"center",
    // display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    // background: 'white'
  },
  titleSection:{
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]:{
      width:'100%'
    }
    // marginTop: '70px',
  },
  backdropClass:{
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '70vh',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.between('sm','md')]:{
      height: '80vh'
    },
    [theme.breakpoints.down('xs')]:{
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      height: '100%'
    }
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: '100',
    marginBottom: theme.spacing(6)
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
      color: '#6a6a6a',
      marginTop: theme.spacing(6)
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1),
  },
  titleStep:{
      fontWeight: 'bold',
      padding: '0 16px',
  },
  descStep:{
      padding: '0 40px'
  },
  itemsData:{
    [theme.breakpoints.down('xs')]:{
      marginBottom: theme.spacing(3)
    }
  }
});

const TrainerStep = ({ classes, topicKey ,background_images, trainerList, history, body, items, variant }) => {

    let title = '';
    let rootStyle = {}
    let rootColor = {}
    let rootDesc = {}
    let backdropStyle = {}
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : ''
    
    if(variant !== 'dark'){
      rootStyle['color'] = 'black'
      rootColor['color'] = 'black'
      rootDesc['color'] = '#999'
      if(backgroundImage){
        rootStyle['background'] = 'url('+backgroundImage+')'
      }else{
        rootStyle['background'] = 'white'
      }
      rootStyle['backgroundRepeat'] = 'no-repeat'
      rootStyle['backgroundSize'] = 'cover'
      // backdropStyle['background'] = '#7473737a'
  }else{
      rootStyle['color'] = 'white'
      rootColor['color'] = 'white'
      rootDesc['color'] = '#eee'
      if(backgroundImage){
        rootStyle['background'] = 'url('+backgroundImage+')'
      }else{
        rootStyle['background'] = 'white'
      }
      rootStyle['backgroundRepeat'] = 'no-repeat'
      rootStyle['backgroundSize'] = 'cover'
      backdropStyle['background'] = '#0000007a'
  }
    
    return (
        <div className={classes.sectionTrainerStep} style={rootStyle}>
          <div className={classes.backdropClass} style={backdropStyle}>
            <Container>
                <div className={classes.titleSection}>
                <Fade>
                  {body ?
                  <Markdown source={ body }/> 
                  :
                  <Typography variant='h2' className={classes.categoryName}>
                    <b style={{fontWeight:700}}>How It</b> Works
                  </Typography>
                  }
                </Fade>
                </div>
                <Fade>
                <div className={classes.descLanding}>
                        <Grid container>
                          {items && items.map(items =>
                            <Grid items xs className={classes.itemsData}>
                            {/* <BusinessCenterOutlinedIcon className={classes.iconLanding} style={rootDesc}/> */}
                            {items.icons}
                            <Typography variant='h5' className={classes.titleStep} style={rootColor}>
                                {items.title}
                            </Typography>
                            <Typography variant='body1' className={classes.descStep} style={rootDesc}>
                                {items.description}
                            </Typography>
                          </Grid>
                          )}
                          {!items &&
                          <>
                            <Grid items sm={4} className={classes.itemsData}>
                                <BusinessCenterOutlinedIcon className={classes.iconLanding} style={rootDesc}/>
                                <Typography variant='h5' className={classes.titleStep} style={rootColor}>
                                    1. Get Onboard
                                </Typography>
                                <Typography variant='body1' className={classes.descStep} style={rootDesc}>
                                    Contact us, sign up for free and get discovered by our clients.
                                </Typography>
                            </Grid>
                            <Grid items sm={4} className={classes.itemsData}>
                                <MenuBookOutlinedIcon className={classes.iconLanding} style={rootDesc}/>
                                <Typography  variant='h5' className={classes.titleStep} style={rootColor}>
                                    2. Teach Well & Passionately
                                </Typography>
                                <Typography variant='body1' className={classes.descStep} style={rootDesc}>
                                    Teach the topic you love. We’ll assist you with everything you need for it.
                                </Typography>
                            </Grid>
                            <Grid items sm={4} className={classes.itemsData}>
                                <AccountBalanceWalletOutlinedIcon className={classes.iconLanding} style={rootDesc}/>
                                <Typography  variant='h5' className={classes.titleStep} style={rootColor}>
                                    3. Get Paid
                                </Typography>
                                <Typography variant='body1' className={classes.descStep} style={rootDesc}>
                                    Earn after your teaching session is done. Get paid on time, every time.
                                </Typography>
                            </Grid>
                            </>
                          }
                        </Grid>
                    </div>
                    </Fade>
            </Container>
            </div>
        </div>
    )
}

TrainerStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainerStep);