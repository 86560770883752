import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionLandingHero:{
    // background: '#acacac',
    // paddingTop: theme.spacing(6),
    // paddingBottom: theme.spacing(6),
    backgroundSize:'cover',
    // height:'100vh',
    minWidth:'100%',
    position: 'relative'
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    color: 'white'
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: 'white'
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
      // display: 'flex',
      // alignItems: 'center',
      // color: 'white',
      // background: '#0000007a',
      // position: 'absolute',
    // bottom: 0,
    // width: '100%',
    // height: '30vh',
    // left: 0,
    [theme.breakpoints.down('xs')]:{
      // padding: theme.spacing(1)
    }
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1),
      [theme.breakpoints.down('xs')]:{
        fontSize: '36px'
      }
  },
  backdropClass:{
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100vh',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
  },
  detailTrainerHero:{
    textAlign: 'center',
    color: '#6a6a6a'
  }
});

const TrainerLandingHero = ({ classes, topicKey ,background_images, trainerList, history, variant, onClick }) => {

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backdropStyle = {}

    if(variant !== 'dark'){
      backdropStyle['background'] = '#7473737a'
    }else{
      backdropStyle['background'] = '#0000007a'
    }

    let backgroundImage = background_images !== undefined ?  background_images : 'https://images.unsplash.com/photo-1475608980029-de8304bf6f6e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
    return (
      <>
        <div className={classes.sectionLandingHero} style={{backgroundImage:`url(`+backgroundImage+`)`}}>
            <div className={classes.backdropClass} style={backdropStyle}>
              <Container>
                  <div className={classes.titleSection}>
                    <Fade>
                      <Typography variant='h2' className={classes.categoryName}>
                          Live More, Work Less
                      </Typography>
                      <Typography variant='h6'>
                          You bring the expertise, we'll work out the rest for you.
                      </Typography>
                      <Button variant='contained' color='primary' className={classes.btnGetStarted} onClick={onClick}>
                          Get Started
                      </Button>
                    </Fade>
                  </div>
              </Container>
            </div>
        </div>
        <div className={classes.descLanding}>
          <Container>
          <Grid container>
                        <Grid items sm={4} xs={4}>
                          <Fade bottom>
                            <div className={classes.detailTrainerHero}>
                              <WorkOutlineOutlinedIcon className={classes.iconLanding}/>
                              <Typography variant='h6'>
                                  Teach the topic you love
                              </Typography>
                            </div>
                          </Fade>
                        </Grid>
                        <Grid items sm={4} xs={4}>
                          <Fade bottom>
                            <div className={classes.detailTrainerHero}>
                              <AccessAlarmOutlinedIcon className={classes.iconLanding}/>
                              <Typography  variant='h6'>
                                  Choose Your own time
                              </Typography>
                            </div>
                          </Fade>
                        </Grid>
                        <Grid items sm={4} xs={4}>
                          <Fade bottom>
                            <div className={classes.detailTrainerHero}>
                              <MonetizationOnOutlinedIcon className={classes.iconLanding}/>
                              <Typography  variant='h6'>
                                  Determine your own fee
                              </Typography>
                            </div>
                          </Fade>
                        </Grid>
                    </Grid>
              </Container>
        </div>
      </>
    )
}

TrainerLandingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainerLandingHero);