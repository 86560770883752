import React, {useState} from 'react';
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types'
import ExperienceCard from '../cards/ExperienceCard';

const styles = theme =>({
    dark: {
        color: 'white'
    },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionJoinTrainer:{
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    background: 'black',
    width: '100%',
    minHeight: '0vh',
    [theme.breakpoints.down('xs')]:{
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
  },
  profileDataTrainer:{
      textAlign: 'left'
  },
  experiencesDataTrainer:{
      marginTop: theme.spacing(6),
      textAlign: 'left',
      [theme.breakpoints.down('xs')]:{
          marginTop: theme.spacing(3)
      }
  },
  trainerInfo:{
      marginTop: theme.spacing(12),
      [theme.breakpoints.down('xs')]:{
        marginTop: theme.spacing(3)
      },
      [theme.breakpoints.between('sm','md')]:{
        marginTop: theme.spacing(3)
      }
  },
  trainerExperiencesInfo:{
    marginTop: theme.spacing(12),
    // [theme.breakpoints.down('md')]:{
    //     marginTop: theme.spacing(6),
    // }
    [theme.breakpoints.down('xs')]:{
        marginTop: theme.spacing(3)
    },
    [theme.breakpoints.between('sm','md')]:{
        marginTop: theme.spacing(3)
    }
  },
  infoName:{
      fontWeight: 'bold',
      marginBottom: theme.spacing(3)
  },
  infoHeadline:{
    fontSize: '16px',
    color: '#a3a3a3',
    [theme.breakpoints.down('md')]:{
        margin: 0
    }
},
infoExperiencesDate:{
    color: '#1565C0',
    [theme.breakpoints.down('xs')]:{
        // textAlign: 'center',
        fontSize: '36px'
    }
},
infoExperiencesDesc:{
    fontSize: '24px',
    color: '#a3a3a3',
    [theme.breakpoints.down('xs')]:{
        // textAlign: 'center'
    }
},
titleExperiencesTrainer:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]:{
        // textAlign: 'center',
        marginBottom: theme.spacing(3),
        // fontSize: '24px'
        margin:0
    },
    [theme.breakpoints.between('xs','md')]:{
        marginLeft: 0
    }
},

});

const TrainerExperiences = ({ classes, hashKey, theme, body, items, variant, images, background_images, link_to, link_title, openLink, trainerList }) => {
   
    return (
        <div className={clsx(classes.sectionJoinTrainer,classes.dark)}>
            {trainerList.data.records &&
            <Container>
                <Grid container className={classes.profileDataTrainer}>
                    <Grid item md={6} xs={12}>
                        {trainerList.data.records.profile_picture_dark !== undefined && trainerList.data.records.profile_picture_dark.length > 0 &&
                            <img src={trainerList.data.records.profile_picture_dark[0].image_url} alt="Logo" width="100%"/>
                        }
                        {trainerList.data.records.profile_picture_dark === undefined &&
                            <img src="https://images4.alphacoders.com/388/38806.png" alt="Logo" width="100%"/>
                        }
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Container>
                            <div className={classes.trainerInfo}>
                                <Typography className={classes.infoName} variant="h2">{trainerList.data.records.first_name + ' ' + trainerList.data.records.last_name}</Typography>
                                <Typography className={classes.infoHeadline}>{trainerList.data.records.summary}</Typography>
                            </div>
                            <div className={classes.trainerExperiencesInfo}>
                                <Grid container spacing={6}>
                                    <Grid item md={6} xs={6}>
                                        <Typography className={classes.infoExperiencesDate} variant="h3">{trainerList.data.records.years_of_experience}+ yrs</Typography>
                                        <Typography className={classes.infoExperiencesDesc} variant="h5">Professional Experience</Typography>
                                    </Grid>
                                    {/* <Grid item md={6} xs={12}>
                                        <Typography className={classes.infoExperiencesDate} variant="h3">{trainerList.data.records.year_of_birth}</Typography>
                                        <Typography className={classes.infoExperiencesDesc} variant="h5">Born</Typography>
                                    </Grid> */}
                                </Grid>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <Grid container className={classes.experiencesDataTrainer}>
                <Grid item md={6} xs={12}></Grid>
                <Grid item md={6} xs={12} className={classes.leftText}>
                    <Container>
                        <Typography className={classes.titleExperiencesTrainer} variant="h4">Professional Experiences</Typography>
                    </Container>
                </Grid>
                
                {trainerList.data.records.experiences.map(item =>
                    <ExperienceCard item={item} type='Experiences'/>
                )}

                {trainerList.data.records.educations.length > 0 &&
                <>
                <Grid item md={6} xs={12}></Grid>
                <Grid item md={6} xs={12} className={classes.leftText}>
                    <Container>
                        <Typography className={classes.titleExperiencesTrainer} variant="h4">Educations</Typography>
                    </Container>
                </Grid>

                {trainerList.data.records.educations.map(item =>
                    <ExperienceCard item={item} type='Educations'/>
                )}
                </>
                }

                </Grid>
            </Container>
            }
        </div>
    )
}

TrainerExperiences.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles, { withTheme: true })(TrainerExperiences);