import React from 'react';
import { Helmet } from 'react-helmet'

const NotFound = () => (
    <>
    <Helmet>
        <title>404 Page Not Found - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <h1>
        Not found
    </h1>
    </>
);

export default NotFound