import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Fade from 'react-reveal/Fade';
import DoneIcon from '@material-ui/icons/Done';
import {
  Link
} from "react-router-dom";

const styles = theme => ({
  TrainerCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  itemTraining:{
    cursor:'pointer',
    width: 210, 
    height: 210,
    opacity: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 150, 
      height: 150,
      marginRight: theme.spacing(1.5)
    },
    [theme.breakpoints.between('xs','sm')]:{
      marginRight: 0
    }
    // paddingTop: '24px'
  },
  trainingCard:{
    width: 200, 
    height: 200,
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 150, 
      height: 150,
    }
  },
  trainingCardActive:{
    // border: '5px solid #ff6308',
    width: 200, 
    height: 200,
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 150, 
      height: 150,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '1rem',
    }
  },
  countCategory:{
    marginBottom: 0,
    [theme.breakpoints.down('xs')]:{
      fontSize: '1rem',
    }
  },
  iconActive:{
    float: 'right',
    margin: theme.spacing(1),
    fontSize: '36px'
  }
})


const CourseTopic = ({ classes, item, onClick, history, topicKey }) => {

  // topicKey = topicKey || 'default'

  const openLink = (linkTo) => {
    history.push(linkTo)
  }
    
  return (
    <>
        {item.key === topicKey &&
        <Link to={'/course/'+item.key} style={{color:'black'}}>
          <div className={classes.itemTraining}>
            <div className={classes.trainingCardActive} style={{background:"url(http://api.thumbr.it/whitenoise-361x370.png?background=68D3FD&noise=626262&density=40&opacity=15)",boxShadow: '-5px 5px 0px 0px #4ea6ca88,-10px 10px 0px 0px #4ea6ca55'}}>
                <div className={classes.categoriesData}>
                  {item.key !== 'default' &&
                    <Typography variant="h1" className={classes.titleCategory}>
                        {item.name}
                    </Typography>
                  }
                  {item.key === 'default' &&
                    <Typography variant="h1" className={classes.titleCategory}>
                        All Topics
                    </Typography>
                  }
                    <Typography className={classes.countCategory}>
                        {item.size} Courses
                    </Typography>

                </div>
                {/* <DoneIcon className={classes.iconActive}/> */}
            </div>
          </div>
          </Link>
        }
        {item.key !== topicKey &&
        <Link to={'/course/'+item.key} style={{color:'black'}}>
          <div className={classes.itemTraining}>
            <div className={classes.trainingCard} style={{background:'url(/card_4ea6caff.png)',boxShadow: '-5px 5px 0px 0px #4ea6ca88,-10px 10px 0px 0px #4ea6ca55'}}>
                <div className={classes.categoriesData}>
                  {item.key !== 'default' &&
                    <Typography variant="h1" className={classes.titleCategory}>
                        {item.name}
                    </Typography>
                  }
                  {item.key === 'default' &&
                    <Typography variant="h1" className={classes.titleCategory}>
                        All Topics
                    </Typography>
                  }
                    <Typography className={classes.countCategory}>
                        {item.size} Courses
                    </Typography>
                </div>
            </div>
          </div>
          </Link>
        }
    </>
    )
}

CourseTopic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourseTopic);
