import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import Markdown from '../components/ui/Markdown'
import {
  Link
} from "react-router-dom";
import { Box } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  footer:{
    background: 'transparent',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    minHeight:'0vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    [theme.breakpoints.down('sm')]:{
      textAlign: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(12),
    }
  },
  nameCompany:{
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.875rem'
    }
  },
  footerLogo:{
    textAlign: 'right',
    [theme.breakpoints.down('sm')]:{
      textAlign: 'center',
      marginTop: theme.spacing(1)
    }
  },
  linkSocial:{
    color: 'black'
  },
  imageLogo:{
    width:'30%',
    [theme.breakpoints.down('sm')]:{
      textAlign: 'center',
      width:'100%',
    }
  },
  menuFooter:{
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  companyProfile:{
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  },
  iconSocial:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  linkFooter:{
    textDecoration: 'none',
    color: '#999',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.875rem'
    }
  },
  linkFooters:{
    textDecoration: 'none',
    color: '#999',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.875rem'
    },
    '&:hover': {
      color: '#3f51b5',
    }
  },
  iconWeb:{
    color: '#999',
    marginRight: theme.spacing(1)
  },
  noDecoration:{
    textDecoration: 'none'
  }
});

const Footer = ({ classes, business }) => {

    let title = '';
    return (
        <div className={clsx(classes.footer,classes.dark)}>
            <Container>
                <Grid container>
                  <Grid items sm={4} className={classes.companyProfile}>
                    <Typography variant='body1' className={classes.nameCompany}>
                      PT Pasar Jasa Profesional
                    </Typography>
                    <Typography variant='body1' className={classes.linkFooter}>
                      Equity Tower 37th Floor Unit D & H, SCBD Lot. 9, Jl. Jenderal Sudirman Kav. 52-53, Jakarta, 12190
                    </Typography>
                    <a href='tel:+622129277909' className={classes.noDecoration}>
                      <Typography variant='body1' className={classes.linkFooters}>
                        Tel: +6221 2927 7909
                      </Typography>
                    </a>
                    <a href='mailto:contact@pasartrainer.com'  className={classes.noDecoration}>
                      <Typography variant='body1' className={classes.linkFooters}>
                        Email: contact@pasartrainer.com
                      </Typography>
                    </a>
                    {/* <Box display='flex' alignItems='center'>
                      <LanguageIcon className={classes.iconWeb}/>
                      <Typography variant='body1' className={classes.linkFooter}>
                        www.pasartrainer.com
                      </Typography>
                    </Box> */}
                    <Typography variant='body1' className={classes.linkFooter}>
                        www.pasartrainer.com
                    </Typography>
                  </Grid>
                  <Grid items sm={2} className={classes.menuFooter}>
                      <Typography variant='body1' className={classes.nameCompany}>
                        Company
                      </Typography>
                      <Link className={classes.linkFooter} to="/page/about-pasar-trainer">
                        <Typography variant='body1' className={classes.linkFooters}>
                          About Us
                        </Typography>
                      </Link>
                      {/* <Link className={classes.linkFooter} to="/page/terms-and-conditions">
                        <Typography variant='body1' className={classes.linkFooter}>
                          Terms & Conditions
                        </Typography>
                      </Link>
                      <Link className={classes.linkFooter} to="/page/privacy-policy">
                        <Typography variant='body1' className={classes.linkFooter}>
                          Privacy Policy
                        </Typography>
                      </Link> */}
                  </Grid>
                  <Grid items sm={6} xs={12}>
                    <div className={classes.footerLogo}>
                      <a href="https://twitter.com/PasarTrainer" className={classes.linkSocial}><TwitterIcon className={classes.iconSocial}/></a>
                      <a href="https://www.instagram.com/pasartrainer/" className={classes.linkSocial}><InstagramIcon className={classes.iconSocial}/></a>
                      <a href="https://www.facebook.com/PasarTrainer/" className={classes.linkSocial}><FacebookIcon className={classes.iconSocial}/></a>
                      <a href="https://www.linkedin.com/company/pasartrainer" className={classes.linkSocial}><LinkedInIcon className={classes.iconSocial}/></a>
                    </div>
                  </Grid>
                </Grid>
            </Container>
        </div>
    )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Footer);