import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  leftText:{
    textAlign: 'left'
    },
  rightText:{
    textAlign: 'right'
  },
  detailExperiences:{
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
        // textAlign: 'center',
        marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('xs','sm')]:{
      textAlign: 'left'
    }
    },
    detailDateExperience:{
      color: '#a3a3a3',
        marginBottom: theme.spacing(3)
    },
    infoExperiencesTitle:{
        color: '#1565C0',
        [theme.breakpoints.down('xs')]:{
            // textAlign: 'center',
            // fontSize: '24px',
            padding: 0
        },
        [theme.breakpoints.between('sm','md')]:{
          // textAlign: 'left'
        }
    },
    dateRangeExperience:{
        // fontSize: '24px',
        color: '#a3a3a3',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]:{
            // textAlign: 'center'
        }
    },
})


const ExperienceCard = ({ classes, item, onClick }) => {

  let start_date = moment(item.start_date).year()
  let end_date = moment(item.end_date).year()

  return (
    <Grid container>
        <Grid item md={6} xs={12} className={classes.rightText}>
        <Container className={classes.detailExperiences}>
            <Typography className={classes.infoExperiencesTitle} variant="h5">{item.place}</Typography>
        </Container>
        </Grid>
        <Grid item md={6} xs={12} className={classes.leftText}>
        <Container>
            <Typography variant='h5'><b>{item.title}</b></Typography>
            <Typography className={classes.dateRangeExperience}>
            {start_date+" "} 
             - 
            {end_date || ' Present'}
            </Typography>
        </Container>
        </Grid>    
    </Grid>
  )
}

ExperienceCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExperienceCard);
