import React, { useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Route, Link as RouterLink, withRouter } from 'react-router-dom'
import { getContentRecord, fetchContentRecord } from '../alpha/data/reducers/content'
import { logEvent } from '../alpha/data/reducers/visitor'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'
import Story from './Story'
import Chat from './Chat'
import Inbox from './Inbox'

import Hero from '../components/sections/Hero'
import ContentInColumn from '../components/sections/ContentInColumn'
import ContentInRow from '../components/sections/ContentInRow'
import Text from '../components/sections/Text'
import ContactForm from '../components/sections/ContactForm'
import ChatForm from '../components/sections/ChatForm'
import BlogFeed from '../components/sections/BlogFeed'
import CourseList from '../components/sections/CourseList'
import TrainerSlide from '../components/sections/TrainerSlide'
import ArticleList from '../components/sections/ArticleList'
import ContentWithColumn from '../components/sections/ContentWithColumn'
import LeftRightContent from '../components/sections/LeftRightContent'
import AboutHero from '../components/sections/AboutHero'
import AboutContent from '../components/sections/AboutContent'
import ContactUs from '../components/sections/ContactUs'
import TrainerStep from '../components/sections/TrainerStep'
import { Helmet } from 'react-helmet'
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Avatar, Typography } from '@material-ui/core'

const styles = theme => ({
  root: {
//    maxWidth: 480,
//    display: 'flex',
    width: '100%',
    minHeight: '50vh'
  },
  content: {
  },
  divider: {
    width: '100%',
    height: theme.spacing(1),
//    backgroundColor: theme.palette.common.white,
    backgroundColor: '#ebebeb'
  },
  iconLanding:{
    fontSize:'64px',
    marginBottom:theme.spacing(1),
  },
  iconText:{
    fontSize:'36px',
    marginTop:theme.spacing(0.5),
  },
  circleBorder:{
    padding: theme.spacing(3),
    border: '5px solid white',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    margin: '0 auto',
    marginBottom: theme.spacing(1)
  }
})

const componentMaps = {
  Hero: Hero,
  ContentInColumn: ContentInColumn,
  Text: Text,
  ContactForm: ContactForm,
  ChatForm: ChatForm,
  BlogFeed: BlogFeed,
  CourseList: CourseList,
  ArticleList: ArticleList,
  TrainerSlide: TrainerSlide
}

const Page = ({ classes, history, isFetching, lastFetched, data, fetchPageRecord, pageKey, popupLink, logEvent }) => {

  const navigationContext = useContext(NavigationContext)
  const [initialized, setInitialized] = useState(false);
  
  const topElement = useRef()
  
  useEffect(() => {
    fetchPageRecord()    

    if (lastFetched)
      logEvent('view', { type: 'content', uid: data.uid })
  
  }, [pageKey, lastFetched])
  
  useEffect(() => {
//    navigationContext.showTitle("")    
    navigationContext.showAppBar(false)
        
    // This solve a problem when our page stay on the middle after
    // PageBase drawer changed to different page.
    
    // This will scroll view below sticky app bar. It doesn't go to top
//    topElement.current.scrollIntoView({ behavior: "smooth" })
    
    return () => {
      navigationContext.showAppBar(true)    
    }
    
  }, [])
  
  useEffect(() => {
    navigationContext.setLoading(isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [isFetching])
  
  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }
  
  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("#")) {
      // Scroll to section.
//      alert(linkTo)
//      window.location = linkTo
//      window.location.hash = linkTo //linkTo.substring(1)
      const id = linkTo.substring(1)
      const element = document.getElementById(id);
      if (element) 
        element.scrollIntoView({behavior: 'smooth'});
      return
    }
    
    if (linkTo == "chat") {
      // Open chat screen
      openChat(0)
      return
    }
    
    if (pageKey != "home")
      link = "/" + pageKey
  
    if (linkTo.startsWith("product") || linkTo.startsWith("story"))
      link += "/" + linkTo + "?popup=1"
    else if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo
      
    history.push(link)
  }
  
  const [popupContent, setPopupContent] = useState(null)
  
  const showPopup = (content) => {
    setPopupContent(content)
  }
  const closePopup = () => {
    if (popupContent)
      setPopupContent(null)
    else
      openLink("")
  }

  const capitalize = (str) =>
  {
  return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
  
  let popupContentToShow = popupContent
  if (!popupContentToShow) {
    if (popupLink && popupLink !== undefined) {      
      if (popupLink.startsWith("story"))
        popupContentToShow = (
          <Story slug={popupLink.substring("story/".length)} openLink={ openLink } />
        )        
    }
  }
  
  let sections = null
  if (lastFetched)
    sections = data.sections !== undefined && data.sections.length > 0 && data.sections.map((sectionData) => {

      let component = componentMaps[sectionData.component]
      if (component && component !== undefined) {
        let c = React.createElement(componentMaps[sectionData.component], {
            ...sectionData,
            hashKey: sectionData.key,
            openLink: openLink,
            history: history
          })
        return c
      }
    })
    .reduce((prev, next) => [prev,
        <div className={classes.divider} />    
      , next])
    
  let section2 = '#### **Looking for experts to teach at your training classes has never been easier.**\n Our platform is built to help you find experts and arrange your training courses effortlessly. Whether on your desk or on the go, finding experts and arranging training classes are as easy as ABC. Less meetings, less coordinating, less hassle.'
  let section3 = '#### **22 Courses,127 Learning Modules, 312 Learning Hours and counting.**\n Explore our library of expert-built training programs to find what suits you best. Our course library will be updated periodically to ensure relevancy of the programs.'
  let section4 = '#### **Pay-as-you-go. Why pay when you don’t use it?**\n We implement flexible pricing with zero upfront cost. No license fee, no customization fee, no setup fee, no user fee and no hidden cost. Pay only for the training you want to execute.  '
  
  let step1 = '### **Learning Made Easy – Arrange Training Class in 3 Easy Steps**'
  let step2 = '#### **We partner with industry experts and practitioners who provide quality training with applicable and relevant practice**'


  let icon1 = <SearchIcon className={classes.iconLanding} style={{color:'white'}}/>
  let icon2 = <PersonIcon className={classes.iconLanding} style={{color:'white'}}/>
  let icon3 = <MenuBookIcon className={classes.iconLanding} style={{color:'white'}}/>
  let icon4 = <AssignmentIcon className={classes.iconLanding} style={{color:'white'}}/>

  let icon5 = <div className={classes.circleBorder}><Typography className={classes.iconText} style={{color:'white'}}>92%</Typography></div>
  let icon6 = <div className={classes.circleBorder}><Typography className={classes.iconText} style={{color:'white'}}>92%</Typography></div>
  let icon7 = <div className={classes.circleBorder}><Typography className={classes.iconText} style={{color:'white'}}>92%</Typography></div>

  let items1 = 
  [{
    icons: icon1,
    title: 'Find Courses',
    description: 'Select courses from our database which was built by topic experts or let us help you build a tailored course.'
  },
  {
    icons: icon2,
    title: 'Select Trainers',
    description: 'Get access & select our professional trainers who are senior practitioners and experts in their field.'
  },
  {
    icons: icon3,
    title: 'Class Ready To Deliver',
    description: 'Your training class is ready to go, and our team will spearhead the training execution.'
  }]

  let items2 = [{
    icons: icon5,
    description: 'of the professional trainers have 20+ years of experience in their field'
  },
  {
    icons: icon6,
    description: 'of the professional trainers have Advanced Degree'
  },
  {
    icons: icon7,
    description: 'of the professional trainers are current practitioners in the industry'
  },
  ]

  let linkTitle1 = 'Browse Courses'
  let linkTitle2 = 'Contact Us to Learn More'
  
  let linkTo1 = '/course'
  let linkTo2 = '/inbox'

  let background1 = '/design_app.png'
  let background2 = 'https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80'
  let background3 = 'https://images.unsplash.com/photo-1518655048521-f130df041f66?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  let background4 = 'https://images.unsplash.com/photo-1530037768512-3c9a22715452?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'
  let background5 = 'https://images.unsplash.com/photo-1483791424735-e9ad0209eea2?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'

  
  return (  
    <>
    <Helmet>
        <title>{pageKey ? capitalize(pageKey.replace(/-/g,' ')) : 'Home'} - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContentToShow}
      closePopup={closePopup}>
      <div className={classes.root} ref={topElement}>
        <div className={classes.content}>
          {/* { sections } */}
          {pageKey === 'home' &&
            <>
            {lastFetched &&
            <>
              <CourseList history={history} classes={classes} background_images={data.sections[0].background_images} body={data.sections[0].body}/>
              <div className={classes.divider} />
              <ContentInRow history={history} classes={classes} variant="TopLight" body={section2} maxHeight={'full'} background_images={background1}/>
              <div className={classes.divider} />
              <TrainerStep history={history} classes={classes} body={step1} items={items1} background_images={background3} maxHeight={'full'} variant='dark'/>
              <div className={classes.divider} />
              {/* <ContentInColumn history={history} classes={classes} variant="LeftSideLight" body={section3} link_title={linkTitle1} link_to={linkTo1} openLink={() =>openLink(linkTo1)} maxHeight={'full'} background_images={background3}/>
              <div className={classes.divider} /> */}
              <LeftRightContent history={history} classes={classes} body={section3} body1={section4} link_title={linkTitle1} link_to={linkTo1} link_title1={linkTitle2} link_to1={linkTo2} openLink={() =>openLink(linkTo1)} openLink1={() => navigationContext.showSideContent(<Inbox />)} maxHeight={'full'} background_images={background2} background_images1={background5} variant='dark'/>
              <div className={classes.divider} />
              <TrainerStep history={history} classes={classes} body={step2} items={items2} maxHeight={'full'} background_images={background5} variant='dark'/>
              <div className={classes.divider} />
              <TrainerSlide history={history} classes={classes} body={data.sections[1].body}/>
              {/* <div className={classes.divider} />
              <ArticleList history={history} classes={classes} body={data.sections[2].body}/> */}
              {/* <div className={classes.newDivide} /> */}
              {/* <ContactUs history={history} classes={classes} onClick={()=>openChat(-1)}/> */}
            </>
            }
            </>
          }
          {pageKey !== 'about-pasar-trainer' &&
          <>
            {lastFetched &&
              <ContactUs history={history} classes={classes} onClick={()=>openChat(-1)}/>
            }
          </>
          }


          {/* {pageKey !== 'about-pasar-trainer' &&
            <ContentWithColumn classes={classes} variant='dark'/>
          } */}
          {pageKey === 'about-pasar-trainer' &&
            <>
            <AboutHero classes={classes} variant='dark'/>
            <AboutContent classes={classes} variant='dark'/>
            <ContactUs classes={classes} variant='light' onClick={()=>openChat(-1)}/>
            </>
          }
        </div>
      </div>
    </PageBase>
    </>
    )

}

function pageKeyFromProps(props) {
  let key = null
  if (props.slug && props.slug !== undefined)
    key = props.slug
  else if (props.pageKey && props.pageKey !== undefined)
    key = props.pageKey
  else
    key = props.match.params.pageKey
    
  if (!key || key == undefined)
    key = 'home'

  return key
}

function popupLinkFromProps(props) {
  let link = null
  if (props.match) {
    if (props.match.params.storyKey !== undefined)
      link = "story/" + props.match.params.storyKey
  }
  return link
}

const mapStateToProps = (state, ownProps) => {
  let key = pageKeyFromProps(ownProps)
  
  return {
    ...getContentRecord(state, "page", key),
    pageKey: key,
    popupLink: popupLinkFromProps(ownProps),
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let key = pageKeyFromProps(ownProps)

  return {
    fetchPageRecord: () => {
      dispatch(fetchContentRecord('page', key))
    },
    logEvent: (eventName, data) => {
      dispatch(logEvent(eventName, data))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    Page)
  )
)