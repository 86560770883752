import ls from 'local-storage'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Initial Data

const cartInitial = {
  isUpdating: false,
  lastUpdate: null,
  data: { lines: [] },
  total: 0,
  isUpdatingPayment: false,
  payment_data: { },
}

const cartLineInitial = {
  product: null,
  quantity: 0,
  price_before_discount: 0,
  price: 0,
  line_total: 0,
}

// Actions

const ACTION = {
  updateCart: "updateCart",
  receiveCartUpdate: "receiveCartUpdate",
  errorUpdateCart: "errorUpdateCart",
  updatePaymentData: "updatePaymentData",
  receivePaymentData: "receivePaymentData",
  errorUpdatePaymentData: "errorUpdatePaymentData"
}

// Async Action Creators

export const getCart = (state, cartKey) => {
  if (cartKey in state.alpha.cart)
    return state.alpha.cart[cartKey]
    
  return cartInitial
}

export const getCartLine = (state, cartKey, product) => {
  let cart = getCart(state, cartKey)
  
  return cart.data.lines.find(line => {
    return line.product.uid == product.uid
  })
}

export const addToCart = (cartKey, product, quantity) => {
  return (dispatch, getState) => {
    if (!product) {
      alert("Invalid product")
      return
    }
    
    // Check availability
    if ('ready_quantity' in product && product['ready_quantity'] <= 0) {
      alert("The selected product is not available.")
      return
    }

//    alert(JSON.stringify(product, null, 2))
            
    let line = null
    let state = getState()
    if (cartKey in state.alpha.cart)
      line = state.alpha.cart[cartKey].data.lines.find(line => {
        return line.product.uid == product.uid
      })
    
    let data = {
      lines: [{
        product: { uid: product.uid },
//        catalog_code: product.catalog_code,
        quantity: quantity + (line ? line.quantity : 0)
      }]
    }
    
    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const removeFromCart = (cartKey, product, quantity) => {
  return (dispatch, getState) => {            
    let line = null
    let state = getState()
    if (cartKey in state.alpha.cart)
      line = state.alpha.cart[cartKey].data.lines.find(line => {
        return line.product.uid == product.uid
      })
    
    if (!line || line.quantity <= 0)
      return
      
    let data = {
      lines: [{
        product: { uid: product.uid },
//        uid: product.uid,
//        catalog_code: product.catalog_code,
        quantity: line.quantity - quantity
      }]
    }
    
    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updateShipTo = (cartKey, shipTo) => {
  return (dispatch, getState) => {
    let data = {
      ship_to: shipTo
    }
        
    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updateShippingMethod = (cartKey, shippingMethod) => {
  return (dispatch, getState) => {
    let data = {
      shipping_method: shippingMethod
    }

    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updatePaymentMethod = (cartKey, paymentMethod) => {
  return (dispatch, getState) => {
    let data = {
      payment_method: paymentMethod
    }

    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updateDiscountCode = (cartKey, discountCode) => {
  return (dispatch, getState) => {
    let data = {
      discount_code: discountCode
    }
    
    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updateContact = (cartKey, contact) => {
  return (dispatch, getState) => {
    let data = {
      contact: contact
    }
    
    return updateCartData(cartKey, data)(dispatch, getState)
  }
}

export const updateCartData = (cartKey, data) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/sales/api/cart/" + cartKey + "/"

    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
        
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
      
    dispatch(updateCart(cartKey))    
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => dispatch(receiveCartUpdate(cartKey, json)))
      .catch(error => dispatch(errorUpdateCart(cartKey, error)))
  }
}

export const fetchCart = (cartKey) => {
  return (dispatch, getState) => {
    let cart = getCart(getState(), cartKey)
    if (cart.isUpdating)
      return Promise.resolve()  
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/sales/api/cart/" + cartKey + "/"
//    let user = getState().alpha.user
    
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    
//    alert(url)

    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
        
    dispatch(updateCart(cartKey))
    
    return fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        }
      })
      .then(response => {
          if (response.status == 200)
              return response.json()
          else if (response.status == 404)
              return cartInitial.data
          else
              return Promise.reject(new Error(response.json()))
      })
      .then(json => dispatch(receiveCartUpdate(cartKey, json)))
      .catch(error => dispatch(errorUpdateCart(cartKey, error)))
  }
}

export const fetchPaymentData = (cartKey) => {
  return (dispatch, getState) => {
    let url = process.env.REACT_APP_GOAPP_API_URL + "/sales/api/cart/" + cartKey + "/pay/"
//    let user = getState().alpha.user
    
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id

    let data = { callback_url: process.env.REACT_APP_PUBLIC_URL + "/pay_callback/" }
//    let data = { callback_url: "http://178.128.86.10:9000/pay_callback/" }
    
//    alert(url)
        
    dispatch(updatePaymentData(cartKey))
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
    
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        },
        body: JSON.stringify(data)
      })
      .then(response => {
          if (response.status == 200)
              response.json().then(json =>
                dispatch(receivePaymentData(
                  cartKey, 
                  json
                ))
              )
          else
              response.json().then(json =>
                dispatch(errorUpdatePaymentData(
                  cartKey, 
                  json.join(", "),
                ))
              )
      })
//      .then(json => dispatch(receivePayURL(cartKey, json)))
      .catch(error => dispatch(errorUpdatePaymentData(cartKey, error)))
  }
}

// Synchronous Action Creators

export const updateCart = (cartKey) => ({
  type: ACTION.updateCart,
  cartKey
});

export const receiveCartUpdate = (cartKey, data) => {

  // Normalize contact data if name contains 'Unknown User'
  if (data.contact && data.contact !== undefined && data.contact.first_name && data.contact.first_name !== undefined) {
    if (data.contact.first_name == 'Unknown User')
      data.contact['first_name'] = null
  }

  return {
    type: ACTION.receiveCartUpdate,
    cartKey,
    data,
    receivedAt: Date.now()
  };
}

export const errorUpdateCart = (cartKey, error) => ({
  type: ACTION.errorUpdateCart,
  cartKey,
  error
});

export const updatePaymentData = (cartKey) => ({
  type: ACTION.updatePaymentData,
  cartKey
});

export const receivePaymentData = (cartKey, data) => {
//  alert("receivePaymentData: " + JSON.stringify(data, null, 2))
  return {
    type: ACTION.receivePaymentData,
    cartKey,
    data,
    receivedAt: Date.now()
  };
}

export const errorUpdatePaymentData = (cartKey, error) => ({
  type: ACTION.errorUpdatePaymentData,
  cartKey,
  error
});

// Cart Line Reducers

const cartLineReducer = createReducer(cartLineInitial, {

  [ACTION.addToCart]: (state, action) => {
     let quantity = state.quantity + action.quantity  
      return {
        ...state,
        product: action.product,
        quantity: quantity
      }
    },
  [ACTION.removeFromCart]: (state, action) => {
     let quantity = state.quantity - action.quantity  
      return {
        ...state,
        product: action.product,
        quantity: quantity
      }
    }
})

// Cart Reducers

function saveCartLine(state, action) {

  let line = state.lines.find(l => {
    return l.product.uid == action.product.uid
  })
  
  let lines = null
  if (line) {
    line = cartLineReducer(line, action)
    lines = state.lines.map(item => {
      if (item.product.uid !== line.product.uid)
        return item
      return line
    })
  }
  else {
    line = cartLineReducer(line, action)
    lines = [
      ...state.lines,
      line
    ]
  }
    
  return {
    ...state,
    lines: lines
  }
}

const cartReducer = createReducer(cartInitial, {
  [ACTION.updateCart]: (state, action) => {
      return {
        ...state,
        isUpdating: true
      }
    },
  [ACTION.receiveCartUpdate]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        isError: false,
        lastUpdate: action.receivedAt,
        data: action.data
      }
    },
  [ACTION.errorUpdateCart]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdating: false,
        isError: true
      }
    },
  [ACTION.updatePaymentData]: (state, action) => {
      return {
        ...state,
        isUpdatingPayment: true
      }
    },
  [ACTION.receivePaymentData]: (state, action) => {
      return {
        ...state,
        isUpdatingPayment: false,
        lastUpdate: action.receivedAt,
        payment_data: action.data
      }
    },
  [ACTION.errorUpdatePaymentData]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isUpdatingPayment: false,
      }
    },
  [ACTION.addToCart]: saveCartLine,
  [ACTION.removeFromCart]: saveCartLine,
})

// Cart by ID

function cartByKeyReducer(state={}, action) {

  if (action.type in ACTION) {  
    let cartKey = action.cartKey
    let newState = {
      ...state,
      [cartKey]: cartReducer(state[cartKey], action)
    }
//    alert(JSON.stringify(newState, null, 2))
    return newState
  }
  
  return state
}

export default cartByKeyReducer

