import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  light:{
      color:'black'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerStep:{
    // background: '#acacac',
    backgroundSize:'cover',
    // height:'100vh',
    minWidth:'100%',
    // alignItems:"center",
    // display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative'
  },
  titleSection:{
    // marginTop: '70px',
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]:{
      marginBottom: theme.spacing(6)
    }
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  titleStep:{
      fontWeight: 'bold',
      padding: '0 16px'
  },
  descStep:{
      padding: '0 40px',
      [theme.breakpoints.down('xs')]:{
        padding: '0 16px',
        marginBottom: theme.spacing(1)
      }
  },
  backdropClass:{
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  }
});

const ContentWithColumn = ({ classes, topicKey ,background_images, trainerList, history, variant }) => {

    let rootStyle = {}
    let backdropStyle = {}
    let image = ''

    if(variant !== 'dark'){
        rootStyle['color'] = 'black'
        rootStyle['backgroundImage'] = "url('https://images.unsplash.com/photo-1554415707-c1426270e0da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')"
        // rootStyle['background'] = 'white'
        rootStyle['backdropFilter'] = 'brightness(50%)'
        backdropStyle['background'] = '#7473737a'
    }else{
        rootStyle['color'] = 'white'
        rootStyle['backgroundImage'] = "url('https://images.unsplash.com/photo-1556565681-67b9cd907d20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')"
        rootStyle['backdropFilter'] = 'brightness(50%)'
        // rootStyle['background'] = 'black'
        backdropStyle['background'] = '#0000007a'
    }
    
    return (
        <div className={classes.sectionTrainerStep} style={rootStyle}>
          <div className={classes.backdropClass} style={backdropStyle}>
            <Container>
                <div className={classes.titleSection}>
                  <Fade bottom>
                    <Typography variant='h4' className={classes.categoryName}>
                    Learning Made Easy – Arrange Training Class in 4 Easy Steps
                    </Typography>
                  </Fade>
                </div>
                <Fade bottom>
                <div className={classes.descLanding}>
                        <Grid container>
                            <Grid items xs={12} sm={3}>
                                <BusinessCenterOutlinedIcon className={classes.iconLanding}/>
                                <Typography variant='h5' className={classes.titleStep}>
                                    Find Courses
                                </Typography>
                                <Typography variant='body1' className={classes.descStep}>
                                    Select courses from our database which was built by topic experts or let us help you build a tailored course.
                                </Typography>
                            </Grid>
                            <Grid items xs={12} sm={3}>
                                <MenuBookOutlinedIcon className={classes.iconLanding}/>
                                <Typography  variant='h5' className={classes.titleStep}>
                                    Select Trainers
                                </Typography>
                                <Typography variant='body1' className={classes.descStep}>
                                    Get access & select our professional trainers who are senior practitioners and experts in their field.
                                </Typography>
                            </Grid>
                            <Grid items xs={12} sm={3}>
                                <AccountBalanceWalletOutlinedIcon className={classes.iconLanding}/>
                                <Typography  variant='h5' className={classes.titleStep}>
                                    Class Ready to Deliver
                                </Typography>
                                <Typography variant='body1' className={classes.descStep}>
                                    Your training class is ready to go, and our team will spearhead the training execution.
                                </Typography>
                            </Grid>
                            <Grid items xs={12} sm={3}>
                                <AccountBalanceWalletOutlinedIcon className={classes.iconLanding}/>
                                <Typography  variant='h5' className={classes.titleStep}>
                                    Get Training Report
                                </Typography>
                                <Typography variant='body1' className={classes.descStep}>
                                    Our team will provide you with Training Report which measures the overall training effectiveness
                                </Typography>
                            </Grid>
                        </Grid>
                </div>
                </Fade>
            </Container>
            </div>
        </div>
    )
}

ContentWithColumn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentWithColumn);