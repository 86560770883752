import ls from 'local-storage'
import { combineReducers } from 'redux'
import { updateObject, updateItemInArray, createReducer } from './utility'

// Data structure

export const WSSTATE = {
  Idle: "Idle",
  Connecting: "Connecting",
  Connected: "Connected",
  Disconnected: "Disconnected"
}

const conversationInitial = {
  key: null,
  isFetching: false,
  isError: false,
  lastFetched: null,
  lastUpdate: null, // Last message update
  data: { new_count: 0, unread_count: 0, message_count: 0 },
  messages: [ ],
}

const messageInitial = {
  isSending: false,
  lastUpdate: null,
  data: { }
}

const conversationListInitial = {
  isFetching: false,
  isError: false,
  lastFetched: null,
  isStarting: false,
  newConversationKey: null,
  stats: { new_count: 0, unread_count: 0, message_count: 0 },
  conversations: [ ],
  wsState: WSSTATE.Idle
}

// Actions
const ACTION = {
  wsStateChanged: "wsStateChanged",
  startingConversation: "startingConversation",
  receiveNewConversation: "receiveNewConversation",
  errorStartingConversation: "errorStartingConversation",
  requestConversationList: "requestConversationList",
  receiveConversationList: "receiveConversationList",
  errorRequestConversationList: "errorRequestConversationList",
  conversationDeleted: "conversationDeleted",
  requestConversation: "requestConversation",
  receiveConversation: "receiveConversation",
  errorRequestConversation: "errorRequestConversation",
  requestMessages: "requestMessages",
  receiveMessages: "receiveMessages",
  errorRequestMessages: "errorRequestMessages",
  postMessage: "postMessage",
  receivePostMessageResult: "receivePostMessageResult",
  errorPostMessage: "errorPostMessage",
  receiveNewMessage: "receiveNewMessage",
  readAllMessages: "readAllMessages"
}

// Async action creator

export const fetchConversationList = (refresh=false) => {
  return (dispatch, getState) => {
    let res = getState().alpha.conversationList
    
    if (res.isFetching || (res.lastFetched && !refresh))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/conversation/"
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id

//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestConversationList()); 
    return fetch(url, { 
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization }
        })
      .then(response => response.json())
      .then(json => dispatch(receiveConversationList(json)))
      .catch(error => dispatch(errorRequestConversationList(error)));
  }
}

export const getNewConversation = (state) => {
  if (state.alpha.conversationList.newConversationKey)
    return getConversation(state, state.alpha.conversationList.newConversationKey)
  return null    
}

export const getConversation = (state, conversationKey) => {
  let c = state.alpha.conversationList.conversations.find(c => c.key == conversationKey)  
  if (c === undefined)
    c = null
  return c
}

export const getLatestConversation = (state) => {
  if (state.alpha.conversationList.lastFetched && state.alpha.conversationList.conversations.length)
      return state.alpha.conversationList.conversations[0]
  return null
}

export const startNewConversation = () => {
  return (dispatch, getState) => {
  
    if (getState().alpha.conversationList.isStarting)
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/conversation/start/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(startingConversation()); 
    return fetch(url, { 
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        },
        body: JSON.stringify({})
      })
      .then(response => response.json())
      .then(json => dispatch(receiveNewConversation(json)))
      .catch(error => dispatch(errorStartingConversation(error)));
  }
}

export const deleteConversationIfEmpty = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    
    if (c.messages.length > 0)
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/conversation/" + c.key + "/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestMessages(conversationKey)); 
    return fetch(url, { 
              method: 'DELETE',    
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization
              }
        })
      .then(response => dispatch(conversationDeleted(conversationKey)))
      .catch(error => dispatch(errorRequestMessages(conversationKey, error)));
  }
};

export const fetchConversation = (conversationKey, refresh=false, withMessages=false, partial=false) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    if (!partial && c && (c.isFetching || (c.lastFetched  && !refresh)))
      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/conversation/" + c.key + "/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id    
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
      
    dispatch(requestConversation(conversationKey)); 
    return fetch(url, { 
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => {
        if (withMessages) {
          // Set partial to true, so that isFetching flag will not reset to false
          dispatch(receiveConversation(conversationKey, json, true))
          dispatch(fetchMessages(conversationKey, true, false))
        }
        else
          dispatch(receiveConversation(conversationKey, json, false, true))
      })
      .catch(error => dispatch(errorRequestConversation(conversationKey, error)));
  }
};

export const fetchMessages = (conversationKey, refresh=false, withConversation=false, partial=false) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    if (!partial && c && (c.isFetching || (c.lastFetched  && !refresh)))
      return Promise.resolve()

    // 26 Nov: Try using conversation API to get messages so that we can get
    // conversation update (typing party) in one call.
//    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/message/"
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/conversation/" + c.key + "/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    url = url + "&message_size=50"
    
//    alert("Fetching: " + url)
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    dispatch(requestMessages(conversationKey)); 
    return fetch(url, { 
              headers: { 
              'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
              ...authorization
              }
        })
      .then(response => response.json())
      .then(json => {
        if (withConversation) {
          // Set partial to true, so that isFetching flag will not reset to false
          dispatch(receiveMessages(conversationKey, json, true))
          dispatch(fetchConversation(conversationKey, true, false, true))
        }
        else
          dispatch(receiveMessages(conversationKey, json, false))
      })
      .catch(error => dispatch(errorRequestMessages(conversationKey, error)));
  }
};

export const sendMessage = (conversationKey, text, files=null) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/message/send/"

    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    
    if (!text && !(files && files.length))
      return Promise.resolve()
    
    // c.lastFetched might null if user try to send message
    // while loading message contents for the first time.
    // Keep trying.
//    if (c && c.lastFetched)
    if (c)
      url = url + "&conversation=" + c.data['uid']
    else
      return Promise.resolve()
      

//    alert("sendMessage: " + url)


    
    // Create new Message object
    let message = {
      ...messageInitial,
      key: new Date().valueOf(),
      data: { body: text }
    }
    
//    alert("sendMessage: " + JSON.stringify(message, null, 2))
//    alert("conversationKey: " + conversationKey)

    dispatch(postMessage(conversationKey, message)); 
    
    let data = {
      body: text
    }    
    
    let formData = new FormData()
    formData.append('body', text)
    if (files)
      files.map((file, index) => {
  //      formData.append(`files${index}`, file)
        formData.append('attachments', file)
      })
    
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }
        
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
//          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        },
//        body: JSON.stringify(data)
        body: formData
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receivePostMessageResult(conversationKey, message, json))
        // Refresh conversation to quickly get operator typing_parties
        // This is a temporary hack until we implement websocket for
        // realtime push
//        dispatch(fetchConversation(conversationKey, true))
      })
      .catch(error => dispatch(errorPostMessage(conversationKey, message, error)));
  }
};

export const markReadAll = (conversationKey) => {
  return (dispatch, getState) => {
    let c = getConversation(getState(), conversationKey)
    // Must not check isFetching as we often do fetching and when it's fetching
    // we don't have any chance to mark read
//    if (c.isFetching || !c.lastFetched)
//      return Promise.resolve()
      
    let url = process.env.REACT_APP_GOAPP_API_URL + "/conversation/api/message/read_all/"
      
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
    url = url + "&conversation=" + c.data['uid']
    
    dispatch(readAllMessages(conversationKey))
    
//    alert("Fetching: " + url)

    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_GOAPP_API_KEY,
          ...authorization
        },
        body: JSON.stringify({})
      })    
      .then(response => response.json())
      .then(json => {
//        alert(JSON.stringify(json, null, 2))
      })
  }
};

// Synchronous Action Creators for Content Index

export const wsStateChanged = (state) => ({
  type: ACTION.wsStateChanged,
  state: state
})

export const startingConversation = () => ({
  type: ACTION.startingConversation,
})

export const receiveNewConversation = (json) => {
  return {
    type: ACTION.receiveNewConversation,
    result: json,
    receivedAt: Date.now()
  }
}

export const errorStartingConversation = (error) => ({
  type: ACTION.errorStartingConversation,
  error
})

export const requestConversationList = () => ({
  type: ACTION.requestConversationList,
})

export const receiveConversationList = (json) => {

  let result = json
//  alert("receiveConversationList")
// alert(JSON.stringify(result, null, 2))

  return {
    type: ACTION.receiveConversationList,
    result: result,
    receivedAt: Date.now()
  };
}

export const errorRequestConversationList = (error) => ({
  type: ACTION.errorRequestConversationList,
  error
})

export const conversationDeleted = (conversationKey) => ({
  type: ACTION.conversationDeleted,
  conversationKey,
})

export const requestConversation = (conversationKey) => ({
  type: ACTION.requestConversation,
  conversationKey
})

export const receiveConversation = (conversationKey, json, partial=false) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveConversation,
    conversationKey,
    result: result,
    receivedAt: Date.now(),
    partial: partial
  }
}

export const errorRequestConversation = (conversationKey, error) => ({
  type: ACTION.errorRequestConversation,
  conversationKey,
  error
})

export const requestMessages = (conversationKey) => ({
  type: ACTION.requestMessages,
  conversationKey
})

export const receiveMessages = (conversationKey, json, partial=false) => {

  let result = json
  
//  alert(JSON.stringify(json, null, 2))

  return {
    type: ACTION.receiveMessages,
    conversationKey,
//    result: result,
    conversation: result,
    result: result['messages'],
    receivedAt: Date.now(),
    partial: partial
  }
}

export const errorRequestMessages = (conversationKey, error) => ({
  type: ACTION.errorRequestMessages,
  conversationKey,
  error
})

export const postMessage = (conversationKey, message) => ({
  type: ACTION.postMessage,
  conversationKey,
  message
})

export const receivePostMessageResult = (conversationKey, message, json) => {
  let result = json

  return {
    type: ACTION.receivePostMessageResult,
    conversationKey,
    message,
    result: result,
    receivedAt: Date.now()
  }
}

export const errorPostMessage = (conversationKey, message, error) => ({
  type: ACTION.errorPostMessage,
  conversationKey,
  message
})

export const receiveNewMessage = (conversationKey, message) => {
//  alert(JSON.stringify(message, null, 2))
  
  return {
    type: ACTION.receiveNewMessage,
    conversationKey,
    result: message,
    receivedAt: Date.now()
  }
}

export const readAllMessages = (conversationKey) => ({
  type: ACTION.readAllMessages,
  conversationKey
})



// Reducers for Conversation

const conversationReducer = createReducer(conversationInitial, {
  [ACTION.requestConversation]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveConversation]: (state, action) => {
      return {
        ...state,
        isFetching: action.partial ? state.isFetching : false,
        lastFetched: action.partial ? state.lastFetched : action.receivedAt,
        data: action.result
      }
    },
  [ACTION.errorRequestConversation]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetchingMessages: false,
        isError: true
      }
    },
  [ACTION.requestMessages]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveMessages]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))

      // Keep pending messages
      
      let pending = state.messages.reduce((all, item) => {
        if (item.isSending)
          return [...all, item]
        else
          return all
      }, [])
  
      let messages = action.result.map(data => {
        return {
          ...messageInitial,
          // Same as conversation update time.
          key: data['id'],
          lastUpdate: action.receivedAt,
          data: data
        }
      })

      return {
        ...state,
        isFetching: action.partial ? state.isFetching : false,
        lastFetched: action.partial ? state.receivedAt : action.receivedAt,
        data: action.conversation,
        messages: [
          ...messages,
          ...pending
        ]
      }
    },
  [ACTION.receiveNewMessage]: (state, action) => {
  
//      alert(JSON.stringify(action.result, null, 2))
      let newMessage = action.result
      let discard = false
      
      let messages = state.messages.map(message => {
        if (message.key == newMessage['id']) {
          discard = true
          return {
            ...message,
            data: newMessage
          }
        }
        
//        if (message.isSending)
//          discard = true

        return message
      })
      
      if (!discard) {
        messages.push({
          ...messageInitial,
          key: newMessage['id'],
          lastUpdate: action.receivedAt,
          data: newMessage
        })
      }

      return {
        ...state,
        messages: messages,
        data: action.result.conversation
      }
    },        
  [ACTION.readAllMessages]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          new_count: 0
        }
      }
    },           
  [ACTION.errorRequestMessages]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.postMessage]: (state, action) => {
      let messages = [
        ...state.messages,
        {
          ...action.message,
          isSending: true
        }
      ]
      return {
        ...state,
        messages
      }
    },
  [ACTION.receivePostMessageResult]: (state, action) => {
      
//      alert("receivePostMessageResult: " + JSON.stringify(action.result, null, 2))

      let messages
      
      let found = state.messages.find(item => item.key == action.result.id)
      if (found)
        messages = state.messages.reduce((p, n) => {
          if (n.key == action.message.key)
            return p
          else
            return [...p, n]
        }, [])
      else  
        messages = state.messages.map(item => {
            if (item.key == action.message.key)
              return {
                ...item,
                key: action.result.id, // Update to new key for new message
                isSending: false,
                lastUpdate: action.receivedAt,
                data: action.result
              }
              
            return item
          })
      
      let unread_count = 1
      if (state.data && state.data.unread_count !== undefined)
        unread_count = state.data.unread_count + 1
            
      if (state.key == 0 && !state.lastFetched) {
        return {
          ...state,
          messages,
          key: action.result.conversation.uid, // Update with new key          
          data: {
            ...action.result.conversation,
            unread_count: unread_count
          },
          lastFetched: action.receivedAt,
        }
      }
      else {      
        return {
          ...state,
          data: {
            ...state.data,
            unread_count: unread_count
          },
          messages
        }
      }
    },
  [ACTION.errorPostMessage]: (state, action) => {
      alert("Error Post Message: " + action.error)
      return {
        ...state,
        isSending: false,
      }
    },
})

const conversationListReducer = createReducer(conversationListInitial, {
  [ACTION.wsStateChanged]: (state, action) => {
      return {  
        ...state,
        wsState: action.state
      }
    },
  [ACTION.requestConversationList]: (state, action) => {
      return {  
        ...state,
        isFetching: true
      }
    },
  [ACTION.receiveConversationList]: (state, action) => {
      // Create record for each conversation in response
      let conversations = action.result.map(data => {
        // Keep last fetched messages
        let c = state.conversations.find(c => {
            return c.key == data.uid
          })
          
        let messages = c ? c.messages : []
        
        if (data.last_message) {
          if (!messages.find(c => { return c.key == data.last_message.id }))
            messages.push({
              ...messageInitial,
              key: data.last_message.id,
              data: data.last_message
              })
        }
        
        return {
          ...conversationInitial,
          key: data.uid,
          data,
          messages
        }
      })
      
      let message_count = 0
      let new_count = 0
      let unread_count = 0
      conversations.forEach((c, index) => {
        message_count += c.data.message_count === undefined ? 0 : c.data.message_count
        new_count += c.data.new_count === undefined ? 0 : c.data.new_count
        unread_count += c.data.unread_count === undefined ? 0 : c.data.unread_count
      })
      
//      alert(JSON.stringify(conversations, null, 2))
  
      return {
        ...state,
        isFetching: false,
        lastFetched: action.receivedAt,
        conversations: conversations,
        stats: { message_count: message_count, new_count: new_count, unread_count: unread_count }
      }        
    },
  [ACTION.errorRequestConversationList]: (state, action) => {
      alert("Request conversation list failed: " + action.error)
      return {
        ...state,
        isFetching: false,
        isError: true
      }
    },
  [ACTION.startingConversation]: (state, action) => {
      return {  
        ...state,
        isStarting: true,
        newConversationKey: null
      }
    },
  [ACTION.receiveNewConversation]: (state, action) => {
      let c = {
        ...conversationInitial,
        key: action.result.uid,
        data: action.result
      }
      
//      alert("receiveNewConversation")
//      alert(JSON.stringify(c, null, 2))
      
      return {
        ...state,
        isStarting: false,
        newConversationKey: c.key,
        conversations: [
          c,
          ...state.conversations
        ]
      }      
    },
  [ACTION.errorStartingConversation]: (state, action) => {
      alert(action.error)
      return {
        ...state,
        isStarting: false,
        isError: true
      }
    },
  [ACTION.conversationDeleted]: (state, action) => {
  
      let conversations = state.conversations.reduce((all, c) => {
        if (c.key == action.conversationKey)
          return all
        else
          return [...all, c]
      }, [])
        
      return {
        ...state,
        newConversationKey: null,
        conversations
      }      
    },
  '*': (state, action) => {
      if (action.type in ACTION) {
//        alert(JSON.stringify(action, null, 2))
        let conversation = state.conversations.find(c => c.key == action.conversationKey)
        
        if (conversation) {
          let newConversation = conversationReducer(conversation, action)
          
          let newRecords = state.conversations.map(c => {
            if (c.key == action.conversationKey)
              return newConversation
            return c
          })
          
          let message_count = 0
          let new_count = 0
          let unread_count = 0
          newRecords.forEach((c, index) => {
            message_count += c.data.message_count === undefined ? 0 : c.data.message_count
            new_count += c.data.new_count === undefined ? 0 : c.data.new_count
            unread_count += c.data.unread_count === undefined ? 0 : c.data.unread_count
          })          
          
          return {
            ...state,
            conversations: newRecords,
            stats: { message_count: message_count, new_count: new_count, unread_count: unread_count }            
          }
          
          return state
        }
      }
      
      return state
    },
})

export default conversationListReducer
