import React, { useState, useEffect, useContext } from 'react'
import { Route, Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../alpha/data/reducers/user'
import { getTrainerIndex, fetchTrainerIndex } from '../data/reducers/trainer'
import { getBusinessInfo } from '../alpha/data/reducers/business'

import { Helmet } from 'react-helmet'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import Chat from './Chat'
import Story from './Story'
import Markdown from '../components/ui/Markdown'

import TrainerHero from '../components/sections/TrainerHero'
import TrainerExperiences from '../components/sections/TrainerExperiences'

import moment from 'moment'
import ls from 'local-storage'

import ImagesSlider from '../components/ui/ImagesSlider'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Footer from './Footer'
import ContactUs from '../components/sections/ContactUs'
import CoursesCard from '../components/cards/CoursesCard'

const styles = theme => ({
  trainerProfile: {
    position: 'absolute',
    top: 0,
    width: '100%',
    background: '#ebebeb',
    overflow: 'hidden'
  },
  welcomeCard: {
    paddingBottom: theme.spacing(1),
  },
  logo: {
    paddingTop: theme.spacing(1) * 1,
    paddingBottom: theme.spacing(1) * 1,
  },
  logoTitle: {
    color: "#555",
    fontWeight: 600
  },
  logoSubTitle: {
    color: "#999"
  },
  businessName: {
    fontSize: '1.5rem',
    color: "#555",
    padding: theme.spacing(1)
  },
  avatar: {
    width: 80,
    height: 80,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ddd'
  },
  welcomeText: {
    color: "#999"
  },
  welcomeTitle: {
    fontWeight: 600,
  },
  mainButton: {
    weight: '100%'
  },
  divider: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
  },
  categoryFilter: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
    paddingLeft: theme.spacing(1) * 2,
    overflow: 'scroll',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) * 2,
  },
  categoryFilterRow: {
    width: 1000
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  storyList: {
  },
  totalCourses:{
    // color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding: 0,
      marginBottom: theme.spacing(6),
    }
  },
  
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 2
  },
  fabIconLeft: {
    marginRight: theme.spacing(1)
  },
  fabIconRight: {
    marginLeft: theme.spacing(1)
  },
  courseList: {
    // background: 'black',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]:{
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    }
  },
  courseTrainerList:{
    // margin: '0 16px',
    padding: '0 !important',
    // height: '100%',
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding: '0 !important',
      // padding: '12px 20px !important',
      marginBottom: theme.spacing(1)
    }
  },
  noPadding:{
    [theme.breakpoints.down('xs')]:{
      padding: '8px !important'
    }
  },
  containerCourseCard:{
    margin: '0 auto',
    [theme.breakpoints.down('xs')]:{
      margin: '-12px'
    }
  }
});

const WelcomeCard = ({ classes, business, user, showPopup }) => {
  let welcomeText
  if (user && user.isLoggedIn)
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi, { user.userInfo.first_name } { user.userInfo.last_name }
      </Typography>
    )
  else
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi
      </Typography>
    )

  let visitor = ls.get('visitor_id')
  if (visitor === undefined || !visitor)
    visitor = null

  return (
    <div className={classes.welcomeCard}>
      <Grid container direction='column' spacing={1}>
        <Grid item xs>
          <Box display='flex' display='none'>
          <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>
          <Box className={classes.businessName}>
          {business.name}
          </Box>
          </Box>
          <Typography className={classes.logoSubTitle} variant='body2'>
            <Markdown source={ business.bio } />
          </Typography>
        </Grid>
        <Box display='none'>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth component={ RouterLink } to="/catalog" >
                Belanja
              </Button>
            </Grid>
            
            <Grid item xs={6}>
            { visitor ?
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth
                onClick={()=>showPopup(
                  <Chat conversationKey={0}/>
                )}
              >
                Chat
              </Button>
              :
              <Button variant="outlined" size="medium" aria-label="" className={classes.mainButton} fullWidth
              >
                ...
              </Button>
            }
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </div>
)}

const TrainerProfile = ({ classes, history, business, user, trainerList, categoryKey, storyKey, productKey, categoryList, fetchTrainerList, fetchCategoryList, conversationList, profileKey }) => {

  const navigationContext = useContext(NavigationContext)
  const [hasBottomMenu, setHasBottomMenu] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    navigationContext.showTitle("")
    
    fetchTrainerList()
    // fetchCategoryList()

/*      
    if (!hasBottomMenu) {
      setHasBottomMenu(true)
      navigationContext.showBottomMenu( b
        <Fab variant="extended" size="medium" aria-label="Menu" className={classes.fab} onClick={ showMenu }>
          <MenuIcon className={classes.fabIconLeft} />
          Menu
        </Fab>
      )
    }
*/
  
    return () => {
      if (hasBottomMenu) {
        navigationContext.showBottomMenu(null)
        setHasBottomMenu(false)
      }
    }    
  },[])
  
  useEffect(() => {
    navigationContext.setLoading(trainerList.isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [trainerList.isFetching])
  
  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }

  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("#")) {
      // Scroll to section.
//      alert(linkTo)
//      window.location = linkTo
//      window.location.hash = linkTo //linkTo.substring(1)
      const id = linkTo.substring(1)
      const element = document.getElementById(id);
      if (element) 
        element.scrollIntoView({behavior: 'smooth'});
      return
    }
    
    if (linkTo == "chat") {
      // Open chat screen
      openChat(0)
      return
    }
    
    if (linkTo.startsWith("product") || linkTo.startsWith("story")) {
      if (categoryKey)
        link = "/trainer/" + categoryKey
      else
        link = "/trainer"
    
      link += "/" + linkTo + "?popup=1"
    }
    else if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo
      
    history.push(link)
  }
  
  const openStory = (item) => {
    if (categoryKey)  
      history.push("/trainer/" + categoryKey + "/story/" + item.key)
    else
      history.push("/trainer/story/" + item.key)
//    alert("openStory: " + item.uid)

//    setPopupContent(
//      <Story slug={item.uid} />
//    )
    
  }
  
  const [newPopupContent, setNewPopupContent] = useState(null)
  
  const showPopup = (content) => {
    setNewPopupContent(content)
  }
  const closePopup = () => {
    if (newPopupContent)
      setNewPopupContent(null)
    else {
      if (categoryKey)
        history.push("/trainer/" + categoryKey)
      else
        history.push("/trainer")
//        history.push("/")
    }
  }
  
  let popupContent = newPopupContent
  if (!popupContent) {
    if (storyKey)
      popupContent = (
        <Story slug={storyKey} openLink={openLink} />
      )
  }

  const capitalize = (str) =>
  {
  return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
  
  return (
    <>
    <Helmet>
        <title>{capitalize(profileKey.replace(/-/g,' '))} - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContent}
      closePopup={closePopup}>
      <div className={classes.trainerProfile}>
        {/* <Container className={classes.root}> */}
          {/* <WelcomeCard classes={classes} business={business} user={user} showPopup={showPopup} />    */}
          <TrainerHero history={history} classes={classes} trainerList={trainerList} openLink={openLink} /> 
          <TrainerExperiences history={history} classes={classes} trainerList={trainerList} openLink={openLink}/>
          <CourseList history={history} classes={classes} courseList={trainerList} openLink={openLink}/>
          <ContactUs history={history} classes={classes} onClick={()=>openChat(-1)}/>
          <Footer business={ business }/> 
        {/* </Container> */}
      </div>
    </PageBase>
    </>
  )
}

const CourseList = ({ classes, courseList, history }) => (
  <div className={classes.courseList}>
    <Container className={classes.noPadding}>
    <div>
        <Typography variant='h3' className={classes.totalCourses}>
          My Courses
        </Typography>
      </div>
      <Grid container spacing={3} layout={'row'} className={classes.containerCourseCard}>
      { courseList.lastFetched ?
        courseList.data.records.courses.map(item =>
      <Grid item xs={12} sm={6} md={6} xl={6} key={item.key}  className={classes.courseTrainerList}>
        <CoursesCard item={item} history={history}/>
      </Grid>
        )
        :
        <p>...</p>
      }
      </Grid>
    </Container>
  </div>
)

function profileKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.profileKey !== undefined)
    key = props.match.params.profileKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let profileKey = profileKeyFromProps(ownProps)
  let indexKey = 'detail'

  return { 
    user: state.user,
    business: getBusinessInfo(state, '0').data,
    trainerList: getTrainerIndex(state, 'trainer', profileKey),
    profileKey: profileKey,
    conversationList: state.alpha.conversationList,
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let profileKey = profileKeyFromProps(ownProps)
  let indexKey = 'detail'

  return {
    login: () => { dispatch(login()) },
    fetchTrainerList: (refresh=false) => {
      let params = null
      dispatch(fetchTrainerIndex('trainer', profileKey, params, profileKey, refresh))
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    TrainerProfile)
  )
)