import React, { Component, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Link from '@material-ui/core/Link'

import LinearProgress from '@material-ui/core/LinearProgress'
import ls from 'local-storage'

const drawerWidth = 240;

const styles = theme => {

  return {
  root: {
//    width: '100%',
//    display: 'flex',
//    justifyContent: 'center'
//    backgroundColor: '#f5f5f5'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fff",
    color: "#000",
  },
  appBarHidden: {
    display: 'none'
  },
  menuButton: {
//    marginLeft: 12,
//    marginRight: 12,
  },
  menuButtonHidden: {
    display: 'none',
  },
  linearProgress: {
    position: 'fixed',
    height: 2,
    overflow: 'hidden',
    width: '100%'
  },
  title: {
    flexGrow: 1,
    fontWeight: 800,
    paddingLeft: theme.spacing(1) * 2,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(1) * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(1) * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  bottomBarSpacer: {
    // height: theme.mixins.toolbar.height,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  bottomMenuSpacer: {   
    // height: 60,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      height: 200,
    },
  },
  content: {
//    padding: theme.spacing.unit * 2,
    flexGrow: 1,
//    height: '100vh',
    overflow: 'auto',
    width: '100%',
//    maxWidth: 480
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(1) * 2,
  },
  bottomMenu: {
    width: '100%',
    position: 'fixed',
    bottom: 60 + 8,
    textAlign: 'center',
    'z-index': 1050 // Below appbar (1100)
  },
  bottomNavBar: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  sideContent: {
    width: 400,
    [theme.breakpoints.down('xs')]:{
      width: '100%'
    }
//    paddingTop: theme.spacing(4),
  },
  drawerSideContent:{
    [theme.breakpoints.down('xs')]:{
      width: '100%'
    }
  },
  sideContentCloseButton: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
    'z-index': 1350
  },  
  popupContentCloseButton: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
    'z-index': 1350
  },
  poweredBy: {
    color: '#aaa',
    fontSize: 12,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}}

export const NavigationContext = React.createContext({})

class ScrollToTopView extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.search) {
        let sp = new URLSearchParams(this.props.location.search)
        if (sp.get('popup') == 1)
          return
      }

      if (prevProps.location.search) {
        let sp = new URLSearchParams(prevProps.location.search)
        if (sp.get('popup') == 1)
          return
      }
  
      window.scrollTo(0, 0)
    }
  }
  
  render() {
    return this.props.children
  }
}

const ScrollToTop = withRouter(ScrollToTopView)

const NavigationController = ({ theme, classes, header, footer, bottomNavBar, children }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  let mdUp = useMediaQuery(theme.breakpoints.up('md'))
  let lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  
  let maxWidth = 0
  if (lgUp)
    maxWidth = 760
  else if (mdUp)
    maxWidth = 720
  else if (smUp)
    maxWidth = 680  
  
  const [appBarHidden, setAppBarHidden] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [sideContent, setSideContent] = useState(null)
  const [popupContent, setPopupContent] = useState(null)
  const [bottomMenu, setBottomMenu] = useState(null)
    
  const navigationContext = {
    showTitle: (text) => { },
    showAppBar: (c) => setAppBarHidden(!c),
    showBottomMenu: setBottomMenu,
    setLoading: setLoading,
    showSideContent: setSideContent,
    showPopupContent: setPopupContent,
    popupContentWidth: maxWidth,
  }  
  
  let sideContentVisible = sideContent ? true : false
  let popupContentVisible = popupContent ? true : false
    
  const closePopup = () => {
    setPopupContent(null)
  }                                                                                                                                                                                                                                                                                                                     
  
  let popupContentStyle
  if (maxWidth > 0)
    popupContentStyle = {
      width: maxWidth
    }      
  else
    popupContentStyle = {
      width: '100%'
    }
    
  return (      
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <NavigationContext.Provider value={ navigationContext }>
      <ScrollToTop>
      <CssBaseline />
      <main className={classes.content}>      
        <div className={classNames(classes.appBarSpacer, appBarHidden && classes.appBarHidden)} />
        { children }        
        { footer }
        {/* <Box display='flex' width='100%' flexDirection='column' alignItems='center'>
          <Typography variant='body2' className={classes.poweredBy}>
            <Link href="https://www.goapp.co.id" color="inherit" target="_blank">
            Powered by GOAPP
            </Link>
          </Typography>
        </Box> */}
        <div className={classes.bottomMenuSpacer} />
        <div className={classes.bottomBarSpacer} />
      </main>
      
      <Box className={ classes.sideContentCloseButton } display={ sideContentVisible ? "block" : "none" }>
        <Button size='large' className={ classes.sideContentCloseButton} onClick={ () => navigationContext.showSideContent(null) }>
          <CancelIcon/>
        </Button>
      </Box>
      
      { smUp ?
      
      <>
        <Dialog
          anchor='bottom'
          scroll='body'
          open={ popupContentVisible }
          onClose={ closePopup }
          maxWidth={ false }
        >
        
          <div
            style={ popupContentStyle }
          >
            { popupContent }
          </div>
        </Dialog>
      </>        
      :
      <>
        <Box className={ classes.popupContentCloseButton } display={ popupContentVisible ? "block" : "none" }>
          <Button size='large' className={ classes.popupContentCloseButton} onClick={ closePopup }>
            <CancelIcon/>
          </Button>
        </Box>

        <Drawer
          anchor='bottom'
          open={ popupContentVisible }
          onClose={ closePopup }
        >
          <div
            className={ classes.contentDiv }
            style={ popupContentStyle }
          >
            { popupContent }
          </div>
        </Drawer>
      </>
      }            
      
      <Drawer anchor='right' open={sideContentVisible} onClose={() => navigationContext.showSideContent(null)}>
        <div
          className={classes.sideContent}
        >
          { sideContent }
        </div>
      </Drawer>
                
      <div className={classes.bottomMenu}>
        { bottomMenu }
      </div>
      { header }
      {/* <div className={classes.bottomNavBar}>
        { bottomNavBar }
      </div> */}
      <div className={classes.linearProgress} 
        style={ isLoading ? {} : { display: 'none' } } >
        <LinearProgress />
      </div>
      </ScrollToTop>
      </NavigationContext.Provider>
    </div>
  </div>
  )
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    NavigationController
  )
)