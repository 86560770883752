import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  light:{
      color:'black'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerStep:{
    // background: '#acacac',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    // alignItems:"center",
    // display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    background: 'white'
  },
  titleSection:{
    // marginTop: '70px',
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(6)
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      // display: 'flex',
      textAlign: 'left',
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  titleStep:{
      fontWeight: 'bold',
      padding: '0 16px'
  },
  descStep:{
      padding: '0 40px'
  },
  heading:{
    fontWeight: 'bold'
  }
});

const TrainerFaq = ({ classes, topicKey ,background_images, trainerList, history }) => {

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : 'https://media.pasartrainer.com/45104640091720/image/content/contentfield/9dd1aa2c-df97-4b3d-8547-7fd82283f590.jpeg'
    return (
        <div className={clsx(classes.sectionTrainerStep,classes.light)}>
            <Container>
                
                <div className={classes.titleSection}>
                  <Fade>
                    <Typography variant='h4' className={classes.categoryName}>
                        FAQs
                    </Typography>
                  </Fade>
                </div>
                <Fade>
                <div className={classes.descLanding}>
                        <Grid container>
                            <Grid items sm={6}>
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>What program course can I deliver?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    It’s entirely up to you. We believe that you are a professional in your field and are comfortable in delivering certain topics. There are a lot of program courses where you can utilize your expertise.
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <Typography className={classes.heading}>I don’t see a program course that I’m an expert in. Now what?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    No worries. You can work with us to develop specific program courses that you think will bring a lot of value for our clients, and of course for yourself.
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <Typography className={classes.heading}>How much does it cost to become a professional trainer?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    It’s 100% free to become a professional trainer at PasarTrainer. There are no payment required to list your service in our platform.
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Grid>
                            <Grid items sm={6}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>How much income can I earn?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    It’s entirely up to you. You have the ability to choose the price of your service and the amount of training that you want to deliver. You can choose to work as a professional trainer full-time or keep your 9-5 job while partnering with PasarTrainer to earn extra income.
                                  </Typography>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>How much time do I need to invest to become a professional trainer?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    Not long at all. After you have agreed to onboard as a professional trainer at PasarTrainer, you will need to spend a total of 8 hours (1 working day) to let us assist you in developing your profile video and enrol in a trainer upskilling session before you can be discovered by our clients.
                                  </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>When will I get paid?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Typography>
                                    After you have finished delivering a training class we will transfer the service fee to your account at 10th or 25th of every month, depending on the training delivery date.
                                  </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Container>
        </div>
    )
}

TrainerFaq.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainerFaq);