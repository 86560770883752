import React, { useState, useEffect, useContext, useRef } from 'react'
import { Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getContentRecord, fetchContentRecord } from '../alpha/data/reducers/content'
import { logEvent } from '../alpha/data/reducers/visitor'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import Markdown from '../components/ui/Markdown'

import ImagesSlider from '../components/ui/ImagesSlider'

import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import CircularProgress from '@material-ui/core/CircularProgress'
import Numeral from 'numeral'

const styles = theme => ({
  root: {
//    maxWidth: 480,
    width: '100%',
    minHeight: '100vh'
  },
  imageDiv: {
//    width: `calc(100% + ${theme.spacing(1)*4}px)`,
//    marginLeft: -theme.spacing(1)*2,
//    marginTop: -theme.spacing(1)*2,
//    marginBottom: theme.spacing(1) * 2
  },
  storyImage: {
    position: 'absolute',
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  },
  content: {
    padding: theme.spacing(1)*2,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)*3,
    }
  },  
  storyTitle: {
    fontWeight: 800,
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'    
  },
  storyText: {
    marginTop: theme.spacing(1) * 2,
    color: '#888'
  },
  bottomPadding: {
    minHeight: 100
  },
  fab: {
    marginRight: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabProgress: {
  }
})

const StoryDetailView = ({ classes, item, openLink }) => {
  let publishDate = moment(item.created_at).fromNow(true)
  
  return (
  <>
    { item.images !== undefined && item.images.length > 0 &&
      <div className={classes.imageDiv}>
        <ImagesSlider images={ item.images } />
      </div>
    }
    <div className={classes.content}>    
      { item.category && item.category !== undefined &&
        <Typography className={classes.storyCategory} variant='body2'>
        { item.category.name }
        </Typography>
      }    
      <Typography variant="h5" className={classes.storyTitle}>
        { item.title }
      </Typography>
      
      { publishDate &&
        <Typography variant='body2'>
        { publishDate }
        </Typography>
      }          
      
      <p/>
      <Typography variant="body1" className={classes.storyLead}>
        { item.leadParagraph }
      </Typography>

      <Markdown
        source={ item.body }
        openLink={ openLink } />
        
      <div className={classes.bottomPadding}/>
    </div>
  </>
  )
}

const StoryView = ({ classes, key, isFetching, lastFetched, data, fetchBlogRecord, openLink, logEvent }) => {

  const navigationContext = useContext(NavigationContext)
  const [initialized, setInitialized] = useState(false);
  
  const topElement = useRef()
  
  useEffect(() => {
    fetchBlogRecord()

    if (lastFetched)
      logEvent('view', { type: 'content', uid: data.uid })  
  }, [key, lastFetched])
  
  useEffect(() => {
    navigationContext.showTitle("")    

    // This solve a problem when our page stay on the middle after
    // PageBase drawer changed to different page.
    topElement.current.scrollIntoView({ behavior: "smooth" })
    
  }, [])
  
  if (isFetching)
    return (
      <div className={classes.root} ref={topElement}>
        <div className={classes.content}>          
        Fetching...
        </div>
      </div>
    )
  else if (lastFetched)
    return (
      <div className={classes.root} ref={topElement}>
        <StoryDetailView classes={classes} item={data} openLink={openLink}/>
      </div>
    )
  else {
    return (
      <div ref={topElement}>
        Empty
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let key
  if (ownProps.slug && ownProps.slug !== undefined)
    key = ownProps.slug
  else if (ownProps.storyKey && ownProps.storyKey !== undefined)
    key = ownProps.storyKey
  else
    key = ownProps.match.params.key
    
//  alert('mapStatToProps: ' + key)
  
  return {
    ...getContentRecord(state, 'blog', key),
    key: key,
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let key
  if (ownProps.slug && ownProps.slug !== undefined)
    key = ownProps.slug
  else if (ownProps.storyKey && ownProps.storyKey !== undefined)
    key = ownProps.storyKey
  else
    key = ownProps.match.params.key

  return {
    fetchBlogRecord: () => {
      dispatch(fetchContentRecord('blog', key))
    },
    logEvent: (eventName, data) => {
      dispatch(logEvent(eventName, data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StoryView))