import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const styles = theme => ({
  root: {
//    color: theme.palette.common.white,
//    color: theme.palette.common.black, 
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(3),
//    marginBottom: theme.spacing(14),
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
//    backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    opacity: 0.4,
    zIndex: -1,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  },
  arrowDown: {
    position: 'absolute',
    bottom: theme.spacing(4),
  },
});

function Layout(props) {
  const { backgroundClassName, backgroundStyle, dark=false, children, classes, theme } = props;
  
  let backdropStyle = {}
  backdropStyle['backgroundColor'] =  dark ? theme.palette.common.black : theme.palette.common.white
  
  let rootStyle = {}
  rootStyle['color'] = dark ? theme.palette.common.white : theme.palette.common.black
    
  return (
    <section className={classes.root} style={rootStyle}>
      <Container className={classes.container}>
        {children}
        <div className={classes.backdrop} style={backdropStyle} />
        <div className={clsx(classes.background, backgroundClassName)} style={backgroundStyle} />
      </Container>
    </section>
  );
}

Layout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true})(Layout);
