import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Fade from 'react-reveal/Fade';

import {
  Link
} from "react-router-dom";

const styles = theme => ({
  TrainerCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  itemTrainer:{
    cursor: 'pointer',
    width: "95%",
    height: "60vh",
    position: 'relative',
    //   marginRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]:{
          width: '100%',
          height: '40vh'
        },
        [theme.breakpoints.between('xs','sm')]:{
          height: '40vh'
        }
    },
    trainerCard:{
        background: '#808080',
        padding: theme.spacing(3),
        color: 'white',
        // position: 'absolute',
        width: '95%',
        height: '170px',
        // bottom: 0,
        // left: 0,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]:{
          textAlign: 'center',
          marginBottom: theme.spacing(1),
          width: '100%',
          height: '100%'
        }
    },
    trainerName:{
        fontWeight: 'bold !important' ,
        letterSpacing: '1px',
        margin: 0,
        [theme.breakpoints.down('xs')]:{
          fontSize: '1.5rem',
        }
    },
    trainerHeadline:{
        fontWeight: '100',
        letterSpacing: '1px',
        [theme.breakpoints.down('xs')]:{
          fontSize: '1.5rem',
        },
        [theme.breakpoints.between('sm','md')]:{
          marginRight: theme.spacing(3)
        }
    },
})


const TrainerSliderCard = ({ classes, item, onClick, history }) => {
    
  const openLink = (linkTo) => {
    history.push(linkTo)
  }

  let images = item.landing_image_url ? item.landing_image_url : "https://www.mandlpaints.com/wp-content/uploads/2018/09/Ash-Grey.jpg"

  return (
    <div>
      <Link to={"/trainer/"+item.key} style={{textDecoration:'none'}}>
      {/* <Fade> */}
        <div className={classes.itemTrainer} style={{background:'url('+images+')',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center center'}}>
            {/* <Fade> */}
            {/* </Fade> */}
        </div>
        <div className={classes.trainerCard}>
                <Typography className={classes.trainerName} variant="h4">
                    {item.first_name + ' '+item.last_name}
                </Typography>
                <Typography className={classes.trainerHeadline} variant="h4">
                   {item.head_line}
                </Typography>
        </div>
        {/* </Fade> */}
        </Link>
    </div>   
  )
}

TrainerSliderCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrainerSliderCard);
