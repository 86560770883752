import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import {
  Link
} from "react-router-dom";
import { Grid } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionDetailCourse:{
    background: 'white',
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
      
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold'
  },
  detailsCourse:{
    textAlign: 'left'
  },
  objectivesTitle:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  objectivesDetail:{
    marginBottom: theme.spacing(3)
  },
  sylabusTitle:{
    marginBottom: theme.spacing(3)
  },
  sylabusDetail:{
    // fontWeight: '100 !important',
    // textAlign: 'center',
    color: 'white',
    margin: '0 auto'
  },
  sylabusType:{
    background: '#91b6e0',
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]:{
        width: '60px',
        height: '60px'
    }
  },
  trainingDay:{
    fontWeight: '100',
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // textAlign: 'center'
  },
  syllabusList:{
    borderBottom: '1px solid #dadada',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]:{
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
  },
  courseFeature:{
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2)
  },
  sylabusSection:{
    backgroundColor:'#ebebeb', 
    padding: '0 24px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding: '0',
      margin: '0 -16px'
    }
  },
  sylabusContent:{
      background: 'white',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
  },
  titleModule:{
      fontWeight: 'bold'
  },
  hiddenIndex:{
    display: 'none'
  }
});

const SyllabusContant = ({ classes, topicKey ,background_images, courseList }) => {

        const groupBy = (array, key) => {
            // Return the end result
            return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
            }, []); // empty object is the initial value for result object
        };
        let moduleIndex = 1

        return (
            <div className={classes.sylabusContent}>
            <Container>
                <Grid container>
                    <Grid items md={3} sm={12} xs={12}></Grid>
                    <Grid items md={9} sm={12} xs={12}>
                            <Typography variant='h3' className={classes.sylabusTitle}>Syllabus</Typography>
                            {courseList.data.records && groupBy(courseList.data.records.contents,'day').map((item,key) =>
                                <>
                                    <div className={classes.sylabusSection}>
                                        <div className={classes.syllabusList}>
                                            <Typography variant='h4' className={classes.trainingDay}>
                                                Day {key}
                                            </Typography>
                                        </div>
                                        {item.map(items =>
                                        <Grid container className={classes.syllabusList}>
                                            <Grid items md={2} xs={3}>
                                              {items.content_type !== 'module' &&
                                                <div className={classes.sylabusType}>
                                                  <Typography className={classes.sylabusDetail} variant='h5'>{items.content_type.substr(0,1).toUpperCase()}</Typography>
                                                </div>
                                              }
                                              {items.content_type === 'module' &&
                                                <>
                                                <div className={classes.sylabusType}>
                                                  <Typography className={classes.sylabusDetail} variant='h5'>{moduleIndex < 10 ? "0"+moduleIndex : moduleIndex}</Typography>
                                                </div>
                                                <div className={classes.hiddenIndex}>
                                                {moduleIndex++}
                                                </div>
                                                </>
                                              }
                                            </Grid>
                                            <Grid items md={10} xs={9}>
                                            <Fade bottom>
                                            <Typography variant='h5' className={classes.titleModule}>{items.title}</Typography>
                                            <Markdown source={ items.summary } />
                                            </Fade>
                                            </Grid>
                                        </Grid>
                                        )}
                                    </div>
                                </>
                            )}
                    </Grid>
                </Grid>
            </Container>
            </div>
            )
    }

    SyllabusContant.propTypes = {
        classes: PropTypes.object.isRequired,
      };
      
      export default withStyles(styles, { withTheme: true })(SyllabusContant);