import ls from 'local-storage'

import React, { useState, useEffect, useContext } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

// Data
import { getVisitor, init, register, logEvent } from './data/reducers/visitor'
import { login, getUser, getCompanies, authenticateWithToken, fetchContactInfo, setActiveCompany } from './data/reducers/user'
import { getBusinessInfo, fetchBusinessInfo } from './data/reducers/business'
import { fetchConversationList, WSSTATE } from './data/reducers/conversation'
import { fetchProductIndex } from './data/reducers/catalog'

import { wsConnect } from './middleware/conversation'

const styles = theme => ({
  root: {
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
})

const AppBase = (props) => {
  const { theme, classes, business, history, user, companies, visitor, wsState, params } = props
  
  const { fetchBusinessInfo, fetchConversationList, fetchProductsCount, login, authenticateWithToken, setActiveCompany, fetchContactInfo, init, register } = props
    
  const [conversationTimerID, setConversationTimerID] = useState()
  const [hasFetchProductsCount, setHasFetchProductsCount] = useState(false)
  
  const getWSHost = () => {
    let host = process.env.REACT_APP_GOAPP_API_URL + "/ws/conversation/"      
    host = host.replace("http://", "ws://")
    host = host.replace("https://", "wss://")
    host = host + "?visitor=" + visitor.data.visitor_id
    if (user.isLoggedIn)
        host += "&token=" + user.authToken
    host += "&api_key=" + process.env.REACT_APP_GOAPP_API_KEY
    return host
  }
  
  const [isOnline, setIsOnline] = useState(false)
  
  useEffect(() => {
    fetchBusinessInfo()
    
    window.addEventListener('online', (e) => {
      setIsOnline(true)
    })
    
    window.addEventListener('offline', (e) => {
      setIsOnline(false)
    })
    
    return () => {
      clearInterval(conversationTimerID)
    }
  }, [])
  
  useEffect(() => {
//    alert("WebSocket state: " + wsState)
    if (wsState == WSSTATE.Disconnected) {
      let timeout = 250
      if (!isOnline)
        timeout = 3000
    
      setTimeout(() => {
//        alert(getWSHost())
        props.wsConnect(getWSHost())
      }, timeout)
    }
    else if (wsState == WSSTATE.Connected) {
      // Refresh conversation list on re-connect.
      fetchConversationList(true)      
    }
  }, [wsState])

  useEffect(() => {  
    if (!business.lastFetched)
      // Wait
      return
      
    if (params.get('reset') == 1) {
      ls.remove('_goid')
      ls.remove('_userInfo')
      // Reload app
      window.open(process.env.REACT_APP_PUBLIC_URL, "_self")
      return
    }
    
    // Check token from callback URL
    let authToken = params.get('auth_token')
    
    if (authToken && !user.isLoggedIn) {
      let authSuccess = params.get('auth_success')
      authenticateWithToken(authToken)
      
      params.delete('auth_token')
      params.delete('auth_success')
      
      // Update url
      // Note: Should use history.replace so that path with token not pushed
      // to history, but it doesn't work.
      history.push({ pathname: history.location.pathname, search: params.toString()})
    }
    else if (user.isAuthenticating) {
      // Just wait
    }
    else if (user.userInfo && !user.isLoggedIn) {
      if (!user.isAuthenticating)
        // Try to login automatically
        login()
    }
    else if (!visitor.isInitialized) {
      init()
    }
    else if (!visitor.isRegistered) {
      let registerData = {}
      if (user.isLoggedIn)
        registerData = {
          external_app: 'alpha.apps.directory',
          external_model: 'user',
          external_id: user.userInfo.uid,
          first_name: user.userInfo.first_name,
          last_name: user.userInfo.last_name,
          email: user.userInfo.email,
          mobile_no: user.userInfo.mobile_no
        }      
      else 
        registerData =  {
        }
        
      register(registerData)
    }
    else if (user.isUpdatingContact) {
      // Just wait
    }
    else if (!user.lastContactUpdate) {
      // Fetch contact info.
      fetchContactInfo()
    }
    else {
      /*
      if (!conversationTimerID || conversationTimerID == undefined) {
        // First chat
        fetchConversationList(true)
        
        let conversationTimerID = setInterval(() => {
  //          alert("timer timeout")
          fetchConversationList(true)
          }, 15000)
        setConversationTimerID(conversationTimerID)
      }
      */
      
      // Connect to conversation ws
      if (wsState == WSSTATE.Idle) {
        props.wsConnect(getWSHost())
      }
      
      if (!hasFetchProductsCount) {
        fetchProductsCount()
        setHasFetchProductsCount(true)
      }      
    
      if (user.isLoggedIn && !user.companyInfo && companies.length > 0)
        setActiveCompany(companies[0].uid)
    }
    
  
//    alert(this.props.location)
//    if (this.props.location !== prevProps.location) {
//      visitor_log_event('View Page', { 'url': this.props.location })
//    }
  })
  
  // Show page
  if (business.lastFetched && !user.isAuthenticating && visitor.isRegistered && user.lastContactUpdate )
    return (  
      <div className={classes.root}>
        { Array.isArray(props.children) ?
          <>
          { props.children.map(c => (c)) }
          </>
          :
          <>
          { props.children }
          </>
        }
      </div>
    )
  else {
    // Show loading messages
    
    let message = "..."
    if (business.isFetching)
      message = "Initializing..."
    else if (user.isAuthenticating)
      message = "Authenticating..."
    else if (visitor.isInitializing || visitor.isRegistering)
      message = "Almost done..."
    else if (user.isUpdatingContact)
      message = "One second..."
    
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
        { message }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)
  
  return {
    user: getUser(state),
    companies: getCompanies(state),
    visitor: state.alpha.visitor,
    business: getBusinessInfo(state, '0'),
    params: urlParams,
    wsState: state.alpha.conversationList.wsState
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
//  let callbackUrl = process.env.REACT_APP_PUBLIC_URL + ownProps.location.path
  let callbackUrl = window.location.href
//  alert(callbackUrl)
  
  return {
    fetchBusinessInfo: () => {
      dispatch(fetchBusinessInfo('0'))
    },
    login: () => {
      dispatch(login(callbackUrl, true))
    },
    authenticateWithToken: (authToken) => {
      dispatch(authenticateWithToken(authToken))
    },
    fetchContactInfo: () => {
      dispatch(fetchContactInfo())
    },
    setActiveCompany: (companyID) => {
      dispatch(setActiveCompany(companyID))
    },
    init: () => {
      dispatch(init())
    },
    register: (registerData) => {
      dispatch(register(registerData))
    },
    fetchProductsCount: () => {
      dispatch(fetchProductIndex('default', null))
    },
    fetchConversationList: (refresh) => {
      dispatch(fetchConversationList(refresh))
    },
    wsConnect: (host) => {
      dispatch(wsConnect(host))
    }
  }
}

const App = withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AppBase)
  )
)

export const withAppBase = (Component) => (props) => {
  return (
    <App>
      <Component
        { ...props }
      />
    </App>
  )
}

export default App