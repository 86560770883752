import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  light:{
      color:'black'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerStep:{
    // background: '#acacac',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    // alignItems:"center",
    // display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    background: 'white'
  },
  titleSection:{
    // marginTop: '70px',
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: '100',
    marginBottom: theme.spacing(3),
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  titleStep:{
      fontWeight: 'bold',
      padding: '0 16px'
  },
  descStep:{
      padding: '0 40px'
  }
});

const TrainerStep = ({ classes, topicKey ,background_images, trainerList, history, onClick }) => {

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : 'https://media.pasartrainer.com/45104640091720/image/content/contentfield/9dd1aa2c-df97-4b3d-8547-7fd82283f590.jpeg'
    return (
        <div className={clsx(classes.sectionTrainerStep,classes.light)}>
            <Container>
                <div className={classes.titleSection}>
                  <Fade>
                    <Typography variant='h4' className={classes.categoryName}>
                        Contact us to start living & working flexibly
                    </Typography>
                    <Button variant='contained' color='primary' onClick={onClick}>
                        Get Started
                    </Button>
                  </Fade>
                </div>
            </Container>
        </div>
    )
}

TrainerStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainerStep);