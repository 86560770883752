import React from 'react'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const styles = theme =>({
    root: {
      flexGrow: 1,
    }
  });

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    if(props.type !== 'course'){
      return (
        <div
          className={className}
          style={{ ...style, display: "block", left: '10px', zIndex: 1, width: '50px'}}
          onClick={onClick}
        >
          <ArrowBackIosIcon style={{fill: '#ffffff8a',fontSize: '5rem'}}/>
        </div>
      );
    }else{
      return (
        <div
          className={className}
          style={{ ...style, display: "block", left: '10px', zIndex: 1, width: '80px', paddingRight:'60px'}}
          onClick={onClick}
        >
          <ArrowBackIosIcon style={{fill: '#ffffff8a',fontSize: '4rem'}}/>
        </div>
      );
    }
  }

const mapStateToProps = (state, ownProps) => {
  return {
    
  }
} 


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PrevArrow))
