import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Avatar from '@material-ui/core/Avatar'
import Tooltip  from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Container } from '@material-ui/core';

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  avatarImage:{
    margin: '0 auto',
    width: '150px',
    height: '150px'
  },
  courseImg:{
    height: '250px',
    width: '250px',
    [theme.breakpoints.down('xs')]:{
      width: '90%',
      height: '325px',
      margin: '0 auto',
      position: 'relative',
      zIndex: -1
    },
    [theme.breakpoints.between('sm','md')]:{
      height: '200px',
      width: '200px',
      margin: '0 auto',
    },
  },
  iconPlay:{
    fontSize: '3rem'
  },
  coursesName:{
    fontWeight: 'bold !important',
    color: 'white',
    [theme.breakpoints.down('xs')]:{
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      display:'none'
    },
    [theme.breakpoints.between('xs','sm')]:{
      display:'none'
    }
  },
  coursesNameMobile:{
    fontWeight: 'bold !important',
    color: 'white',
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      // marginLeft: theme.spacing(4),
      // marginRight: theme.spacing(4),
      display: 'block',
    },
    [theme.breakpoints.between('xs','sm')]:{
      display:'block',
      textAlign: 'center'
    }
  },
  totalDays:{
    // color: '#9C9E9B',
    color: 'white',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      textAlign:'center',
      // color: 'white'
    }
  },
  levelCourse:{
    color: '#ff6f38',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      textAlign:'center',
      marginRight: 0
    },
    [theme.breakpoints.between('xs','sm')]:{
      marginRight: 0
    }
  },
  detailCourse:{
    width: '60%',
    position: 'absolute',
    bottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]:{
      position: 'relative',
      margin: '0 auto',
      width: '90%',
      marginTop:theme.spacing(-12)
    },
    [theme.breakpoints.between('xs','sm')]:{
      position: 'relative',
      margin: '0 auto',
    }
  },
  cardDetailCourses:{
      position: 'relative',
      marginTop: '0px',
      [theme.breakpoints.down('xs')]:{
        marginTop: 0
      },
      [theme.breakpoints.between('sm','md')]:{
        margin: 0
      }
  },
  detailCourses:{
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      justifyContent: 'center'
    },
    [theme.breakpoints.between('xs','sm')]:{
      justifyContent: 'center'
    }
  },
  introductionButton:{
      display:'none',
      background: 'rgb(63, 81, 181)',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: '5px',
      borderRadius: '5em',
      color: 'white',
      height: '60px',
      alignItems: 'center',
      width: '150px',
      cursor: 'pointer',
      [theme.breakpoints.between('sm','md')]:{
        // textAlign:'center',
        height: '60px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: '35%'
      },
      [theme.breakpoints.between('xs','sm')]:{
        // textAlign:'center',
        margin: '0 auto',
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]:{
        margin: '0 auto',
        marginTop: theme.spacing(2),
        width: '50%'
      }
  },
  dialogVideo:{
    height: '100vh',
    [theme.breakpoints.down('xs')]:{
        height: '30vh'
    }
},
iframeYoutube:{
  position:'absolute',
  top:0,
  left:0,
  bottom:0,
  right:0,
  width:'100%',
  height:'100%',
  border:'none',
  margin:0,
  padding:0,
  overflow:'hidden',
  zIndex:999999
},
infoLevel:{
  // color: '#9C9E9B',
  color: 'white',
  [theme.breakpoints.between('xs','sm')]:{
    display: 'none',
  },
  [theme.breakpoints.down('xs')]:{
    display: 'none'
  }
},
categoryName:{
  color: 'white',
  // fontWeight: 'bold'
  [theme.breakpoints.down('xs')]:{
    textAlign: 'center',
    // marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.between('xs','sm')]:{
    textAlign: 'center'
  }
},
iconPlayProfile:{
  fontSize: '100px',
  color: 'white',
  cursor: 'pointer',
  transition: 'all .2s ease-in-out',
  '&:hover': {
      transform: 'scale(1.2)',
  }
},
playProfile:{
  textAlign: 'center',
  position: 'relative',
  top: '30%',
  // left: '50%',
  zIndex: 100,
  [theme.breakpoints.down('xs')]:{
    top: '20%'
  }
},
backdropCourse:{
  [theme.breakpoints.down('xs')]:{
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
    margin: '-16px',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(6)
    // position: 'absolute',
    // top: '56px',
    // left: 0,
    // right: 0,
    // bottom: 0,
  }
}
})

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CourseCard = ({ classes, item, topicKey, history }) => {
  const [open, setOpen] = React.useState(false);
  const [urlVideo, setUrlVideo] = React.useState('https://www.youtube.com/embed/GsGnU7_Pp0c?autoplay=1&rel=0')
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent className={classes.dialogVideo}>
                    <iframe className={classes.iframeYoutube} src={urlVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </DialogContent>
            </Dialog>
      <div className={classes.backdropCourse}>
      <Grid container spacing={3} className={classes.cardDetailCourses}>
        <Grid item xs={12} sm={12} md={3}>
          {item.images.length > 0 &&
          <div style={{background:`url(`+item.images[0].image_url+`)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} className={classes.courseImg}>
            {/* <div className={classes.playProfile}>
              <PlayCircleOutlineIcon className={classes.iconPlayProfile} onClick={()=> handleClickOpen()}/>
            </div> */}
          </div>
          }
          {item.images.length === 0 &&
            <div style={{background:`url(https://www.mandlpaints.com/wp-content/uploads/2018/09/Ash-Grey.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} className={classes.courseImg}>
              {/* <div className={classes.playProfile}>
                <PlayCircleOutlineIcon className={classes.iconPlayProfile} onClick={()=> handleClickOpen()}/>
              </div> */}
              {/* <Typography variant='h4' className={classes.coursesNameMobile} align={'left'}>
                {item.name}
              </Typography> */}
            </div>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
            <div className={classes.detailCourse}>
              <Typography variant='h3' className={classes.coursesName} align={'left'}>
              {item.name}
              </Typography>
              <Typography variant='h4' className={classes.coursesNameMobile} align={'left'}>
              {item.name}
              </Typography>
              <Typography variant="h6" className={classes.categoryName}>{item.category.name}</Typography>
              <Typography variant="body2" className={classes.categoryName}>{item.course_code}</Typography>
              <div className={classes.detailCourses}>
              {/* <Grid container spacing={3}> */}
              {/* <Grid item xs={6} sm={3}> */}
                  <Typography variant="body1" className={classes.totalDays}>{item.training_days} Days In-class Training</Typography>
              {/* </Grid>
              <Grid item xs={6} sm={3}> */}
                  <Typography variant="body1" className={classes.levelCourse}>{item.level.name || 'Basic'} Level</Typography>
              {/* </Grid>
              <Grid item xs={2} sm={2}> */}
              {item.level.name === 'Basic' && item.level_name === 'Basic' &&
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Basic Level</Typography>
                      {"Suitable for professionals with 0-4 years of experience"}
                    </React.Fragment>
                  }
                >
                  <InfoOutlinedIcon className={classes.infoLevel}/>
                </HtmlTooltip>
              }
              {item.level.name !== 'Basic' && item.level_name !== 'Basic' &&
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Intermediate Level</Typography>
                      {"Suitable for professionals with 4+ years of experience or 2+ years in related role"}
                    </React.Fragment>
                  }
                >
                  <InfoOutlinedIcon className={classes.infoLevel}/>
                </HtmlTooltip>
              }
              </div>
              {/* </Grid> */}
              {/* </Grid> */}
              <Grid container className={classes.introductionButton} onClick={()=> handleClickOpen()}>
                  <Grid item md={4} xs={4}>
                  <PlayArrowIcon className={classes.iconPlay}/>
                  </Grid>
                  <Grid item md={8} xs={8}>
                  <Typography variant="body2" className={classes.durationVideo}>1:07</Typography>
                  <Typography variant="body2" className={classes.labelProfile}>Introduction</Typography>
                  </Grid>
              </Grid>
          </div>
        </Grid>
      </Grid>
      </div>
    </div> 
  )
}

CourseCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourseCard);
