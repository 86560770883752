import { renderToString } from 'react-dom/server'
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { connect } from 'react-redux'

import { getNewConversation, getLatestConversation, getConversation, deleteConversationIfEmpty, startNewConversation, fetchConversation, fetchMessages, sendMessage, markReadAll, WSSTATE } from '../alpha/data/reducers/conversation'
import { getBusinessInfo, fetchBusinessInfo } from '../alpha/data/reducers/business'
import { getUser, fetchContactInfo, updateContactInfo } from '../alpha/data/reducers/user'
import { wsSend } from '../alpha/middleware/conversation'

import ReactMarkdown from 'react-markdown'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import { withStyles } from '@material-ui/core/styles'

import { getChatOnlineStatus, isChatOnline } from './Inbox.js'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import moment from 'moment'
import classNames from 'classnames'

import ContactForm from '../components/forms/ContactForm'

// UI
import ChatMessage from '../components/ui/ChatMessage'
import ReactLoading from 'react-loading'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import Button from '@material-ui/core/Button'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Popover, {PopoverAnimationVertical} from '@material-ui/core/Popover'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },  
  chatHeader: {
    zIndex: 1300,
    backgroundColor: theme.palette.background.paper,
  },
  chatHeaderContent: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,    
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: '#ddd'
  },  
  headerTitle: {
    fontWeight: 800
  },
  headerSubTitle: {  
  },
  chatInfo: {
    color: '#999',
  },
  messageTop: {
//    minHeight: '70vh',
//    [theme.breakpoints.up('sm')]: {
//      minHeight: '80vh'
//    }
    minHeight: '90vh',
    [theme.breakpoints.up('sm')]: {
//      minHeight: '30vh'
      minHeight: '70vh'
    }
  },
  messageList: {
    width: '100%',
//    maxWidth: 500,
    padding: theme.spacing.unit * 2,
    backgroundColor: '#eee',
  },
  inline: {
    display: 'inline',
  },
  componentBar: {
    margin: 0
  },
  composeBar: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  composeBarPaper: {
    display: 'flex',
    padding: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: 'auto',
//    maxWidth: 500,
    alignItems: 'center',
  },
  filesPreviewPaper: {
    display: 'flex',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  composeInput: {
//    padding: `${theme.spacing.unit * 2}`,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: 0, //theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 0,
    paddingBottom: theme.spacing.unit * 0,
    width: '100%'
  },
  composeIcon: {
    padding: theme.spacing.unit
  },
  composeSendIcon: {
    padding: theme.spacing.unit,
  },
  displayNone: {
    display: 'none'
  },
  attachFileZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: theme.spacing.unit
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewImageDiv: {
    width: '100%',
    borderColor: '#eeeeee',
    borderWidth: 1
  },
  muted: {
    color: '#999'
  }
})

const FilePreview = ({ classes, file }) => {
  const [ image, setImage ] = useState(null)
  
  useEffect(() => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result)
    };
    reader.readAsDataURL(file);
  }, file)
  
  return (
    <div className={classes.previewImageDiv}>
      <img src={image} className={classes.previewImage}/>
    </div>
  )
}

/*
const AttachFileZone = ({ classes, onCancel, sendFiles }) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})
  
  const [ files, setFiles ] = useState([])
  
  useEffect(() => {
    if (acceptedFiles)
      setFiles([...files, ...acceptedFiles])
  }, [acceptedFiles])
  
  return (
    <div>
      <div {...getRootProps()} className={ classes.attachFileZone }>
        <input {...getInputProps()} />
        { isDragActive ?
          <p>Drop the files here ...</p>
          :
          <Grid container spacing={1}>
            { files.map(file => (
              <Grid item xs={3}>              
                <FilePreview classes={classes} file={file} />
              </Grid>
              ))
            }
            <Grid item xs={3}>              
              <p>Add file</p>
            </Grid>
          </Grid>          
        }
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={()=>sendFiles(files)}>
            Kirim
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" color="secondary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={onCancel}>
            Batal
          </Button>
        </Grid>
      </Grid>
      
    </div>
  )
}
*/

const ComposeBar = ({ classes, sendMessage, defaultValue }) => {

  const [message, setMessage] = useState({body: ""})
  
  const [files, setFiles] = useState([])
  
  const handleChange = name => event => {
    setMessage({ ...message, [name]: event.target.value });
  } 
  
  const handleKeyDown = event => {
    if (event.keyCode == 13)
      send()
  }
  
  const send = () => {
    sendMessage(message.body, files)
    clear()
  }
  
  const clear = () => {
    setMessage({ ...message, body: "" })
    setFiles([])
  }
  
  useEffect(() => {
    if (defaultValue)
      setMessage({
        ...message,
        body: defaultValue
      })
  }, [defaultValue])
  
  const addFiles = ({ target }) => {
    let newFiles = []
    for (let i = 0; i < target.files.length; i++) {
      let file = target.files[i]
      newFiles.push(file)
    }
    
    setFiles([...files, ...newFiles])
  }
  
  return (
    <Box className={classes.composeBar}>
      { files.length ?
        <Paper className={classes.filesPreviewPaper} elevation={0}>
          <Grid container spacing={1}>
            { files.map(file => (
              <Grid item xs={3}>              
                <FilePreview classes={classes} file={file} />
              </Grid>
              ))
            }
          </Grid>
        </Paper>
        :
        <></>
      }
      <Paper className={classes.composeBarPaper} elevation={0}>      
        <Input placeholder="Tulis pesan anda" className={classes.composeInput} margin="normal" variant="filled" disableUnderline={true} value={message.body} onChange={handleChange("body")} onKeyDown={handleKeyDown} />
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          onChange={addFiles}
          type="file"
        />
        <label htmlFor="raised-button-file">
          <IconButton size="medium" 
            className={ classes.composeSendIcon }
            >
            <AddAPhotoIcon />
          </IconButton>        
        </label>         
        <IconButton size="medium" className={ classNames(classes.composeSendIcon, { [classes.displayNone]: !message.body && !(files && files.length) })} onClick={() => send() }>
          <SendIcon />
        </IconButton>
      </Paper>
    </Box>  
  )
}

const OptionInputBar = ({ theme, classes, sendMessage, helperText, options }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  return (
    <Box className={classes.composeBar}>
      <Paper className={classes.composeBarPaper} elevation={0}>
        
        { smUp ?
        
        <Box display='flex' flexDirection='row' alignItems='flex-end' width='100%' flexWrap='wrap' ml={1} mr={1}>
          <Box mb={1} width='100%'>
            <Typography variant='body2' className={classes.muted}>
              { helperText }
            </Typography>
          </Box>
        
          { options.map(option => (
            <Box mt={1} mr={1}>
              <Button variant="outlined" color="primary"
                style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize}}
                onClick={ () => sendMessage(JSON.stringify({ value: option.value, text: option.text })) }>{ option.text }</Button>
            </Box>
            ))
          }
        </Box>
        
        :
        
        <Box display='flex' flexDirection='column' alignItems='center' width='100%' ml={1} mr={1}>
          <Box mb={1}>
            <Typography variant='body2' className={classes.muted}>
              { helperText }
            </Typography>
          </Box>
        
          { options.map(option => (
            <Box mt={1} flexGrow={1} width='100%'>
              <Button variant="outlined" color="primary" fullWidth
                style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize }}
                onClick={ () => sendMessage(JSON.stringify({ value: option.value, text: option.text })) }>{ option.text }</Button>
            </Box>
            ))
          }
        </Box>                
        }
      </Paper>
    </Box>  
  )
}

const ContactFormAction = ({ classes, user, updateContactInfo }) => {

  const contactForm = (
    <ContactForm initial={user.contactInfo}
      inline={ true }
      saveForm={ (contact) => {
        updateContactInfo(contact)
      }}
     />
  )
  
  return (
    <Box className={classes.componentBar}>
      { contactForm }
    </Box>  
  )
}

const ChatHeader = ({ classes, business, conversation, openChat, wsState }) => {

  const navigationContext = useContext(NavigationContext)
  
  // When displayed inside popup, we need to get popup width, so that
  // the header div can have fixed position and width.
  // On mobile, we can just use 100%, but not popup is not full width.

  let headerWidth = '100%'
  if ('popupContentWidth' in navigationContext && navigationContext['popupContentWidth'] > 0)
    headerWidth = navigationContext['popupContentWidth']
  
  let headerStyle = {    
    position: 'fixed',
    top: 0,
    width: headerWidth
  }    

  let title = business.name
    title = conversation.data.parties.map(p => {
      if (p.agent) {
        let name = p.agent.first_name
        if (p.agent.last_name && p.agent.last_name !== undefined)
          name += " " + p.agent.last_name
        if (p.agent.is_service_user)
          name += "" //" (Bot)"
        else
          name += " (Agent)"
//          name += " (" + business.name + ")"
        return name
      }
      else
        return null
    }).reduce((p, n) => {
      if (p && n)
        return p + ", " + n
      else if (n)
        return n
      else
        return p
    })
    
    if (!title && conversation.data.queue)
      title = conversation.data.queue.name    
    
    if (!title || title.length <= 0)
      title = "You"
      
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const showMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchorEl(null);
  };


  return (
  
  <div className={classes.chatHeader} style={headerStyle}>  
    <Box container className={classes.chatHeaderContent} display='flex' direction='row'>    
      <Box mr={1}>
        <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>      
      </Box>
      <Box flexGrow={1}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography className={classes.headerTitle} variant="body1" align="left" >
            { business.name }
          </Typography>

          { false && 
          <>
          <Typography className={classes.headerTitle} variant="body1" align="left" onClick={showMenu}>
            { business.name }
          </Typography>
          <ExpandMoreIcon />
          </>
          }
        </Box>
        <Typography className={classes.headerSubTitle} variant="body2" align="left">
          { title }
        </Typography>  
      </Box>
    </Box>
    <Divider />
    <Menu
        id={menuAnchorEl ? 'chat-menu-popover' : undefined}
        open={ Boolean(menuAnchorEl) }
        anchorEl={menuAnchorEl}
        onClose={hideMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}        
      >
      <MenuItem onClick={ () => openChat(0) }>
        <AddIcon /><Box mr={1} />Start New Chat
      </MenuItem>
    </Menu>
    { wsState != WSSTATE.Connected && 
    <Box style={{ position: 'fixed', top: '0', width: headerWidth, textAlign: 'center' }}>
      <Typography style={{ fontSize: '.9em', backgroundColor: '#f00', color: '#fff' }}>
        { wsState }
      </Typography>
    </Box>
    }
  </div>
  )
}

const MessageItem = ({ classes, item, onClick }) => {

  let sender = "You"
  let messages = []
  let date = ""
  let side = "right"
  
  if (!item.isSending && item.data.sender.agent) {
    sender = item.data.sender.agent.first_name
    side = "left"
  }
  
  if (item.data.body && item.data.body != undefined) {
    let body = item.data.body
    
    if (body.startsWith("{") && body.endsWith("}")) {
      let data = JSON.parse(body)
      body = data['text']
//      alert(JSON.stringify(data, null, 2))
    }

    const renderers = {
      paragraph: (props) => <Typography gutterBottom className={ classes.paragraph } variant='body1'> { props.children } </Typography>, 
    }

    messages.push(<ReactMarkdown source={body} renderers={renderers}/>)
//    alert(renderToString(<ReactMarkdown source={body} renderers={renderers}/>))
    
//    let body = item.data.body.replace(/(\[.*?\]\()(.+?)(\))/g, "<a href='$2'>$1</a>")
//    messages.push(<>{ body }</>)
  }
      
  if (item.data.attachments && item.data.attachments !== undefined && item.data.attachments.length > 0) {
  
    messages.push(
      ...item.data.attachments.map(att => (
          <img src={att.image_url} className={classes.previewImage}/>
      )))
  }

  if (item.isSending)
    date = "Sending..."
  else if (item.data.sent_at) {
    date = moment(item.data.sent_at).fromNow(true)
    
    if (item.data.sender && item.data.sender.contact) {
      if (item.data.read_at !== undefined && item.data.read_at)
        date += " . read"
      else
        date += " . not read"
    }
  }

  return (
    <>
      <ChatMessage
        side={side}
        messages={messages}
        status={ date }
      />
    </>
  )
}


const MessageList = ({ classes, business, messages, parties, initialMessage, onClick }) => {

  const bottomDiv = useRef()
//  const listDiv = useRef()
  
  const scrollToBottom = () => {
//    alert("Scroll to bottom")
//		listDiv.current.scrollTop = listDiv.current.scrollHeight;
    bottomDiv.current.scrollIntoView({ behavior: "smooth" })
	}
	
	// Scroll to bottom when message is added or typing parties changed
	let messagesLength = messages.length + (parties.filter(item => item['typing_at'] ? true : false).length << 8 )
  
  useEffect(() => {
    scrollToBottom()
  }, 
  [messagesLength]
  )
  
  return (
    <div className={classes.messageList}>
      <div className={classes.messageTop} />
        <Typography variant='body1' className={classes.chatInfo} gutterBottom>
        
        { initialMessage }
        
        </Typography>

      { messages.length > 0 &&
        messages.map(item =>
          <MessageItem classes={classes} item={item}/>
        )
      }
      
      { parties.map(item =>
        item['typing_at'] ?
          <ChatMessage
            side='left'
            messages={[
              (<Box style={{ height: 32, position: 'relative', top: -16 }}>
                  <ReactLoading type='bubbles' color='#555' width={64} height={64} />
              </Box>
              )
            ]}
          />
        :
          <></>
        )
      }
      
      <div ref={bottomDiv} />
    </div>
  )
}

const ChatMessagesView = ({ theme, classes, business, user, conversationKey, conversation, fetchMessages, sendMessage, markReadAll, deleteConversationIfEmpty, fetchContactInfo, updateContactInfo, openChat, wsState }) => {

  const navigationContext = useContext(NavigationContext)    
  
  useEffect(() => {
//    const intervalID = setInterval(() => {
//      fetchConversation()
//      }, 2000)
      
    return () => {
//      navigationContext.showTitleBar(null)
//      clearInterval(intervalID)
      
      // Delete conversation if it's empty.
      deleteConversationIfEmpty()
    }
  }, [])
  
  useEffect(() => {
    let timeoutID = null
    if (conversation.data.new_count > 0) {
      timeoutID = setTimeout(() => {
        markReadAll()
      }, 50)
    
    }
    
    return () => {
      if (timeoutID)
        clearTimeout(timeoutID)    
    }
  }, [conversation.data.new_count])
  
  const [firstFetch, setFirstFetch] = useState(true)
  
  /*
  useEffect(() => {
    let timeoutID = null
    if (!conversation.isFetching) {
      if (firstFetch) {
        setFirstFetch(false)
//        alert("fetchMessages")
        fetchMessages()
        return
      }      
    }
    
    return () => {
      if (timeoutID)
        clearTimeout(timeoutID)
    }
  
  }, [conversation.isFetching])
  */
  
  useEffect(() => {
    // Refetch on first open or when connected
    if (wsState == WSSTATE.Connected)
      fetchMessages()
  }, [wsState])
  
  const checkOnlineAndSendMessage = (message, files=null) => {
    if (wsState != WSSTATE.Connected) {
      alert("Can not send message because you are not online. Please check your internet connection and try again!")
      return
    }
    
    sendMessage(message, files)
  }
  
  // Check last message

  let component = null
  let defaultValue = null
  
  if (conversation.data.accept_reply) {  
    if (conversation.messages.length) {
      let lastMessage = conversation.messages[conversation.messages.length-1]
      let body = lastMessage.data.body
      if (body.startsWith("{") && body.endsWith("}")) {
        let data = JSON.parse(body)
        let inputType = data['input_type']
        if ('default' in data)
            defaultValue = data['default']
        if (inputType == 'option') {
          component = (
            <OptionInputBar theme={theme} classes={classes} options={data['options']} helperText={data['helper_text']} sendMessage={checkOnlineAndSendMessage} />
            )
        }
      }
    }
    
    if (!component)
      component = (
        <ComposeBar theme={theme} classes={classes} sendMessage={checkOnlineAndSendMessage} defaultValue={defaultValue} />
      )
  }

  return (
    <div className={classes.root}>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
      <ChatHeader classes={classes} business={business} conversation={conversation} openChat={openChat} wsState={wsState} />    
      <MessageList classes={classes} business={business} messages={conversation.messages} parties={conversation.data.parties}/>
      <Divider />
      { component }
    </div>
    )
}

const NewChatView = ({ classes, business, openChat, isStarting, conversationKey, conversation, startNewConversation, wsStart, wsState }) => {
  const navigationContext = useContext(NavigationContext)
  
  const [hasStarted, setHasStarted] = React.useState(false);
  
  
  // Initialize new conversation.    
  useEffect(() => {
    if (conversationKey == 0) {
      if (conversation && hasStarted) {
        // Send start command to websocket
        wsStart()
        
        // Reopen chat with new conversation key
        openChat(conversation.key)
      }
      else {
        // Try to start a new convrsation
        startNewConversation()
        setHasStarted(true)
      }
    }  
    else if (conversationKey == -1) {
      if (conversation)
        openChat(conversation.key)
      else
        openChat(0)
    }
  }, [conversation, conversationKey])
  
  
  return (
    <div className={classes.root} style={{ minHeight: 100 }}>
      <Box display='flex' justifyContent='center' alignItem='center'>
      { isStarting ?
      <Typography variant='body1'>
      Starting...
      </Typography>
      :
      <Typography variant='body1'>
      Please wait...
      </Typography>
      }
      </Box>
    </div>
  )
}

  
const OpenChatView = ({ classes, business, user, conversationKey, conversation, fetchMessages, sendMessage, markReadAll, deleteConversationIfEmpty, fetchContactInfo, updateContactInfo }) => {

  const navigationContext = useContext(NavigationContext)    
  
  useEffect(() => {
    fetchMessages()
      
//    navigationContext.showTitleBar(
//      <ChatHeader classes={classes} business={business} conversation={conversation} />
//    )
  
    const timeoutID = setTimeout(() => {
        markReadAll()
      }, 
      1000)

    const intervalID = setInterval(() => {
      fetchMessages()
      }, 5000)
      
    return () => {
//      navigationContext.showTitleBar(null)
      clearTimeout(timeoutID)
      clearInterval(intervalID)
      
      // Delete conversation if it's empty.
      deleteConversationIfEmpty()
    }
  }, [])
  
  let component
  let initialMessage
  if (isChatOnline() || conversation.messages.length || (
     user.lastContactUpdate && user.contactInfo.first_name && user.contactInfo.first_name !== undefined)) {
    component = (
      <ComposeBar classes={classes} sendMessage={sendMessage} />
    )      

    if (user.lastContactUpdate && user.contactInfo.first_name && user.contactInfo.first_name !== undefined)
      initialMessage = (
        <>{user.contactInfo.first_name} {user.contactInfo.last_name}, Terima kasih telah menghubungi {business.name}. Ada yang bisa kami bantu?    </>
      )
    else
      initialMessage = (
        <>Terima kasih telah menghubungi {business.name}. Ada yang bisa kami bantu?</>
      )
  }
  else {
    // Ask for contact info
    component = (
      <ContactFormAction classes={classes} user={user} fetchContactInfo={fetchContactInfo} updateContactInfo={updateContactInfo} />
    )
    
    initialMessage = (
      <>Terima kasih telah menghubungi {business.name}. Beritahu kami cara menghubungi Anda.</>
    )
  }

  return (
    <div className={classes.root}>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
      <ChatHeader classes={classes} business={business} conversation={conversation} />    
      <MessageList classes={classes} business={business} initialMessage={initialMessage} messages={conversation.messages} />
      <Divider />
      { component }
    </div>
    )
}

const ChatView = (props) => {
  const { conversationKey } = props
  
  if (conversationKey <= 0)
    return (<NewChatView {...props} />)
  else
    return (<ChatMessagesView {...props} />)
}

function conversationKeyFromProps(props) {
  let key=0
  if (props.conversationKey)
    key = props.conversationKey
  else if (props.match)
    key = props.match.params.key
  return key
}

const mapStateToProps = (state, ownProps) => {
  let key = conversationKeyFromProps(ownProps)
  
  let c = null
  let isStarting = false
  
  if (key == 0) {
    c = getNewConversation(state)
    isStarting = state.alpha.conversationList.isStarting
  }
  else if (key == -1) {
    c = getLatestConversation(state)
    if (!c) {
      c = getNewConversation(state)      
      isStarting = state.alpha.conversationList.isStarting
    }
  }
  else
    c = getConversation(state, key)
  
  return {    
    conversationKey: key,
    conversation: c,
    iStarting: isStarting,
    business: getBusinessInfo(state, '0').data,
    user: getUser(state),
    wsState: state.alpha.conversationList.wsState
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let key = conversationKeyFromProps(ownProps)

  return {
    startNewConversation: () => {
      dispatch(startNewConversation())
    },
    deleteConversationIfEmpty: () => {
      dispatch(deleteConversationIfEmpty(key))
    },
    wsStart: () => {
      dispatch(wsSend('start', null))
    },
    fetchMessages: () => {
//      dispatch(fetchMessages(key, true, true))
      dispatch(fetchMessages(key, true, false))
    },
    sendMessage: (message, files=null) => {
      dispatch(sendMessage(key, message, files))
    },
    markReadAll: () => {
      dispatch(markReadAll(key))
    },
    fetchContactInfo: () => { dispatch(fetchContactInfo()) },
    updateContactInfo: (data) => { dispatch(updateContactInfo(data)) },
  }
}

export default withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatView))
