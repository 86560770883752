import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import Button from '../components/Button'
import Button from '@material-ui/core/Button'
//import Typography from '../components/Typography'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Markdown from '../ui/Markdown'
import LeftSideContent from '../ui/LeftSideContent'
import RightSideContent from '../ui/RightSideContent'
import TopContent from '../ui/TopContent'
import BottomContent from '../ui/BottomContent'

const styles = theme => ({
  root: {
    display: 'flex',
//    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

const ContentInRow = ({ classes, hashKey, theme, body, items, variant, images, background_images, link_to, link_title, openLink, maxHeight }) => {

  let rootStyle = {}
  let dark = false
  let contentSide = {}

  if(variant === 'TopDark'){
    body = body || '##### **Are you an expert but not into teaching? Here’s a way to capitalize your expertise.**\n We understand that not everybody has a passion in teaching but has exceptional expertise in certain fields. And we know that some people still prefers permanent work-type. No worries, wehave a way for you to capitalize your expertise so you can have side earnings while focusing in your career journey'
    contentSide = <TopContent classes={classes} body={body} items={items} images={images} link_to={link_to} link_title={link_title} openLink={openLink} maxHeight={maxHeight} variant='dark' background_images={background_images}/>
  }else if(variant === 'TopLight'){
    body = body || '##### **Are you an expert but not into teaching? Here’s a way to capitalize your expertise.**\n We understand that not everybody has a passion in teaching but has exceptional expertise in certain fields. And we know that some people still prefers permanent work-type. No worries, wehave a way for you to capitalize your expertise so you can have side earnings while focusing in your career journey'
    contentSide = <TopContent classes={classes} body={body} items={items} images={images} link_to={link_to} link_title={link_title} openLink={openLink} maxHeight={maxHeight} variant='light' background_images={background_images}/>
  }else if(variant === 'BottomLight'){
    body = body || '##### **Are you an expert but not into teaching? Here’s a way to capitalize your expertise.**\n We understand that not everybody has a passion in teaching but has exceptional expertise in certain fields. And we know that some people still prefers permanent work-type. No worries, wehave a way for you to capitalize your expertise so you can have side earnings while focusing in your career journey'
    contentSide = <BottomContent classes={classes} body={body} items={items} images={images} link_to={link_to} link_title={link_title} openLink={openLink} maxHeight={maxHeight} variant='light' background_images={background_images}/>
  }else{
    body = body || '##### **Are you an expert but not into teaching? Here’s a way to capitalize your expertise.**\n We understand that not everybody has a passion in teaching but has exceptional expertise in certain fields. And we know that some people still prefers permanent work-type. No worries, wehave a way for you to capitalize your expertise so you can have side earnings while focusing in your career journey'
    contentSide = <BottomContent classes={classes} body={body} items={items} images={images} link_to={link_to} link_title={link_title} openLink={openLink} maxHeight={maxHeight} variant='dark' background_images={background_images}/>
  }
  
  if (variant == 'Dark') {
    dark = true
    rootStyle['backgroundColor'] = '#555'
    rootStyle['color'] = theme.palette.common.white
  }

  return (
    <section id={hashKey} name={hashKey} className={classes.root} style={rootStyle}>
      {contentSide}
      {/* <Container className={classes.container}>
        <Box align="center">
          <Markdown source={ body } />
        </Box>
        <div>
          <Grid container spacing={5}>
          
          { items !== undefined && items.map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              <div className={classes.item}>
                <Box align="center">                
                  <Markdown source={ item.body } dark={dark}/>
                </Box>
              </div>
            </Grid>
            )) 
          }
          </Grid>
        </div>
        { link_to && link_to !== undefined &&
          <Box align="center">
            <p/>
            <Button variant="contained" color="primary" onClick={ () => openLink(link_to) }>
              { link_title }
            </Button>
          </Box>
        }        
      </Container> */}
    </section>
  );
}

ContentInRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentInRow);
