import React, { useState, useEffect, useContext } from 'react'
import { Route, Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../alpha/data/reducers/user'
import { getTrainerIndex, fetchTrainerIndex } from '../data/reducers/trainer'
import { getBusinessInfo } from '../alpha/data/reducers/business'

import { Helmet } from 'react-helmet'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import Chat from './Chat'
import Story from './Story'
import Markdown from '../components/ui/Markdown'

import TrainerCard from '../components/cards/TrainerCard'

import moment from 'moment'
import ls from 'local-storage'

import ImagesSlider from '../components/ui/ImagesSlider'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TrainerListHero from '../components/sections/TrainerListHero'
import Footer from './Footer'
import TrainerLandingHero from '../components/sections/TrainerLandingHero'
import TrainerStep from '../components/sections/TrainerStep'
import ContentInColumn from '../components/sections/ContentInColumn'
import TrainerFaq from '../components/sections/TrainerFaq'
import TrainerContact from '../components/sections/TrainerContact'
import Fade from 'react-reveal/Fade';
import clsx from 'clsx';
import Slider from "react-slick";
import NextArrow from '../components/ui/NextArrow'
import PrevArrow from '../components/ui/PrevArrow'
import TrainerSliderCard from '../components/cards/TrainerSliderCard';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 2,
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1) * 4,
    }
  },
  sectionTrainerLists:{
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    background: '#ebebeb'
  },
  trainerList:{
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]:{
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  welcomeCard: {
    paddingBottom: theme.spacing(1),
  },
  logo: {
    paddingTop: theme.spacing(1) * 1,
    paddingBottom: theme.spacing(1) * 1,
  },
  logoTitle: {
    color: "#555",
    fontWeight: 600
  },
  logoSubTitle: {
    color: "#999"
  },
  businessName: {
    fontSize: '1.5rem',
    color: "#555",
    padding: theme.spacing(1)
  },
  avatar: {
    width: 80,
    height: 80,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ddd'
  },
  welcomeText: {
    color: "#999"
  },
  welcomeTitle: {
    fontWeight: 600,
  },
  mainButton: {
    weight: '100%'
  },
  divider: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
  },
  categoryFilter: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
    paddingLeft: theme.spacing(1) * 2,
    overflow: 'scroll',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) * 2,
  },
  categoryFilterRow: {
    width: 1000
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  storyList: {
  },
  
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 2
  },
  fabIconLeft: {
    marginRight: theme.spacing(1)
  },
  fabIconRight: {
    marginLeft: theme.spacing(1)
  },
  // titleTrainerList:{
  //   paddingBottom: theme.spacing(3),
  // },
  newDivide: {
    width: '100%',
    height: theme.spacing(1),
//    backgroundColor: theme.palette.common.white,
    backgroundColor: '#ebebeb'
  },
  sectionTrainerList:{
    background: 'white',
  minHeight:'0vh',
  minWidth:'100%',
  display:"flex",
  overflow: 'hidden'
},
carouselItem:{
  maxWidth: 1200,
  border: 0,
  height: '80vh',
  [theme.breakpoints.down('md')]:{
      height: '80vh',
      margin: 0,
      maxWidth: '100%'
  }
},
titleTrainerList:{
    fontSize: '36px',
    fontWeight: '400',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]:{
        fontSize: '24px',
      //   margin: 0,
      //   marginTop: theme.spacing(3),
      //   marginBottom: theme.spacing(3),
      //   marginLeft: theme.spacing(3)
    }
},
containerTrainerList:{
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    }
},
slickTrainer:{
    width: 1000,
},
sliderHide:{
  [theme.breakpoints.down('xs')]:{
    // marginLeft: theme.spacing(1) * -2,
    // marginRight: theme.spacing(1) * -2
    display:'none'
  }
},
desktopHidden:{
  display: 'none',
  [theme.breakpoints.down('xs')]:{
    // marginLeft: theme.spacing(1) * -2,
    // marginRight: theme.spacing(1) * -2
    display:'block',
    marginLeft: '-16px',
    marginRight: '-16px'
  }
},
moreTrainers:{
  color: '#E75A0B',
  textAlign: 'center'
},
noDecoration:{
  textDecoration: 'none'
},
totalCourses:{
  // textAlign: 'center'
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
},
teachingTitle:{
  textAlign: 'center',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.between('sm','md')]:{
    paddingBottom: 0
  }
},
iconLanding:{
  fontSize:'64px',
  marginBottom:theme.spacing(1),
},
});

const TrainerList = ({ classes, trainerList, history, openLink }) => {

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1.05,
    initialIndex: 0,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false
        }
      },
    ]
    // variableWidth: true,
  };

  let title = '';
  return (
    <Fade>
      {trainerList &&
      
      <div className={clsx(classes.sectionTrainerList,classes.light)}>
          <Container className={classes.containerTrainerList}>  
            <Typography variant='h2' className={classes.totalCourses}>
              <b style={{fontWeight:700}}>Featured</b> Professional Trainers
            </Typography>
            {trainerList.data.records && trainerList.data.records.length > 1 &&
              <Slider {...settings} className={classes.sliderHide}>
                  { trainerList.data.records ?
                      trainerList.data.records.map(item =>
                      <TrainerSliderCard item={item} history={history}/>
                  ) 
                  :
                  <p>...</p>
                }
              </Slider>
              }
              {trainerList.data.records && trainerList.data.records.length === 1 &&
                <Grid container>
                  <Grid items xs={12} className={classes.itemTrainer}>
                  { trainerList.data.records ?
                      trainerList.data.records.map(item =>
                      <TrainerSliderCard item={item} history={history}/>
                  ) 
                  :
                  <p>...</p>
                }
                  </Grid>
                </Grid>
              }
              <div className={classes.desktopHidden}>
                <Grid container>
                  <Grid items xs={12} className={classes.itemTrainer}>
                  { trainerList.data.records ?
                      trainerList.data.records.map(item =>
                      <TrainerSliderCard item={item} history={history}/>
                  ) 
                  :
                  <p>...</p>
                }
                  </Grid>
                </Grid>
              </div>
          </Container>
      </div>
      }
    </Fade>
  )
}

const TeachingTrainer = ({ classes, trainerList, history, openLink }) => {

  return (
    <Fade>
      <div className={clsx(classes.sectionTrainerList,classes.light)}>
          <Container className={classes.containerTrainerList}>  
            <Typography variant='h2' className={classes.teachingTitle}>
              <b style={{fontWeight:700}}>More Than</b> Just Teaching
            </Typography>
            <ContentInColumn history={history} classes={classes} variant="LeftSideLight"/>
            <ContentInColumn history={history} classes={classes} variant="RightSideLight"/>
          </Container>
      </div>
    </Fade>
  )
}

const HomeView = ({ classes, history, business, user, trainerList, categoryKey, storyKey, productKey, categoryList, fetchTrainerList, fetchCategoryList, conversationList }) => {

  const navigationContext = useContext(NavigationContext)
  const [hasBottomMenu, setHasBottomMenu] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  
  useEffect(() => {
    navigationContext.showTitle("")
    
    fetchTrainerList()
  
    return () => {
      if (hasBottomMenu) {
        navigationContext.showBottomMenu(null)
        setHasBottomMenu(false)
      }
    }    
  },[])
  
  useEffect(() => {
    navigationContext.setLoading(trainerList.isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [trainerList.isFetching])
  
  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }

  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("#")) {
      // Scroll to section.
//      alert(linkTo)
//      window.location = linkTo
//      window.location.hash = linkTo //linkTo.substring(1)
      const id = linkTo.substring(1)
      const element = document.getElementById(id);
      if (element) 
        element.scrollIntoView({behavior: 'smooth'});
      return
    }
    
    if (linkTo == "chat") {
      // Open chat screen
      openChat(0)
      return
    }
    
    if (linkTo.startsWith("product") || linkTo.startsWith("story")) {
      if (categoryKey)
        link = "/trainer/" + categoryKey
      else
        link = "/trainer"
    
      link += "/" + linkTo + "?popup=1"
    }
    else if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo
      
    history.push(link)
  }
  
  const openStory = (item) => {
    if (categoryKey)  
      history.push("/trainer/" + categoryKey + "/story/" + item.key)
    else
      history.push("/trainer/story/" + item.key)
//    alert("openStory: " + item.uid)

//    setPopupContent(
//      <Story slug={item.uid} />
//    )
    
  }
  
  const [newPopupContent, setNewPopupContent] = useState(null)
  
  const showPopup = (content) => {
    setNewPopupContent(content)
  }
  const closePopup = () => {
    if (newPopupContent)
      setNewPopupContent(null)
    else {
      if (categoryKey)
        history.push("/trainer/" + categoryKey)
      else
        history.push("/trainer")
//        history.push("/")
    }
  }
  
  let popupContent = newPopupContent
  if (!popupContent) {
    if (storyKey)
      popupContent = (
        <Story slug={storyKey} openLink={openLink} />
      )
  }

  let icon1 = <RecordVoiceOverIcon className={classes.iconLanding}/>
  let icon2 = <SupervisorAccountIcon className={classes.iconLanding}/>
  let icon3 = <AccountBalanceWalletOutlinedIcon className={classes.iconLanding}/>

  let step1 = '### **Get Paid** On the Go'
  let items1 = 
  [{
    icons: icon1,
    title: 'Contribute Expertise',
    description: 'Contact us to have a discussion on how you can utilize your expertise into training materials for certain topics.'
  },
  {
    icons: icon2,
    title: 'Other Trainers Use Your Topic',
    description: 'The training materials that you have developed will be discoverable and available for use by other trainers.'
  },
  {
    icons: icon3,
    title: 'Get Paid',
    description: 'You will get paid every time your training materials are used. You can literally earn while you sleep.'
  }
]
  
  return (
    <>
    <Helmet>
        <title>Trainer - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContent}
      closePopup={closePopup}>
      <div className={classes.sectionTrainerLists}>
        {/* <Container className={classes.root}> */}
          {/* <WelcomeCard classes={classes} business={business} user={user} showPopup={showPopup} /> */}
          {/* <TrainerListHero history={history} classes={classes} trainerList={trainerList} openLink={openLink}/> */}
          <TrainerLandingHero history={history} classes={classes} variant='dark' onClick={()=>openChat(-1)}/>
          <div className={classes.newDivide} />
          <TrainerStep history={history} classes={classes} />
          <div className={classes.newDivide} />
          <TeachingTrainer history={history} classes={classes}/>
          <div className={classes.newDivide} />
          <TrainerStep history={history} classes={classes} body={step1} items={items1}/>
          <div className={classes.newDivide} />
          <TrainerFaq history={history} classes={classes}/>
          <div className={classes.newDivide} />
          <TrainerContact history={history} classes={classes} onClick={()=>openChat(-1)}/>
          <div className={classes.newDivide} />
          <TrainerList history={history} classes={classes} trainerList={trainerList} openLink={openLink} />    
          <Footer business={business}/>
        {/* </Container> */}
      </div>
    </PageBase>
    </>
  )
}

function categoryKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.categoryKey !== undefined)
    key = props.match.params.categoryKey
  return key
}

function storyKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.storyKey !== undefined)
    key = props.match.params.storyKey
  return key
}

function productKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.productKey !== undefined)
    key = props.match.params.productKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let categoryKey = categoryKeyFromProps(ownProps)
  let indexKey = categoryKey ? categoryKey : 'default'

  return { 
    user: state.user,
    business: getBusinessInfo(state, '0').data,
    trainerList: getTrainerIndex(state, 'trainer', indexKey),
    categoryKey: categoryKey,
    categoryList: getTrainerIndex(state, 'trainer-category', 'default'),
    storyKey: storyKeyFromProps(ownProps),
    conversationList: state.alpha.conversationList,
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let categoryKey = categoryKeyFromProps(ownProps)
  let indexKey = categoryKey ? categoryKey : 'default'

  return {
    login: () => { dispatch(login()) },
    fetchTrainerList: (refresh=false) => {
      let params = categoryKey ? { category_f: categoryKey } : null
      dispatch(fetchTrainerIndex('trainer', indexKey, params, null, refresh))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    HomeView)
  )
)