import React from 'react'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = theme =>({
    root: {
      flexGrow: 1,
    },
    backgroundNext:{
      background: 'linear-gradient(to left, rgba(35,31,32,1) 0%,rgba(35,31,32,0) 100%) !important'
    }
  });

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    if(props.type !== 'course'){
      return (
        <div
          className={className}
          style={{ ...style, display: "block", right: 0, width: '70px' }}
          onClick={onClick}
        >
          <ArrowForwardIosIcon style={{fill: '#ffffff8a',fontSize: '5rem'}}/>
        </div>
      );
    }else{
      return (
        <div
          className={className}
          style={{ ...style, display: "block", right: 0, width: '60px',paddingRight:'10px'}}
          onClick={onClick}
        >
          <ArrowForwardIosIcon style={{fill: '#ffffff8a',fontSize: '4rem'}}/>
        </div>
      );
    }
  }

const mapStateToProps = (state, ownProps) => {
  return {
    
  }
} 


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NextArrow))
