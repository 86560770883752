import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})


const StoryCard = ({ classes, item, onClick }) => {

  let publishDate = moment(item.created_at).fromNow(true)
  let likes = "0 Views"
  if (item.popularity_score !== undefined)
    likes = item.popularity_score + " Views" + " - 0 Comments"
    console.log(item)
  return (

  <Card className={classes.storyCard} elevation={16}>  
    <CardActionArea onClick={()=>onClick(item)}>    
      { item.images && item.images !== undefined && item.images.length == 999 &&
        <CardMedia component="img" className={classes.storyImage} image={ item.images[0]['image_url'] }/>
      }
      { item.images && item.images !== undefined && item.images.length > 0 &&
        <ImagesSlider images={item.images} />
      }
      <CardContent className={classes.storyContent}>
        <Grid container>
        { item.category && item.category !== undefined &&
          <Grid item xs={8}>
            <Typography className={classes.storyCategory} variant='body2'>
            { item.category.name }
            </Typography>
          </Grid>
        }
        { publishDate &&
          <Grid item xs={4}>
            <Typography className={classes.storyDate} variant='body2' align='right'>
            { publishDate }
            </Typography>
          </Grid>
        }        
        </Grid>
          
        { item.title && item.title !== undefined &&
          <Typography className={classes.storyTitle} variant='h5'>
          { item.title }
          </Typography>
        }
        { item.leadParagraph !== undefined && item.leadParagraph &&
          <Typography className={classes.storyLead} variant='h5'>
          { item.leadParagraph }
          </Typography>
        }
        { likes &&
          <Typography className={classes.storyLikes} variant='body2' style={{display: 'inline-block'}}>
          { likes }
          </Typography>
        }          
      </CardContent>
    </CardActionArea>
  </Card>    
  )
}

StoryCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoryCard);
