import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionLandingHero:{
    // background: '#acacac',
    backgroundSize:'cover',
    // height:'100vh',
    minWidth:'100%',
    position: 'relative'
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
    marginTop: '70px',
    marginBottom: theme.spacing(12),
    color: 'white'
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: 'white'
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      background: '#0000007a',
      position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30vh',
    left: 0
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  detailAbout:{
    fontWeight: 100,
    width: '60%',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]:{
      width: '100%',
      marginBottom: theme.spacing(3)
    }
  },
  chevronDown:{
    //   marginTop: theme.spacing(8),
      borderRadius: '50%',
      border: '3px solid white',
      fontSize: '80px',
      color: 'white',
      cursor: 'pointer'
  },
  backdropClass:{
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100vh',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
  }
});

const AboutHero = ({ classes, topicKey ,background_images, trainerList, history, variant }) => {

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backdropStyle = {}

    const ref = React.createRef();

    const handleClick = () =>
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
      });

    if(variant !== 'dark'){
      backdropStyle['background'] = '#7473737a'
    }else{
        backdropStyle['background'] = '#0000007a'
    }
    let backgroundImage = background_images !== undefined ?  background_images : 'https://images.unsplash.com/photo-1475608980029-de8304bf6f6e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
    return (
        <div className={clsx(classes.sectionLandingHero,classes.dark)} style={{backgroundImage:`url(`+backgroundImage+`)`}}>
          <div className={classes.backdropClass} style={backdropStyle}>
            <Container>
                <div className={classes.titleSection}>
                  <Fade>
                    <Typography variant='h2' className={classes.categoryName}>
                        Why We’re Here
                    </Typography>
                    <Typography variant='h5' className={classes.detailAbout}>
                        Fundamentally, we aim to change two things. Organization
                        learning management and work arrangement. We believe that
                        both can be done more effectively and efficiently so all the people
                        involved can benefit from the change.
                    </Typography>
                  </Fade>
                </div>
                <Fade bottom>
                <KeyboardArrowDownIcon ref={ref} className={classes.chevronDown} onClick={() => handleClick()}/>
                </Fade>
            </Container>
            </div>
        </div>
    )
}

AboutHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AboutHero);