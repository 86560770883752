import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import { getContentIndex, fetchContentIndex } from '../../alpha/data/reducers/content'
import ArticleCard from '../cards/ArticleCard'
import Slider from "react-slick";
import NextArrow from '../ui/NextArrow'
import PrevArrow from '../ui/PrevArrow'
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Link
} from "react-router-dom";
import ArticleHero from './ArticleHero';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
    dark: {
        color: 'white'
    },
    light:{
        color: 'black'
    },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  cardArticle:{
    height: '350px',
    position: 'relative'
  },
  sectionArticleList:{
    background: 'white',
    width: '100%',
    minHeight: '0vh',
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]:{
      paddingBottom: theme.spacing(4),
    }
  },
  sectionArticleTitle:{
    [theme.breakpoints.down('md')]:{
        fontSize: '24px'
    }
  },
  loadMore:{
    color: '#E75A0B',
    textAlign: 'center',
    textDecoration: 'none'
  },
  hiddenMobile:{
    display:'block',
    [theme.breakpoints.down('xs')]:{
      display:'none'
    }
  },
  hiddenDesktop:{
    display:'none',
    [theme.breakpoints.down('xs')]:{
      display:'block',
      // marginLeft: theme.spacing(-2),
      // marginRight: theme.spacing(-2)
    }
  },
  noPadding:{
    [theme.breakpoints.down('xs')]:{
      
    }
  }
});

const ArticleList = ({ classes, fetchArticleList, articleList, hashKey, theme, body, items, variant, images, background_images, link_to, link_title, openLink, history }) => {
    
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1.2,
      initialIndex: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.04,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            // arrows: true
          }
        },
      ]
      // variableWidth: true,
    };

    useEffect(() => {
        fetchArticleList()
    }, [])

    return (
      <Fade>
        <div className={clsx(classes.sectionArticleList,classes.light)}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <Markdown source={ body }/>
                    </Grid>
                    { articleList.lastFetched ?
                      articleList.data.records.map(item =>
                    <Grid item md={4} sm={6} xs={12} className={classes.hiddenMobile}>
                      {/* <div className={classes.hiddenMobile}> */}
                        <ArticleCard item={item} onClick={()=>openLink("/article/"+item.key)} history={history}/>
                      {/* </div> */}
                    </Grid>
                      )
                      :
                      <Skeleton variant="rect" width={210} height={118} />
                    }
                    <Grid item md={4} sm={6} xs={12} className={classes.hiddenDesktop}>
                      <Slider {...settings} className={classes.noPadding}>
                        { articleList.lastFetched ?
                          articleList.data.records.map(item =>
                            <ArticleCard item={item} onClick={()=>openLink("/article/"+item.key)} history={history}/>
                            )
                            :
                            <Skeleton variant="rect" width={210} height={118} />
                        }
                      </Slider>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Link to="/article" className={classes.loadMore}>
                        <Typography variant='h5'>View More</Typography>
                      </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
        </Fade>
    )
}

const mapStateToProps = (state, ownProps) => {
    let indexKey = 'default'
    return { 
        articleList: getContentIndex(state, 'article', indexKey),
    }
  } 
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchArticleList: (refresh=false) => {
        let params = null
        let indexKey = 'default' 
        dispatch(fetchContentIndex('article', indexKey, params, refresh))
      },
    }
  }
  
  ArticleList.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true})(ArticleList))