import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import HomeIcon from '@material-ui/icons/Home'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import Badge from '@material-ui/core/Badge'

const styles = theme => ({
  nav: {
    width: '100%',
    position: 'fixed',
    maxWidth: 480,
    bottom:0,
    // This will add bottom space on iPhone X/iPad Pro
    height: 60+8,
    paddingBottom: 8,
    zIndex: 1100 // Below appbar (1100)    
  },
  navAction: {
    minWidth: 60
  }
});

const BottomNavBar = ({ classes, path, user, inboxStats }) => {

  let selected = 0
    
  if (path && path !== undefined) {
    if (path.startsWith("/account") || path.startsWith("/user"))
      selected = 2
    else if (path.startsWith("/inbox"))
      selected = 1
  }
      
  let inboxIcon = (
    <QuestionAnswerIcon />
    )
  if (inboxStats && inboxStats !== undefined && inboxStats.new_count !== undefined && inboxStats.new_count > 0)
    inboxIcon = (
      <Badge badgeContent={ inboxStats.new_count } color="secondary">
        { inboxIcon }
      </Badge>
    )
    
  
  let userIcon = (
    <AccountCircleIcon />
    )

  return (
    <BottomNavigation
      value={selected}
      showLabels
      className={classes.nav}
    >
      <BottomNavigationAction className={classes.navAction} label="Home" icon={<HomeIcon />} component={Link}
      to="/" />
      <BottomNavigationAction className={classes.navAction} label="Chat" icon={inboxIcon} component={Link}
      to="/inbox" />
      <BottomNavigationAction className={classes.navAction} label="Akun" icon={userIcon} component={Link}
      to="/user" />        
    </BottomNavigation>
  )
}

BottomNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return { 
    user: state.user,
    // Note: Can't use ownProps.match. Probably because we're not inside
    // <Switch>
    path: ownProps.location.pathname,
    inboxStats: state.alpha.conversationList.stats
  }
} 

export default withRouter(connect(
  mapStateToProps,
)(withStyles(styles)(BottomNavBar)));
