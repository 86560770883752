import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
//import Button from '../components/Button'
import Button from '@material-ui/core/Button'
//import Typography from '../components/Typography'
import Typography from '@material-ui/core/Typography'
import Layout from './Layout'
import Markdown from '../ui/Markdown'
import Box from '@material-ui/core/Box'

//const backgroundImage =
//  'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400&q=80'

const styles = theme => ({
  background: {
//    backgroundImage: `url(${backgroundImage})`,
//    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  image: {
    width: 20,
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

const Hero = ({ classes, key, body, variant="", images, background_images, link_to, link_title, openLink }) => {

  let backgroundStyle = {}
  let backgroundImageUrl = null
  if (background_images && background_images !== undefined && background_images.length > 0) {
  
    backgroundImageUrl = background_images[0].image_url
    if (backgroundImageUrl.startsWith("/"))
      backgroundImageUrl = "http://178.128.86.10:8000" + backgroundImageUrl
    backgroundStyle['backgroundImage'] = `url(${backgroundImageUrl})`
  }
  
  let imageUrl = null
  if (images && images !== undefined && images.length > 0) {
    imageUrl = images[0].image_url
    if (imageUrl.startsWith("/"))
      imageUrl = "http://178.128.86.10:8000" + imageUrl
  }

  return (
    <Layout backgroundClassName={classes.background} dark={variant=='Dark'} backgroundStyle={backgroundStyle}>
      { backgroundImageUrl &&
        /* Increase the network loading priority of the background image. */
        <img style={{ display: 'none' }} src={backgroundImageUrl} alt="" />
      }
      <Box align="center">
      { imageUrl && false &&
        <img src={imageUrl} className={classes.Image} width="147" />
      }
      
      <Markdown source={ body } />
      </Box>
      { link_to && link_to !== undefined &&
        <Box align="center">
          <p/>
          <Button variant="contained" color="primary" onClick={ () => openLink(link_to) }>
            { link_title }
          </Button>
        </Box>
      }      
    </Layout>
  );
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hero);
