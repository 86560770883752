import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import {
  Link
} from "react-router-dom";
import CoursesDetailCard from '../cards/CoursesDetailCard';
import Fade from 'react-reveal/Fade';
import { relative } from 'path';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionCourseHero:{
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover',
    minHeight:'50vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    // textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      minHeight: '90vh',
      paddingTop: theme.spacing(8),
      paddingBottom: 0
    },
    [theme.breakpoints.between('sm','md')]:{
      minHeight: '35vh',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(10)
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
      
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold'
  }
});

const CourseHero = ({ classes, topicKey , courseList, background_images }) => {

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      variableWidth: true
    };

    let title = '';
    let backgroundImage = background_images !== undefined ? background_images : 'https://media.pasartrainer.com/45104640091720/image/content/contentfield/9dd1aa2c-df97-4b3d-8547-7fd82283f590.jpeg' 
    return (
        <div className={clsx(classes.sectionCourseHero,classes.dark)} style={{backgroundImage: 'url('+backgroundImage+')'}}>
            <Container>
                {courseList.data.records &&
                <Fade>
                    <CoursesDetailCard item={courseList.data.records}/>
                </Fade>
                }
            </Container>
        </div>
    )
}

CourseHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CourseHero);