import React, { useState, useEffect, useContext } from 'react'
import { NavigationContext } from './NavigationController'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'

const styles = theme => ({
  root: {
  },
  closeButton: {
    position: 'fixed',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    'z-index': 1350
  },
  contentDiv: {
//    minHeight: 800,
//    padding: theme.spacing.unit * 2,
  },
  titleBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1350,
    [theme.breakpoints.down('sm')]: {    
      // Quick workaround to fix 10px blank on top of title bar.
      // This can't be used on large screen though
      backgroundColor: '#fff'
    }
  },
  statusBar: {
    position: 'fixed',
//    padding: theme.spacing.unit * 2,
    padding: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: 1350,
  }  
})

const PageBase = ({ classes, theme, popupContent, closePopup, bottomBar, children }) => {

  let sm = useMediaQuery(theme.breakpoints.up('sm'))
  let md = useMediaQuery(theme.breakpoints.up('md'))
  let lg = useMediaQuery(theme.breakpoints.up('lg'))

  const showTitle = (c) => {
  }      
      
  const showBottomMenu = (c) => {
    setNavigationContext({
      ...navigationContext,
      bottomMenu: c
    })
  }
      
  const showTitleBar = (c) => {
    setNavigationContext({
      ...navigationContext,
      titleBar: c
    })
  }
  
  const appNavigationContext = useContext(NavigationContext)
  const showSideContent = (c) => {
    appNavigationContext.showSideContent(c)
  }  
        
  const showPopupContent = (c) => {
    if (sm)
      appNavigationContext.showPopupContent(c)
    else
      setNavigationContext({
        ...navigationContext,
        popupContent: c
      })
  }
  
  const [navigationContext, setNavigationContext] = useState({
      title: null,
      showTitle: (c)=>{},
      showBottomMenu: showBottomMenu,
      showTitleBar: showTitleBar,
      bottomMenu: null,
      titleBar: null,
      popupContent: null,
      showPopupContent: showPopupContent,
      showSideContent: showSideContent,
    })
    
  let hasPopupContent = false
  if (popupContent || navigationContext.popupContent)
    hasPopupContent = true
  
  let popupContentStyle = {}
  let statusBarStyle = {}
  
  let maxWidth = 'sm'
  if (lg) {
    maxWidth = 760
    popupContentStyle = {
      width: maxWidth
    }      
  }
  else if (md) {
    maxWidth = 720
    popupContentStyle = {
      width: maxWidth
    }      
  }
  else if (sm) {  
    maxWidth = 680
    popupContentStyle = {
      width: maxWidth
    }      
  }
  else {
    popupContentStyle = {
      width: '100%'
    }
  }
    
  return (
    <div className={classes.root}>
      { Array.isArray(children) ?
        <>
        { children.map(c => (c)) }
        </>
        :
        <>
        { children }
        </>
      }
      
      { sm ?
      
      <>
        <Dialog
          anchor='bottom'
          scroll='body'
          open={ popupContent || navigationContext.popupContent ? true : false }
          onClose={ closePopup }
          maxWidth={ false }
        >
        
          <Box className={ classes.titleBar } display={ navigationContext.bottomMenu ? 'block' : 'none' } style={{ display:'flex', justifyContent:'center'}}>
            <div style={{ width: maxWidth }}>
            { navigationContext.titleBar }
            </div>
          </Box>
        
          <div
            className={ classes.contentDiv }
            style={{ maxWidth: maxWidth, width: maxWidth }}
          >
          <NavigationContext.Provider value={ navigationContext }>
            { navigationContext.popupContent || popupContent }
          </NavigationContext.Provider>
          
          <Box className={ classes.statusBar } display={ navigationContext.bottomMenu ? 'block' : 'none' } style={{ display:'flex', justifyContent:'center'}}>
            <div style={{ width: maxWidth }}>
            { navigationContext.bottomMenu }
            </div>
          </Box>
          
          </div>
        </Dialog>
      </>        
      :
      <>
        <Box className={ classes.titleBar } display={ navigationContext.titleBar ? 'block' : 'none' }>
          { navigationContext.titleBar }
        </Box>      
  
        <Box className={ classes.closeButton } display={ hasPopupContent ? "block" : "none" }>
          <Button size='medium' className={ classes.closeButton} onClick={ closePopup }>
            <CancelIcon/>
          </Button>
        </Box>
                      
        <Drawer
          anchor='bottom'
          open={ hasPopupContent }
          onClose={ closePopup }
        >
          <div
            className={ classes.contentDiv }
            style={ popupContentStyle }
          >
          <NavigationContext.Provider value={ navigationContext }>
            { popupContent }
          </NavigationContext.Provider>
          </div>
        </Drawer>
  
        <Box className={ classes.statusBar } display={ navigationContext.bottomMenu ? 'block' : 'none' }>
          { navigationContext.bottomMenu }
        </Box>
      </>
      }      
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(
  PageBase
)