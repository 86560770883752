import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
//import Button from '../components/Button'
import Button from '@material-ui/core/Button'
//import Typography from '../components/Typography'
import Typography from '@material-ui/core/Typography'
import Layout from './Layout'
import Markdown from '../ui/Markdown'

const styles = theme => ({
});

const Text = ({ classes, body, images, background_images }) => {

  return (
    <Layout>
      <Markdown source={ body } />
    </Layout>
  );
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Text);
