import React, { useState, useEffect, useContext } from 'react'
import { Route, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser, authenticateWithToken, login, register, logout, fetchContactInfo, updateContactInfo } from '../alpha/data/reducers/user'
import { getBusinessInfo } from '../alpha/data/reducers/business'
import { getVisitor } from '../alpha/data/reducers/visitor'

import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import queryString from 'query-string'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import Chat from './Chat'

import ContactForm from '../components/forms/ContactForm'

const styles = theme => ({
  root: {
  },
  loginButton: {
    width: '100%'
  },
  accountHeader: {
    paddingLeft: theme.spacing(1) * 2,
    paddingRight: theme.spacing(1) * 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) * 3,
  },
  accountAvatar: {
    width: 80,
    height: 80
  },
  accountName: {
//    fontWeight: 800
    color: '#999'
  },
  pageTitle: {
    color: '#000',
    fontWeight: 800,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }    
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1) * 2,
    color: '#999',
  },
  sectionFooter: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1) * 2,
  },
  sectionDivider: {
    minHeight: theme.spacing(1) * 3,
  },  
  sectionBody: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  sectionBodyTransparent: {
    width: '100%',
  },
  sectionContent: {
    paddingLeft: theme.spacing(1) * 2,
    paddingRight: theme.spacing(1) * 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '100',
    [theme.breakpoints.down('xs')]:{
      marginTop:theme.spacing(6)
    }
  },  
  sectionContentAction: {
  },
  bottomPadding: {
    minHeight: 100
  },
  btnClient:{
    // margin: '16px 0',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontSize: '16px',
    width: '50%',
    [theme.breakpoints.down('xs')]:{
      marginTop:theme.spacing(3),
      marginBottom: theme.spacing(0)
    }
  },
  btnTrainer:{
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '16px',
    width: '50%'
  },
  labelBtn:{
    marginTop: theme.spacing(3),
    fontWeight: 'bold'
  }
})

const AccountHeader = ({ classes, business, user, visitor, logout }) => {
  let initial = ""
  let name = ""
  let contactInfo = user.contactInfo
  
  if (contactInfo) {
    if (contactInfo.first_name) {
      initial = contactInfo.first_name.substring(0, 1)
      name = contactInfo.first_name
    }
    if (contactInfo.last_name) {
      initial += contactInfo.last_name.substring(0, 1)
      name += " " + contactInfo.last_name
    }
  }
  else {
    name = "Kak/Sis"
  }

  return (
    <Grid container className={classes.accountHeader}>
      <Grid item xs>
        <Typography variant='h5' className={classes.accountName}>
        Selamat datang di {business.name}{ name && ','} <Box fontWeight={800} color='#000' component='span'>{ name }</Box>
        </Typography>
      </Grid>
    </Grid>
  )

  
  return (
    <Grid container className={classes.accountHeader}>
      <Grid item xs={4}>
        <Avatar className={classes.accountAvatar}>
        {initial}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Typography variant='h4' className={classes.accountName}>
        { name }
        </Typography>
      </Grid>
    </Grid>
  )
}


const ProfileSection = ({ classes, user, visitor, showPopup, closePopup, updateContactInfo }) => {

  let contactInfo = user.contactInfo
    
  const contactForm = (
    <ContactForm initial={user.contactInfo}
      saveForm={ (contact) => {
        updateContactInfo(contact)
        closePopup()
      }}
      cancelForm={ () => {
        closePopup()        
      }}
     />
  )

  let hasContactInfo = contactInfo !== undefined && contactInfo && contactInfo.first_name && user.contactInfo.first_name !== undefined
      
  return (
    <Box className={classes.sectionBody} onClick={()=>showPopup(contactForm)}>
      <Divider/>
          
      { hasContactInfo &&
      <Box width='100%' className={classes.sectionContent} onClick={()=>showPopup(contactForm)}>
        <Box flexGrow={1}>
         
            <>
              <Typography component="div" variant='body1' style={{ fontWeight: 800 }}>
                { contactInfo.first_name } { contactInfo.last_name }<br/> 
              </Typography>
              <Typography component="div" variant='body2'>
                Mobile No: { contactInfo.mobile_no }<br/> 
                Email: { contactInfo.email }
              </Typography>
            </>
          
        </Box>
        <Box className={classes.sectionContentAction}>
          <IconButton aria-label="Edit Contact" onClick={ () => showPopup(contactForm) }>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      }

      <Divider/>
    </Box>  
    
  )
}

const LoginSection = ({ classes, user, visitor, business, login, register, logout, onClickChat }) => {

  const dashboard = () => {
    if(user.companyInfo){
      if(business.company.uid === user.companyInfo.uid){
        window.open(process.env.REACT_TRAINER_URL || 'https://trainer.pasartrainer.com','_self')
      }else{
        window.open(process.env.REACT_CLIENT_URL || 'https://client.pasartrainer.com','_self')
      }
    }
  }

  if (user.isLoggedIn)
    return (
    <Box className={classes.sectionBodyTransparent}>
      <Box width='100%' className={classes.sectionContent}>
        <Box flexGrow={1}>
          <Button variant='contained' fullWidth color='primary' onClick={dashboard }>Go To Dashboard</Button>
        </Box>
      </Box>
      {/* <Box width='100%' className={classes.sectionContent}>
        <Box flexGrow={1}>
          <Button variant='outlined' fullWidth color='secondary'>Go To Dashboard</Button>
        </Box>
      </Box> */}
      <Box width='100%' className={classes.sectionContent}>
        <Box flexGrow={1}>
          <Button variant='outlined' fullWidth color='secondary' onClick={ logout }>Log Out</Button>
        </Box>
      </Box>
    </Box>
    )
  else
    return (
    <Box className={classes.sectionBodyTransparent}>
      <Box width='100%' className={classes.sectionContent}>
        <Box flexGrow={1} textAlign='center'>
          <Typography variant="h6" className={classes.pageTitle}>
            You are not logged-in
          </Typography>
          <Typography variant='body1' gutterBottom>
            Log-in to start the easiest
            way of arranging training
            classes for your employees.
          </Typography>
          <Typography variant='body'>
            <Button variant='contained' color='primary' onClick={ () => window.open(process.env.REACT_CLIENT_URL || 'https://client.pasartrainer.com','_self') } className={classes.btnClient}>Client Login</Button>
          </Typography>
          <Typography component="div" variant='h6' className={classes.labelBtn} gutterBottom>
            Are you a professional trainer?
          </Typography>
          <Typography variant='body' gutterBottom>
            <Button variant='contained' color='primary' onClick={ () => window.open(process.env.REACT_TRAINER_URL || 'https://trainer.pasartrainer.com','_self')} className={classes.btnTrainer}>Trainer Login</Button>
          </Typography>
          <Typography component="div" variant='h6' className={classes.labelBtn} gutterBottom>
            Don’t have an account?
          </Typography>
          <p/>
          <Typography variant='body' gutterBottom>
            <Button variant='contained' color='primary' className={classes.btnTrainer} onClick={onClickChat}>Contact Us</Button>
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

const AccountView = ({ theme, classes, history, business, user, visitor, login, register, logout, fetchContactInfo, updateContactInfo, orderList, fetchOrderList }) => {

  const navigationContext = useContext(NavigationContext)
  const [popupContent, setPopupContent] = useState(null)
  
  useEffect(() => {
    // Retrieve contact info. This could be changed when updating cart or
    // from other app instance.
        
    fetchContactInfo()
  }, [])
  
  useEffect(() => {
    navigationContext.setLoading(user.isUpdatingContact)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [user.isUpdatingContact])
  
  let smUp = useMediaQuery(theme.breakpoints.up('sm'))  
  
  const openLink = (link) => {
    if (smUp) {
    }
    
    history.push(link)
  }

  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }
  
  const showPopup = (content) => {
    setPopupContent(content)      
  }
  
  const closePopup = () => {
    setPopupContent(null)
  }

  let popupContentToShow = popupContent
  if (!popupContentToShow) {
  }        
  
  return (
    <>
    <Helmet>
        <title>Account - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContentToShow}
      closePopup={closePopup}>
      <div className={classes.root}>

        {user.isLoggedIn &&
        <>
        <Box className={classes.pageTitle}>
          <Typography variant="h6" className={classes.pageTitle}>My Account
          </Typography>
        </Box>
      
        <Box display='none'>
        <AccountHeader classes={classes} business={business} user={user} visitor={visitor}/>
        </Box>
  
        {/* <Box className={classes.sectionHeader} di>
          <Typography variant="body1">
            
          </Typography>
        </Box> */}

        <ProfileSection classes={classes} user={user} showPopup={showPopup} closePopup={closePopup} updateContactInfo={updateContactInfo} />
        </>      
        }
        {/* <div className={classes.sectionDivider} /> */}

        <LoginSection classes={classes} business={business} user={user} visitor={visitor} login={login} register={register} logout={logout} onClickChat={()=>openChat(-1)}/>

        <div className={classes.bottomPadding}/>
      </div>
        
    </PageBase>
    </>
  )  
}

const mapStateToProps = (state, ownProps) => {
  return { 
    visitor: getVisitor(state),
    user: getUser(state),
    business: getBusinessInfo(state).data,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let callbackUrl = window.location.href
  return {
    login: () => { dispatch(login(callbackUrl)) },
    register: () => { dispatch(register(callbackUrl)) },
    logout: () => { dispatch(logout(callbackUrl)) },
    fetchContactInfo: () => { dispatch(fetchContactInfo()) },
    updateContactInfo: (data) => { dispatch(updateContactInfo(data)) },
    authenticateWithToken: (authToken) => {
      dispatch(authenticateWithToken(authToken))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    AccountView)
  )
) 