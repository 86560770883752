import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../alpha/data/reducers/user'
import { getContentIndex, fetchContentIndex } from '../alpha/data/reducers/content'
import { getBusinessInfo } from '../alpha/data/reducers/business'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import Chat from './Chat'
import Story from './Story'
import Markdown from '../components/ui/Markdown'

import ArticleCard from '../components/cards/ArticleCard'

import moment from 'moment'
import ls from 'local-storage'

import ImagesSlider from '../components/ui/ImagesSlider'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ArticleHero from '../components/sections/ArticleHero'
import ContactUs from '../components/sections/ContactUs'
import Footer from './Footer'

const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 2,
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1) * 4,
    }
  },
  sectionArticleList:{
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    background: '#ebebeb'
  },
  articleList:{
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  welcomeCard: {
    paddingBottom: theme.spacing(1),
  },
  logo: {
    paddingTop: theme.spacing(1) * 1,
    paddingBottom: theme.spacing(1) * 1,
  },
  logoTitle: {
    color: "#555",
    fontWeight: 600
  },
  logoSubTitle: {
    color: "#999"
  },
  businessName: {
    fontSize: '1.5rem',
    color: "#555",
    padding: theme.spacing(1)
  },
  avatar: {
    width: 80,
    height: 80,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ddd'
  },
  welcomeText: {
    color: "#999"
  },
  welcomeTitle: {
    fontWeight: 600,
  },
  mainButton: {
    weight: '100%'
  },
  divider: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
  },
  categoryFilter: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
    paddingLeft: theme.spacing(1) * 2,
    overflow: 'scroll',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) * 2,
  },
  categoryFilterRow: {
    width: 1000
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  storyList: {
  },
  
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 2
  },
  fabIconLeft: {
    marginRight: theme.spacing(1)
  },
  fabIconRight: {
    marginLeft: theme.spacing(1)
  },
});

const WelcomeCard = ({ classes, business, user, showPopup }) => {
  let welcomeText
  if (user && user.isLoggedIn)
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi, { user.userInfo.first_name } { user.userInfo.last_name }
      </Typography>
    )
  else
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi
      </Typography>
    )

  let visitor = ls.get('visitor_id')
  if (visitor === undefined || !visitor)
    visitor = null

  return (
    <div className={classes.welcomeCard}>
      <Grid container direction='column' spacing={1}>
        <Grid item xs>
          <Box display='flex' display='none'>
          <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>
          <Box className={classes.businessName}>
          {business.name}
          </Box>
          </Box>
          <Typography className={classes.logoSubTitle} variant='body2'>
            <Markdown source={ business.bio } />
          </Typography>
        </Grid>
        <Box display='none'>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth component={ RouterLink } to="/catalog" >
                Belanja
              </Button>
            </Grid>
            
            <Grid item xs={6}>
            { visitor ?
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth
                onClick={()=>showPopup(
                  <Chat conversationKey={0}/>
                )}
              >
                Chat
              </Button>
              :
              <Button variant="outlined" size="medium" aria-label="" className={classes.mainButton} fullWidth
              >
                ...
              </Button>
            }
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </div>
)}

const CategoryFilter = ({ classes, categoryList, activeKey, openLink }) => {

  if (categoryList.lastFetched)
    return (
      <div className={classes.categoryFilter}>
        <div className={classes.categoryFilterRow}>
            <Button variant="contained" size="medium" 
              color={ activeKey ? '' : 'primary'} 
              aria-label="" 
              className={classes.categoryButton}
              onClick={() => openLink("/article")}
            > Semua Post
            </Button>
          { categoryList.data.records.map(cat => (
            <Button variant="contained" size="medium" aria-label=""            
              className={classes.categoryButton}
              color={activeKey == cat.key ? 'primary': ''}
              onClick={() => openLink("/article/"+cat.key)}
            >
              { cat.name }
            </Button>
            ))
          }
        </div>
      </div>
    )
  else
    return (<> </>)
}

const ArticleList = ({ classes, articleList, openLink, history }) => (

  <div className={classes.articleList} >
    <Container>
      <Grid container spacing={3}>
      { articleList.lastFetched ?
        articleList.data.records.map(item =>
      <Grid item xs={12} sm={6} md={4} key={item.key}>
        <ArticleCard item={item} onClick={()=>history.push("/article/"+item.key)} history={history}/>
      </Grid>
        )
        :
        <p>...</p>
      }
      </Grid>
      </Container>
  </div>
)

const Article = ({ classes, history, business, user, articleList, topicKey, storyKey, productKey, categoryList, fetchArticleList, fetchCategoryList, conversationList }) => {

  const navigationContext = useContext(NavigationContext)
  const [hasBottomMenu, setHasBottomMenu] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  
  useEffect(() => {
    navigationContext.showTitle("")
    
    fetchArticleList()
    fetchCategoryList()

/*      
    if (!hasBottomMenu) {
      setHasBottomMenu(true)
      navigationContext.showBottomMenu( b
        <Fab variant="extended" size="medium" aria-label="Menu" className={classes.fab} onClick={ showMenu }>
          <MenuIcon className={classes.fabIconLeft} />
          Menu
        </Fab>
      )
    }
*/
  
    return () => {
      if (hasBottomMenu) {
        navigationContext.showBottomMenu(null)
        setHasBottomMenu(false)
      }
    }    
  })
  
  useEffect(() => {
    navigationContext.setLoading(articleList.isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [articleList.isFetching])
  
  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }

  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("#")) {
      // Scroll to section.
//      alert(linkTo)
//      window.location = linkTo
//      window.location.hash = linkTo //linkTo.substring(1)
      const id = linkTo.substring(1)
      const element = document.getElementById(id);
      if (element) 
        element.scrollIntoView({behavior: 'smooth'});
      return
    }
    
    if (linkTo == "chat") {
      // Open chat screen
      openChat(0)
      return
    }
    
    if (linkTo.startsWith("product") || linkTo.startsWith("story")) {
      if (topicKey)
        link = "/article/" + topicKey
      else
        link = "/article"
    
      link += "/" + linkTo + "?popup=1"
    }
    else if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo
      
    history.push(link)
  }
  
  const openTrainer = (item) => {
    if (topicKey)  
      history.push("/article/" + topicKey + "/trainer/" + item.key)
    else
      history.push("/article/trainer/" + item.key)
//    alert("openStory: " + item.uid)

//    setPopupContent(
//      <Story slug={item.uid} />
//    )
    
  }
  
  const [newPopupContent, setNewPopupContent] = useState(null)
  
  const showPopup = (content) => {
    setNewPopupContent(content)
  }
  const closePopup = () => {
    if (newPopupContent)
      setNewPopupContent(null)
    else {
      if (topicKey)
        history.push("/article/" + topicKey)
      else
        history.push("/article")
//        history.push("/")
    }
  }
  
  let popupContent = newPopupContent
  if (!popupContent) {
    if (storyKey)
      popupContent = (
        <Story slug={storyKey} openLink={openLink} />
      )
  }
  
  return (
    <>
    <Helmet>
        <title>Article - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContent}
      closePopup={closePopup}>
      <div className={classes.sectionArticleList}>
        {/* <Container className={classes.root}> */}
          {/* <WelcomeCard classes={classes} business={business} user={user} showPopup={showPopup} /> */}
          {/* <CategoryFilter classes={classes} categoryList={categoryList} activeKey={topicKey} openLink={openLink} /> */}
          <ArticleHero history={history} classes={classes} articleList={articleList} openLink={openLink} />
        {/* <Container> */}
          <ArticleList history={history} classes={classes} articleList={articleList} openLink={openLink} />  

          {articleList.lastFetched &&
          <ContactUs history={history} classes={classes} onClick={()=>openChat(-1)}/>
          }
          <Footer business={ business }/> 
        {/* </Container> */}
      </div>
    </PageBase>
    </>
  )
}

function topicKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.topicKey !== undefined)
    key = props.match.params.topicKey
  return key
}

function storyKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.storyKey !== undefined)
    key = props.match.params.storyKey
  return key
}

function productKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.productKey !== undefined)
    key = props.match.params.productKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let topicKey = topicKeyFromProps(ownProps)
  let indexKey = topicKey ? topicKey : 'default'

  return { 
    user: state.user,
    business: getBusinessInfo(state, '0').data,
    articleList: getContentIndex(state, 'article', indexKey),
    topicKey: topicKey,
    categoryList: getContentIndex(state, 'article-category', 'default'),
    storyKey: storyKeyFromProps(ownProps),
    conversationList: state.alpha.conversationList,
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let topicKey = topicKeyFromProps(ownProps)
  let indexKey = topicKey ? topicKey : 'default'

  return {
    login: () => { dispatch(login()) },
    fetchArticleList: (refresh=false) => {
      let params = topicKey ? { category_f: topicKey } : null
      dispatch(fetchContentIndex('article', indexKey, params, refresh))
    },
    fetchCategoryList: (refresh=false) => {
      // dispatch(fetchContentIndex('article-category', 'default', null, refresh))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    Article)
  )
)