import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import { getContentIndex, fetchContentIndex } from './alpha/data/reducers/content'
import { getBusinessInfo } from './alpha/data/reducers/business'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 2,
    minWidth: 300
  },
  logo: {
    maxHeight: 32,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 40
    }
  },
  linkText: {
  }
});

const PageMenu = ({ classes, business, isFetching, lastFetched, data, fetchPageList }) => {

  useEffect(() => {
    fetchPageList()
  })

  if (isFetching)
    return (
      <div className={classes.root}>
      Loading...
      </div>
    )  
  else if (lastFetched)
    return (
      <div className={classes.root}>
        <Container>
            <img src={business.logo.image_url} className={classes.logo}/>        
        </Container>
        <List>
          <ListItem button component={RouterLink} to={'/'}>
            <ListItemText
              primary={
              <Typography variant='h5' className={classes.linkText}>
              Home
              </Typography>
              } />
          </ListItem>
          {/* <ListItem button component={RouterLink} to={'/blog'}>
            <ListItemText
              primary={
              <Typography variant='h5' className={classes.linkText}>
              Blog
              </Typography>
              } />
          </ListItem> */}
          <ListItem button component={RouterLink} to={'/trainer'}>
            <ListItemText
              primary={
              <Typography variant='h5' className={classes.linkText}>
              Trainers
              </Typography>
              } />
          </ListItem>
          <ListItem button component={RouterLink} to={'/article'}>
            <ListItemText
              primary={
              <Typography variant='h5' className={classes.linkText}>
              Articles
              </Typography>
              } />
          </ListItem>
          <ListItem button component={RouterLink} to={'/course'}>
            <ListItemText
              primary={
              <Typography variant='h5' className={classes.linkText}>
              Courses
              </Typography>
              } />
          </ListItem>
          {/* { data && data.records.map((page) =>
            page.key != 'home' && page.key != 'course-detail' && page.key != 'trainer-profile' && (
            <ListItem button component={RouterLink} to={'/page/' + page.key}>
              <ListItemText
                primary={
                <Typography variant='h5' className={classes.linkText}>
                { page.title }
                </Typography>
                } />
            </ListItem>          
            ))
          } */}
        </List>
      </div>
    )
  else
    return (
      <div className={classes.root}>
      </div>
    )
}

const mapStateToProps = (state, ownProps) => {
  return { 
    ...getContentIndex(state, 'page', 'default'),
    business: getBusinessInfo(state, '0').data,    
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
 
  return {
    fetchPageList: (refresh=false) => {
      dispatch(fetchContentIndex('page', 'default', null, refresh))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    PageMenu)
  )
)