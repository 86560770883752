import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  storyCard: {
    flexGrow: 1,
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2,    
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  storyTitle: {
    fontWeight: 800
  },
  storyLead: {
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cardArticle:{
    height: '350px',
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]:{
      width: '95%',
      height: '250px'
      // marginLeft: theme.spacing(-2),
      // marginRight: theme.spacing(-2)
    }
  },
  contentArticle:{
    background: 'white',
    padding: '15px',
    position: 'absolute',
    bottom: 0,
    textAlign: 'left',
    width: '100%'
  },
  titleArticle:{
    color: '#808080',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]:{
        fontSize: '18px'
    }
  },
  authorArticle:{
    color: '#E75A0B',
    fontWeight: 'bold'
  },
  dateArticle:{
    color: 'black',
    fontWeight: 'bold'
  },
  detailArticleTitle:{
    fontWeight: 'bold'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
})


const ArticleCard = ({ classes, item, history, onClick }) => {

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [articleData, setArticleData] = React.useState('');

  const handleClickOpen = (item) => {
    history.push('/article/'+item.key)
    // setArticleData(item)
    // setOpen(true);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div className={classes.cardArticle} style={{background:'#808080'}} onClick={() => handleClickOpen(item)}>
      <div className={classes.contentArticle}>
        <Typography className={classes.titleArticle} variant="h5">
          {item.title}
        </Typography>
        <Typography className={classes.authorArticle}>
          {item.author || 'Pasar Trainer'}
        </Typography>
        {/* <Typography className={classes.dateArticle}>{moment(item.created_at).format('LL')}</Typography> */}
      </div>
    </div>

    {articleData &&
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.hiddenMobile}
      >
      <DialogTitle id="max-width-dialog-title">
        <Typography variant='h5' className={classes.detailArticleTitle}>{articleData.title}</Typography>
        <Typography variant='h6'>{articleData.author || 'Pasar Trainer'}</Typography>
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body1'>{articleData.body || ''}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    }
    </>
  )
}

ArticleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticleCard);
