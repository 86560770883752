import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import CourseTopic from '../cards/CourseTopic';
import {
  Link
} from "react-router-dom";

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionCategoryCourse:{
    // background: '#acacac',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center'
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
    marginTop: '70px'
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: 'white'
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  }
});

const TrainerListHero = ({ classes, topicKey ,background_images, trainerList, history }) => {
    
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      variableWidth: true,
    };

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : 'https://media.pasartrainer.com/45104640091720/image/content/contentfield/9dd1aa2c-df97-4b3d-8547-7fd82283f590.jpeg'
    return (
        <div className={clsx(classes.sectionCategoryCourse,classes.dark)} style={{backgroundImage:`url(`+backgroundImage+`)`}}>
            <Container>
              {trainerList.data.records &&
                <div className={classes.titleSection}>
                    {categoryName === 'all-trainer' &&
                    <Typography variant='h2' className={classes.categoryName}>
                        Featured Trainers
                    </Typography>
                    }
                    {/* {categoryName === 'all-topics' &&
                    <Slider {...settings} className={classes.carouselItem}>
                    { courseList.lastFetched &&
                      courseList.data.records.categories.map(item =>
                        <CourseTopic item={item} history={history}/>
                    )
                    }
                    </Slider>
                    } */}
                    {/* {categoryName !== 'all-topics' &&
                    <Typography variant='h2' className={classes.categoryName}>
                        {articleList.data.records.categories[0].name}
                    </Typography>
                    }
                    {categoryName !== 'all-topics' &&
                      <Link to='/course' className={classes.viewAll}>
                      <Typography variant='body1'>
                        View All Topics
                      </Typography>
                      </Link>
                    } */}
                </div>
              }
            </Container>
        </div>
    )
}

TrainerListHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TrainerListHero);