import React, { useState, useEffect, useContext } from 'react'
import { Route, Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../alpha/data/reducers/user'
import { getCourseDetailIndex, fetchCourseDetailIndex } from '../data/reducers/course'
import { getBusinessInfo } from '../alpha/data/reducers/business'

import { NavigationContext } from '../components/ui/NavigationController'

import PageBase from '../components/ui/PageBase'

import Chat from './Chat'
import Story from './Story'
import Markdown from '../components/ui/Markdown'

import StoryCard from '../components/cards/StoryCard'

import moment from 'moment'
import ls from 'local-storage'

import ImagesSlider from '../components/ui/ImagesSlider'

import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CoursesCard from '../components/cards/CoursesCard'
import CategoryCourse from '../components/sections/CategoryCourse'
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import TimerIcon from '@material-ui/icons/Timer';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CourseHero from '../components/sections/CourseHero'
import CourseDetail from '../components/sections/CourseDetail'
import CourseFeature from '../components/sections/CourseFeature'
import TrainerCard from '../components/cards/TrainerCard'
import Footer from './Footer'
import Fade from 'react-reveal/Fade';
import SyllabusContent from '../components/sections/SyllabusContent'
import clsx from 'clsx';
import Slider from "react-slick";
import NextArrow from '../components/ui/NextArrow'
import PrevArrow from '../components/ui/PrevArrow'
import ContactUs from '../components/sections/ContactUs'
import TrainerSliderCard from '../components/cards/TrainerSliderCard';
import { Helmet } from 'react-helmet'

const styles = theme => ({
  root: {
    padding: theme.spacing(1) * 2,
    backgroundColor: "#ebebeb",
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1) * 4,
    }
  },
  sectionCourseDetailList:{
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    background: '#ebebeb'
  },
  welcomeCard: {
    paddingBottom: theme.spacing(1),
  },
  logo: {
    paddingTop: theme.spacing(1) * 1,
    paddingBottom: theme.spacing(1) * 1,
  },
  logoTitle: {
    color: "#555",
    fontWeight: 600
  },
  logoSubTitle: {
    color: "#999"
  },
  businessName: {
    fontSize: '1.5rem',
    color: "#555",
    padding: theme.spacing(1)
  },
  avatar: {
    width: 80,
    height: 80,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ddd'
  },
  welcomeText: {
    color: "#999"
  },
  welcomeTitle: {
    fontWeight: 600,
  },
  mainButton: {
    weight: '100%'
  },
  divider: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
  },
  categoryFilter: {
    marginLeft: -theme.spacing(1) * 2,
    width: `calc(100% + ${theme.spacing(1)*4}px)`,
    paddingLeft: theme.spacing(1) * 2,
    overflow: 'scroll',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) * 2,
  },
  categoryFilterRow: {
    width: 1000
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  storyList: {
  },
  totalCourses:{
    // textAlign: 'center'
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  courseList:{
    background: 'white',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  newDevide: {
    width: '100%',
    height: theme.spacing(1),
//    backgroundColor: theme.palette.common.white,
    backgroundColor: '#ebebeb'
  },
  sectionTrainerList:{
    background: 'white',
  minHeight:'0vh',
  minWidth:'100%',
  display:"flex",
  overflow: 'hidden'
},
carouselItem:{
  maxWidth: 1200,
  border: 0,
  height: '80vh',
  [theme.breakpoints.down('md')]:{
      height: '80vh',
      margin: 0,
      maxWidth: '100%'
  }
},
titleTrainerList:{
    fontSize: '36px',
    fontWeight: '400',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]:{
        fontSize: '24px',
      //   margin: 0,
      //   marginTop: theme.spacing(3),
      //   marginBottom: theme.spacing(3),
      //   marginLeft: theme.spacing(3)
    }
},
containerTrainerList:{
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
},
slickTrainer:{
    width: 1000,
},
sliderHide:{
  [theme.breakpoints.down('xs')]:{
    // marginLeft: theme.spacing(1) * -2,
    // marginRight: theme.spacing(1) * -2
    display:'none'
  }
},
desktopHidden:{
  display: 'none',
  [theme.breakpoints.down('xs')]:{
    // marginLeft: theme.spacing(1) * -2,
    // marginRight: theme.spacing(1) * -2
    display:'block',
    marginLeft: '-16px',
    marginRight: '-16px'
  }
},
moreTrainers:{
  color: '#E75A0B',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]:{
    paddingTop: theme.spacing(3)
  }
},
noDecoration:{
  textDecoration: 'none'
}
});

const WelcomeCard = ({ classes, business, user, showPopup }) => {
  let welcomeText
  if (user && user.isLoggedIn)
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi, { user.userInfo.first_name } { user.userInfo.last_name }
      </Typography>
    )
  else
    welcomeText = (
      <Typography variant='body1' className={classes.welcomeText}>
      Selamat pagi
      </Typography>
    )

  let visitor = ls.get('visitor_id')
  if (visitor === undefined || !visitor)
    visitor = null

  return (
    <div className={classes.welcomeCard}>
      <Grid container direction='column' spacing={1}>
        <Grid item xs>
          <Box display='flex' display='none'>
          <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>
          <Box className={classes.businessName}>
          {business.name}
          </Box>
          </Box>
          <Typography className={classes.logoSubTitle} variant='body2'>
            <Markdown source={ business.bio } />
          </Typography>
        </Grid>
        <Box display='none'>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth component={ RouterLink } to="/catalog" >
                Belanja
              </Button>
            </Grid>
            
            <Grid item xs={6}>
            { visitor ?
              <Button variant="outlined" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth
                onClick={()=>showPopup(
                  <Chat conversationKey={0}/>
                )}
              >
                Chat
              </Button>
              :
              <Button variant="outlined" size="medium" aria-label="" className={classes.mainButton} fullWidth
              >
                ...
              </Button>
            }
            </Grid>
          </Grid>
        </Grid>
        </Box>
      </Grid>
    </div>
)}

const TrainerList = ({ classes, trainerList, history, openLink }) => {

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1.05,
      initialIndex: 0,
      slidesToScroll: 1,
      arrows: true,
      centerMode: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false
          }
        },
      ]
      // variableWidth: true,
    };

    let title = '';
    return (
      <Fade>
        {trainerList &&
        <>
        {trainerList.data.records && trainerList.data.records.trainers.length > 0 &&
        
        <div className={clsx(classes.sectionTrainerList,classes.light)}>
            <Container className={classes.containerTrainerList}>  
              {/* <Markdown source={ body }/> */}
              <Typography variant='h2' className={classes.totalCourses}>
                <b style={{fontWeight:700}}>Featured</b> Professional Trainers
              </Typography>
              {trainerList.data.records.trainers.length > 1 && 
                <Slider {...settings} className={classes.sliderHide}>
                    { trainerList.data.records ?
                        trainerList.data.records.trainers.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                    ) 
                    :
                    <p>...</p>
                  }
                </Slider>
                }
                {trainerList.data.records.trainers.length === 1 &&
                  <Grid container>
                    <Grid items xs={12} className={classes.itemTrainer}>
                    { trainerList.data.records ?
                        trainerList.data.records.trainers.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                    ) 
                    :
                    <p>...</p>
                  }
                    </Grid>
                  </Grid>
                }
                <div className={classes.desktopHidden}>
                  <Grid container>
                    <Grid items xs={12} className={classes.itemTrainer}>
                    { trainerList.data.records ?
                        trainerList.data.records.trainers.map(item =>
                        <TrainerSliderCard item={item} history={history}/>
                    ) 
                    :
                    <p>...</p>
                  }
                    </Grid>
                  </Grid>
                  <RouterLink to='/trainer' className={classes.noDecoration}>
                    <Typography variant='h5' className={classes.moreTrainers}>
                      Learn More About Our Trainers
                    </Typography>
                  </RouterLink>
                </div>
            </Container>
        </div>
        }
        </>
      }
      </Fade>
    )
}

const CourseDetails = ({ classes, history, business, user, courseDetailList, categoryKey, courseKey, productKey, fetchCourseDetailList, fetchCategoryList, conversationList, topicKey }) => {

  const navigationContext = useContext(NavigationContext)
  const [hasBottomMenu, setHasBottomMenu] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  
  useEffect(() => {
    navigationContext.showTitle("")
    
    fetchCourseDetailList()
  
    return () => {
      if (hasBottomMenu) {
        navigationContext.showBottomMenu(null)
        setHasBottomMenu(false)
      }
    }    
  },[])
  
  useEffect(() => {
    navigationContext.setLoading(courseDetailList.isFetching)
    
    return () => {
      navigationContext.setLoading(false)
    }
  }, [courseDetailList.isFetching])
  
  const openChat = (key) => {
    showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const showPopupContent = (c) => {
    navigationContext.showPopupContent(c)
  }

  const openLink = (linkTo) => {
    let link = ""
    
    if (linkTo.startsWith("#")) {
      // Scroll to section.
//      alert(linkTo)
//      window.location = linkTo
//      window.location.hash = linkTo //linkTo.substring(1)
      const id = linkTo.substring(1)
      const element = document.getElementById(id);
      if (element) 
        element.scrollIntoView({behavior: 'smooth'});
      return
    }
    
    if (linkTo == "chat") {
      // Open chat screen
      openChat(0)
      return
    }
    
    if (linkTo.startsWith("product") || linkTo.startsWith("story")) {
      if (categoryKey)
        link = "/course/" + categoryKey
      else
        link = "/course"
    
      link += "/" + linkTo + "?popup=1"
    }
    else if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo
      
    history.push(link)
  }
  
  const openStory = (item) => {
    if (categoryKey)  
      history.push("/course/" + categoryKey + "/story/" + item.key)
    else
      history.push("/course/story/" + item.key)
//    alert("openStory: " + item.uid)

//    setPopupContent(
//      <Story slug={item.uid} />
//    )
    
  }
  
  const [newPopupContent, setNewPopupContent] = useState(null)
  
  const showPopup = (content) => {
    setNewPopupContent(content)
  }
  const closePopup = () => {
    if (newPopupContent)
      setNewPopupContent(null)
    else {
      if (categoryKey)
        history.push("/course/" + categoryKey)
      else
        history.push("/course")
//        history.push("/")
    }
  }
  
  let popupContent = newPopupContent
  if (!popupContent) {
    // if (storyKey)
    //   popupContent = (
    //     <Story slug={storyKey} openLink={openLink} />
    //   )
  }

  const capitalize = (str) =>
  {
  return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  let moduleData = ''
  let typeCourse = ''
  let trainingDays = 0
  let courseType
  let courseFilter

  if(courseDetailList.lastFetched){
      trainingDays = courseDetailList.data.records.training_days
      moduleData = courseDetailList.data.records.contents.filter(items => items.content_type === "module")
      typeCourse = courseDetailList.data.records.contents.filter(items => items.content_type !== "module").map(items => capitalize(items.content_type.replace(/-/g,' ')))
      courseFilter = typeCourse.filter((items,index) => typeCourse.indexOf(items) === index)
      courseType = courseFilter.join(', ')
  }
  
  return (
    <>
    <Helmet>
        <title>{capitalize(courseKey.replace(/-/g,' '))} - { process.env.REACT_APP_TITLE_SUFFIX }</title>
      </Helmet>
    <PageBase popupContent={popupContent}
      closePopup={closePopup}>
      <div className={classes.sectionCourseDetailList}>
        {/* <Container className={classes.root}> */}
          {/* <WelcomeCard classes={classes} business={business} user={user} showPopup={showPopup} /> */}
          <CourseHero classes={classes} topicKey={topicKey} courseList={courseDetailList} />
          <CourseFeature classes={classes} trainingDays={trainingDays} moduleData={moduleData.length} courseType={courseType}/>  
          <CourseDetail classes={classes} courseList={courseDetailList}/>
          <div className={classes.newDevide} />
          <SyllabusContent classes={classes} courseList={courseDetailList}/>
          <div className={classes.newDevide} />
          <TrainerList classes={classes} trainerList={courseDetailList} history={history}/> 
          <ContactUs history={history} classes={classes} onClick={()=>openChat(-1)}/>
          <Footer business={ business }/> 
        {/* </Container> */}
      </div>
    </PageBase>
    </>
  )
}

function topicKeyFromProps(props) {

  let key = null
  if (props.match && props.match.params.topicKey !== undefined)
    key = props.match.params.topicKey
  return key
}

function courseKeyFromProps(props) {
  let key = null
  if (props.match && props.match.params.courseKey !== undefined)
    key = props.match.params.courseKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let courseIndexKey = courseKeyFromProps(ownProps)
  let indexKey = 'detail'
  let courseKey = courseIndexKey

  return { 
    user: state.user,
    business: getBusinessInfo(state, '0').data,
    courseDetailList: getCourseDetailIndex(state, 'detail-courses', courseKey),
    courseKey: courseKeyFromProps(ownProps),
    conversationList: state.alpha.conversationList,
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  // let topicKey = topicKeyFromProps(ownProps)
  // let indexKey = topicKey ? topicKey : 'default'
  let courseIndexKey = courseKeyFromProps(ownProps)
  let indexKey = 'detail'
  let courseKey = courseIndexKey

  return {
    login: () => { dispatch(login()) },
    fetchCourseDetailList: (refresh=false) => {
      // let params = topicKey ? { topic_f: topicKey } : null
      let params = null
      dispatch(fetchCourseDetailIndex('detail-courses', courseKey, params, courseKey, refresh))
    },
    // fetchCategoryList: (refresh=false) => {
    //   dispatch(fetchCourseIndex('categories', 'default', null, refresh))
    // },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    CourseDetails)
  )
)