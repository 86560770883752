import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Numeral from 'numeral'
import moment from 'moment'

import ImagesSlider from '../ui/ImagesSlider'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const styles = theme => ({
  TrainerCard: {
    flexGrow: 1,
    height: '100%'
  },
  storyPaper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 500,
  },
  storyMedia: {
    width: '100%',
    padding: 0
  },
  storyContent: {
    width: '100%',
    padding: theme.spacing(1) * 2, 
    // position: 'absolute',
    // bottom: 0,
    background: '#808080',
    color: 'white',
    height: 'inherit'
  },
  storyCategory: {
    color: '#aaa',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  headlineTitle: {
    // fontWeight: 100
  },
  nameTitle:{
    // color: 'black',
    fontWeight: 'bold'
  },
  storyLead: {
  },
  storyImage:{
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  storyLikes: {
    color: '#888',
    fontWeight: 400
  },
  storyDate: {
    color: '#aaa',
    fontSize: '.75rem'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  iconPlayProfile:{
    fontSize: '100px',
    color: 'white',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.2)',
    }
  },
  playProfile:{
    textAlign: 'center',
    position: 'absolute',
    top: '30%',
    left: '30%',
    zIndex: 100
  }
})


const TrainerCard = ({ classes, item, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const [urlVideo, setUrlVideo] = React.useState('https://www.youtube.com/embed/LnA7n9qSB7E?autoplay=1&rel=0')
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (

  <Card className={classes.TrainerCard} elevation={16}>  
    <CardActionArea onClick={()=>onClick(item)} className={classes.TrainerCard}>    
      { item.profile_picture_url &&
        <div>
          <CardMedia component="img" className={classes.storyImage} image={ item.profile_picture_url }/>
          {/* <div className={classes.playProfile}>
              <PlayCircleOutlineIcon className={classes.iconPlayProfile} onClick={()=> handleClickOpen()}/>
            </div> */}
        </div>
      }
      { item.images && item.images !== undefined && item.images.length > 0 &&
        <div>
          <ImagesSlider className={classes.storyImage} images={item.images} />
          {/* <div className={classes.playProfile}>
              <PlayCircleOutlineIcon className={classes.iconPlayProfile} onClick={()=> handleClickOpen()}/>
            </div> */}
        </div>
      }
      <CardContent className={classes.storyContent}>
        <Grid container>
        { item.first_name && item.first_name !== undefined &&
          <Grid item xs={12}>
            <Typography className={classes.nameTitle} variant='body1'>
            { item.first_name + " " + item.last_name }
            </Typography>
          </Grid>
        }       
        </Grid>
          
        { item.head_line && item.head_line !== undefined &&
          <Typography className={classes.headlineTitle} variant='body1'>
          { item.head_line }
          </Typography>
        }      
      </CardContent>
    </CardActionArea>
  </Card>    
  )
}

TrainerCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrainerCard);
