import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Fade from 'react-reveal/Fade';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  dark:{
    color: 'white'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionLandingHero:{
    // background: '#acacac',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundSize:'cover',
    minHeight:'100vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      display: 'block'
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
    marginTop: '20px',
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: 'white'
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      background: '#0000007a',
      position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '30vh',
    left: 0
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1)
  },
  detailAbout:{
    // fontWeight: 100,
    width: '50%',
    margin: '0 auto',
    marginBottom: theme.spacing(6)
  },
  chevronDown:{
    //   marginTop: theme.spacing(8),
      borderRadius: '50%',
      border: '3px solid white',
      fontSize: '80px',
      color: 'white'
  },
  contentAbout:{
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      border: '2px solid white',
      background: '#0000007a',
      [theme.breakpoints.down('xs')]:{
        margin: theme.spacing(1),
        padding: theme.spacing(2)
      }
  },
  titleContent:{
      fontWeight: 'bold !important'
  },
  descContent:{
      marginTop: theme.spacing(1),
      fontWeight: 100,
      padding: theme.spacing(3)
  }
});

const AboutContent = ({ classes, topicKey ,background_images, trainerList, history }) => {

    let title = '';
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : 'https://images.unsplash.com/photo-1481151500463-1fa2dd2d5dbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1206&q=80'
    return (
        <div className={clsx(classes.sectionLandingHero,classes.dark)} style={{backgroundImage:`url(`+backgroundImage+`)`}}>
            <Container className={classes.titleSection}>
                <Grid container spacing={3}>
                    <Grid items xs>
                      <Fade>
                        <div className={classes.contentAbout}>
                            <Typography variant='h3' className={classes.titleContent}>
                                For Organizations
                            </Typography>
                            <Typography variant='h6' className={classes.descContent}>
                                From our analysis on years of how trainings
                                which involve external training providers are
                                managed, we understand that managing is
                                not a fast process. Reviewing training
                                provider quality, agreeing on the price and
                                confirming schedule can take weeks. We aim
                                to significantly change that with our
                                platform. With our carefully curated
                                professional trainers combined with our
                                platform, we can significantly increase the
                                processing speed without sacrificing quality.
                            </Typography>
                        </div>
                        </Fade>
                    </Grid>
                    <Grid items xs>
                      <Fade>
                        <div className={classes.contentAbout}>
                            <Typography variant='h3' className={classes.titleContent}>
                                For Professionals
                            </Typography>
                            <Typography variant='h6' className={classes.descContent}>
                                Based on our own experience as
                                professionals in the industry, we know that
                                work can be taxing. Be it from time spent or
                                the commitment it requires, we tend to
                                sacrifice our personal lives a lot more for
                                work. And when it is time for us to retire, we
                                bear the challenge of trying to figure out
                                what’s next. We aim to provide an alternative
                                solution for all of the above. We aim to
                                create an ecosystem where people can
                                utilize their expertise, work flexibly while still
                                earning decent income to live better lives
                            </Typography>
                        </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

AboutContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AboutContent);