import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import {
  Link
} from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionDetailCourse:{
    background: 'white',
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]:{
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    }
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 850,
    margin:"0 auto",
    [theme.breakpoints.down('md')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    }
  },
  titleSection:{
      
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: 'bold'
  },
  detailsCourse:{
    textAlign: 'left'
  },
  objectivesTitle:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  objectivesDetail:{
    marginBottom: theme.spacing(3)
  },
  sylabusTitle:{
    marginBottom: theme.spacing(3)
  },
  sylabusDetail:{
    fontWeight: 'bold !important',
    textAlign: 'center',
    color: '#224A85'
  },
  trainingDay:{
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  syllabusList:{
    borderBottom: '1px solid #dadada',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  courseFeature:{
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2)
  },
  sylabusSection:{
    backgroundColor:'#ebebeb', 
    padding: '0 24px',
    [theme.breakpoints.down('xs')]:{
      padding: '0'
    }
  }
});

const CourseDetail = ({ classes, topicKey ,background_images, courseList }) => {

    let title = '';
    return (
        <div className={clsx(classes.sectionDetailCourse,classes.dark)}>
          {courseList.data.records &&
            <Container>
              <Grid container>
                <Grid md={3} xs={12}></Grid>
                <Grid md={9} xs={12}>
                  <div className={classes.detailsCourse}>
                    <Fade bottom>
                    <Typography variant='h3' className={classes.objectivesTitle}>Objective</Typography>
                    <Typography variant='body1' className={classes.objectivesDetail}>
                      {courseList.data.records.summary}
                    </Typography>
                    </Fade>
                  </div>
                </Grid>
              </Grid>
            </Container>
            }
        </div>
    )
}

CourseDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CourseDetail);