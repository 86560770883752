import React, {useState} from 'react';
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import TrainerDetailCard from '../cards/TrainerDetailCard';
import NextArrow from '../ui/NextArrow'
import PrevArrow from '../ui/PrevArrow'
import Rating from '@material-ui/lab/Rating';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const styles = theme =>({
    dark: {
        color: 'white'
    },
    light: {
        color: 'black'
    },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerList:{
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
      // background: 'white',
      position: 'relative',
    height:'90vh',
    width:'100%',
    display:"block",
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    overflow:"hidden",
    [theme.breakpoints.down('xs')]:{
        minHeight: '80vh',
        height: 'initial'
    }
  },
  carouselItem:{
    maxWidth: '100%',
    height: '100vh',
    margin:0
  },
  titleTrainerList:{
      margin: '5rem 0 1rem',
      fontSize: '36px',
      fontWeight: '400',
      textAlign: 'left'
  },
  fullWidth:{
      width: '100%'
  },
  trainerCard:{
    background: '#808080b3',
    padding: theme.spacing(2.4),
    color: 'white',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]:{
        position: 'relative'
    },
    [theme.breakpoints.between('xs','sm')]:{
        padding: '16px 8px'
    }
    },
    trainerName:{
        fontWeight: 'bold !important',
        letterSpacing: '1px',
        margin: 0,
        [theme.breakpoints.down('md')]:{
            // fontSize: '18px'
        },
        [theme.breakpoints.down('xs')]:{
            textAlign:'center'
          }
    },
    trainerHeadline:{
        letterSpacing: '1px',
        fontWeight: 100,
        [theme.breakpoints.between('sm','md')]:{
        // fontSize: '24px',
        // marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3)
        },
    [theme.breakpoints.down('xs')]:{
        textAlign:'center'
      }
    },
    btnProfile:{
        background: '#ffffff7d',
        padding: '5px',
        borderRadius: '3em',
        color: 'black',
        height: '75px',
        alignItems: 'center',
        width: '90%',
        cursor: 'pointer',
        [theme.breakpoints.between('sm','md')]:{
        textAlign:'center',
        marginBottom: theme.spacing(1)
        // height: '80px',
        // width: '50%'
        },
        [theme.breakpoints.between('xs','sm')]:{
            textAlign:'center',
            width: '100%',
            marginBottom: theme.spacing(1)
            // height: '80px',
            // width: '50%'
            },
        [theme.breakpoints.down('xs')]:{
            textAlign:'center',
            marginBottom: theme.spacing(3),
            width: '95%',
            height: '65px'
        }
    },
    feedBack:{
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]:{
        margin: '0 auto'
    }
    },
    iconProfile:{
        fontSize: '64px',
        color: '#4b62e3',
        [theme.breakpoints.between('sm','md')]:{
            fontSize:'56px'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'48px',
            // marginLeft: theme.spacing(1)
        }
    },
    iconPlayProfile:{
        fontSize: '100px',
        color: 'white',
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.2)',
        },
        border: '2px solid white',
        borderRadius: '50%',
        [theme.breakpoints.down('xs')]:{
            display:'none'
        },
        [theme.breakpoints.between('xs','sm')]:{
            fontSize: '80px'
        }
    },
    durationVideo:{
        // fontWeight: 'bold',
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]:{
            // display: 'none',
            margin: 0
        },
        [theme.breakpoints.between('xs','sm')]:{
            // display: 'none',
            margin: 0
        },
    },
    ratingSystem:{
        [theme.breakpoints.down('sm')]:{
            fontSize: '1.25rem'
        }
    },
    feedbackCount:{
    // fontWeight: 'bold',
    [theme.breakpoints.down('md')]:{
        fontSize: '1rem',
    }
    },
    labelProfile:{
    // fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]:{
        fontSize: '1rem',
        margin:0
        // marginTop: theme.spacing(-2),
    },
    [theme.breakpoints.between('xs','sm')]:{
        margin:0
    }
    },
    introProfile:{
        marginTop: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]:{
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.between('sm','md')]:{
            marginLeft: theme.spacing(4)
        }
    },
    dialogVideo:{
        height: '100vh',
        [theme.breakpoints.down('xs')]:{
            height: '30vh'
        }
    },
    iframeYoutube:{
      position:'absolute',
      top:0,
      left:0,
      bottom:0,
      right:0,
      width:'100%',
      height:'100%',
      border:'none',
      margin:0,
      padding:0,
      overflow:'hidden',
      zIndex:999999
    },
    playProfile:{
        textAlign: 'center',
        position: 'absolute',
        top: '40%',
        width: '100px',
        left: 0,
        right: 0,
        margin: '0 auto',
    }
});

const DetailTrainer = ({classes, name, headline, videoLink, ratingValue, feedbackCount}) => {
//   const [ratingValue, setRatingValue] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  let url = ''
  let resultUrl = ''
  if(videoLink !== undefined){
    url = videoLink.split("?")[1]
    resultUrl = url.substring(2)
  }

  const [urlVideo, setUrlVideo] = React.useState('https://www.youtube.com/embed/'+resultUrl+'?autoplay=1&rel=0')
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div className={classes.trainerCard}>
                <Container>
                    <Grid container>
                        <Grid item md={8} sm={9} xs={12}>
                            <Typography className={classes.trainerName} variant="h4">
                                {name}
                            </Typography>
                            <Typography className={classes.trainerHeadline} variant="h4">
                                {headline}
                            </Typography>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Grid container className={classes.introProfile}>
                            {url !== '' &&
                                <Grid item md={6} sm={12} xs={6}>
                                    <Grid container className={classes.btnProfile} onClick={()=> handleClickOpen()}>
                                        <Grid item md={4} xs={4}>
                                            <PlayArrowIcon className={classes.iconProfile}/>
                                        </Grid>
                                        <Grid item md={8} xs={8}>
                                            <Typography className={classes.durationVideo}>1:07</Typography>
                                            <Typography className={classes.labelProfile}>My Profile</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> 
                                }
                                <Grid item md={6} sm={12} xs={6}>
                                    <Grid container className={classes.btnProfile}>
                                        <Grid item md={12} className={classes.feedBack}>
                                            <Rating name="disabled" value={ratingValue} disabled className={classes.ratingSystem} precision={0.5}/>
                                            <Typography className={classes.feedbackCount}>{feedbackCount || 0} Feedback</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.playProfile} onClick={()=> handleClickOpen()}>
                <PlayArrowIcon className={classes.iconPlayProfile}/>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent className={classes.dialogVideo}>
                    <iframe className={classes.iframeYoutube} src={urlVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </DialogContent>
            </Dialog>
        </>
  )
}

const TrainerHero = ({ classes, hashKey, theme, body, items, variant, images, background_images, link_to, link_title, openLink, trainerList }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow type='trainer-profile'/>,
        prevArrow: <PrevArrow type='trainer-profile'/>,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false
                }
            }
        ]
      };

      console.log(trainerList)

    let title = '';
    return (
        <div className={clsx(classes.sectionTrainerList,classes.light)}>
            {trainerList.data.records &&
            <>
            <Slider {...settings} className={classes.fullWidth}>
                {trainerList.data.records.landing_images.map(item =>
                <TrainerDetailCard item={item}/>
                )}
            </Slider>
            <DetailTrainer classes={classes} videoLink={trainerList.data.records.profile_video_link} ratingValue={trainerList.data.records.user_review_avg_score} feedbackCount={trainerList.data.records.user_review_count} name={trainerList.data.records.first_name +" "+trainerList.data.records.last_name} headline={trainerList.data.records.head_line}/>
            </>
            }
        </div>
    )
}

TrainerHero.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles, { withTheme: true })(TrainerHero);