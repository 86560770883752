import React, { useState, useEffect, useContext } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
  },
  imagesSlider: {
  },
  imageDiv: {
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  },
});

const ImagesSlider = ({ classes, images }) => {

  const settings = {
    className: classes.imagesSlider,
    infinite: true, 
    dots: true,
    arrows: false,
//    slidesPerRow: 1,
//    centerPadding: "0px",
//    centerMode: false,
    slidesToScroll: 1,
    slidesToShow: 1,
//    swipeToSlide: true,
    afterChange: function(index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    }
  };

  
  return (
    <div className={classes.root}>
      { images.length == 1 ?
          <div className={classes.imageDiv}>
          <img src={ images[0].image_url } className={classes.image} />
          </div>      
        :
        <Slider {...settings}>
        { images.map(image =>    
          <div className={classes.imageDiv}>
          <img src={ image.image_url } className={classes.image} />
          </div>
        )}    
        </Slider>
      }
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ImagesSlider)