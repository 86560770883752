import React, { useState, useEffect, useContext } from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getContentIndex, fetchContentIndex } from './alpha/data/reducers/content'

import { withStyles } from '@material-ui/core/styles'

import { NavigationContext } from './components/ui/NavigationController'

import PageMenu from './PageMenu'
import Inbox from './pages/Inbox'
import User from './pages/User'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Drawer from '@material-ui/core/Drawer'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core//AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CollectionsIcon from '@material-ui/icons/Collections'
import HomeIcon from '@material-ui/icons/Home'
import Chat from './pages/Chat'

const styles = theme => ({
  root: {
    position: 'fixed',
  },
  title: {
    fontSize: 24,
  },
  placeholder: {
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 74,
    },
  },
  appbar: {
//    color: theme.palette.common.black,
//    backgroundColor: theme.palette.common.white,
    background: '#0000001c'
  },
  appbarBackground: {
    position: 'fixed',
    width: '100%',
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 74,
    },
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 70,
      // color: 'white'
    },
  },
  logoBox: {
    paddingTop: theme.spacing(1),
  },
  logo: {
    cursor: 'pointer',
    maxHeight: 32,
    [theme.breakpoints.up('md')]: {
      maxHeight: 44
    },
    [theme.breakpoints.between('xs','sm')]:{
      maxHeight: 32
    }
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
//    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]:{
      marginRight: theme.spacing(-1)
    }
  },
  rightLink: {
    fontSize: 16,
//    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  menuButton: {
//    marginLeft: 12,
//    marginRight: 12,
  },
  menuButtonHidden: {
    display: 'none',
  },
  desktopHidden: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },  
  mobileHidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  iconBtn:{
    [theme.breakpoints.down('xs')]: {
      padding: '8px'
    }
  },
  iconBtnInbox:{
    display:'block',
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
      display:'none'
    }
  },
  iconBtnMobile:{
    display:'none',
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
      display: 'block'
    }
  }
});

const StyledBadge2 = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #44b700',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const Header = ({ classes, path, history, theme, business, inboxStats, pageList, fetchPageList, dark=false }) => {

  const navigationContext = useContext(NavigationContext)

  const [isDrawerOpen, showDrawer] = useState(false)
  const [transparentBar, setTransparentBar] = useState(true)

  const openChat = (key) => {
    navigationContext.showPopupContent(
      <Chat conversationKey={key} openChat={ openChat } />
      )
  }

  const updateStyleOnScroll = () => {
    if (window.pageYOffset > 0) {
      if (transparentBar)
        setTransparentBar(false)
    }
    else if (!transparentBar)
      setTransparentBar(true)
  }
  
  useEffect(() => {
    fetchPageList()
    window.addEventListener("scroll", updateStyleOnScroll);      
  })
  
  let appbarStyle = {}
  let appbarBackgroundStyle = {}
  if (transparentBar) {
    // if (dark)
      appbarStyle['color'] = theme.palette.common.white
    // else
    //   appbarStyle['color'] = '#555'
    appbarBackgroundStyle = {
      background: 'transparent'
    }
  }
  else {
    appbarStyle = {
      color: theme.palette.primary.main,
    }  
    appbarBackgroundStyle = {
      backgroundColor: theme.palette.common.white,
    }
  }
      
  let inboxIcon = (
    <QuestionAnswerIcon data-tut="reactour__logo"/>
    )
  if (inboxStats && inboxStats !== undefined && inboxStats.new_count !== undefined && inboxStats.new_count > 0)
  // if(inboxStats && inboxStats !== undefined)
    inboxIcon = (
      <Badge badgeContent={ inboxStats.new_count } color="secondary">
        { inboxIcon }
      </Badge>
    )
    
    // const [isRun, showRun] = useState(localStorage.getItem('isHighlight') || false)

    const tourConfig = [
      {
        target: '[data-tut="reactour__logo"]',
        content: 'Click here to start a chat with operator.',
        disableBeacon : true,
        placement: 'right-end'
      }
    ]
  
    const handleJoyrideCallback = data => {
      const { action, index, status, type } = data;
  
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        localStorage.setItem('isHighlight', false);
        // showRun(false)
      }
    };
  
  let userIcon = (
    // <AccountCircleIcon />
    // <StyledBadge2
    // overlap="circle"
    // anchorOrigin={{
    //   vertical: 'top',
    //   horizontal: 'left',
    // }}
    // variant="dot"
    // >
        <AccountCircleIcon />
    //   </StyledBadge2>
    )
  
  let highlightData = localStorage.getItem('isHighlight') ? false : true;
  const [popoverContent, setPopoverContent] = useState(null)
  
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar} style={appbarStyle} elevation={0}>
        {/* <Joyride
          steps={tourConfig}
          run={highlightData}
          callback={handleJoyrideCallback}
        /> */}
        <Fade in={!transparentBar}>
          <Box className={classes.appbarBackground} style={appbarBackgroundStyle}>          
          </Box>
        </Fade>
        <Container style={{ padding: 0 }}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => showDrawer(!isDrawerOpen)}
                className={clsx(
                  classes.menuButton,
                  isDrawerOpen && classes.menuButtonHidden,
                  classes.desktopHidden
                )}
              >
                <MenuIcon />
              </IconButton>
          { false && pageList.lastFetched &&
            pageList.data.records.map((page) =>
            <Button className={classes.mobileHidden} color="inherit" onClick={() => history.push('/page/' + page.key)}>
              { page.title }
            </Button>
            )
          }
          {/* <Button className={classes.mobileHidden} color="inherit" onClick={() => history.push('/')}>
            Home
          </Button> */}
          {/* <Button className={classes.mobileHidden} color="inherit" onClick={() => history.push('/page/about-pasar-trainer')}>
            About
          </Button> */}
          
          <Button className={classes.mobileHidden} color="inherit">
          <RouterLink to={'/course'} style={{color:'inherit', textDecoration:'none'}}>
            Courses
          </RouterLink>
          </Button>
          <Button className={classes.mobileHidden} color="inherit">
          <RouterLink to={'/trainer'} style={{color:'inherit', textDecoration:'none'}}>
            Trainers
          </RouterLink>
          </Button>
          {/* <Button className={classes.mobileHidden} color="inherit" onClick={() => history.push('/article')}>
            Articles
          </Button> */}
          <Button className={classes.mobileHidden} color="inherit">
          <RouterLink to={'/page/about-pasar-trainer'} style={{color:'inherit', textDecoration:'none'}}>
            About Us
          </RouterLink>
          </Button>
          
          </div>
          
          { false &&
          <a href="/">
            <img src='https://images.ctfassets.net/g3w9ev985id2/5mC2QamRzoUVjo9xsHvGTl/cecb020337ff12b8bc4bdfa98d828ee5/8FA65AA9-76A9-48E4-B9F2-D0A5443E7681.png' className={classes.logo}/>          
          </a>
          }
          
          <Box className={classes.logoBox}>
          <a href="/">
            <img src={business.logo.image_url} className={classes.logo}/>          
          </a> 
          </Box>
          <div className={clsx(classes.right)}>
            <IconButton className={classes.iconBtnInbox} color='inherit' 
              onClick={() => navigationContext.showSideContent(
                <Inbox />
              )}
              >
              { inboxIcon }
            </IconButton>

            <IconButton className={classes.iconBtnMobile} color='inherit' 
              onClick={() => history.push("/inbox")}
              >
              { inboxIcon }
            </IconButton>
            
            <IconButton className={classes.iconBtn} color='inherit' 
              onClick={() => navigationContext.showSideContent(
                <User />
              )}
              >
              { userIcon }
            </IconButton>
          </div>
        </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.placeholder} />
      <Drawer open={isDrawerOpen} onClose={() => showDrawer(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => showDrawer(false)}
          onKeyDown={() => showDrawer(false)}
        >
          <PageMenu />
        </div>
      </Drawer>      
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return { 
    pageList: getContentIndex(state, 'page', 'default'),
    path: ownProps.location.pathname,
    inboxStats: state.alpha.conversationList.stats    
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPageList: (refresh=false) => {
      dispatch(fetchContentIndex('page', 'default', null, refresh))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(
    Header)
  )
)
