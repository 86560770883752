import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import CourseTopic from '../cards/CourseTopic';
import NextArrow from '../ui/NextArrow'
import PrevArrow from '../ui/PrevArrow'
import { connect } from 'react-redux'
import { getCourseIndex, fetchCourseIndex } from '../../data/reducers/course'
import {Link} from 'react-router-dom'
import { Button } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  dark:{ 
    color: 'white'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionCreateClass:{
    backgroundSize:'cover',
    minHeight:'100vh',
    minWidth:'100%',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center'
  },
  carouselItem:{
    // padding: "0 80px",
    maxWidth: 1280,
    margin:"0 auto",
    zIndex: 0,
    marginTop: theme.spacing(3),
    [theme.breakpoints.between('md','sm')]:{
      marginTop: theme.spacing(3),
      padding: 0,
    },
    [theme.breakpoints.down('xs')]:{
      // marginRight: theme.spacing(1) * -2,
      // marginLeft: theme.spacing(1) * -2,
      marginBottom: theme.spacing(3)
    }
  },
  viewAllCourses:{
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  linkCourse:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  titleSection:{
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]:{
      marginTop: theme.spacing(10)
    }
  },
  btnViewAll:{
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '16px'
  }
});

const CourseList = ({ classes, hashKey, theme, body, items, variant, images, background_images, fetchCoursesList, link_to, link_title, openLink, courseList, history, topicKey }) => {
    
    // useEffect(() => {
    //   fetchCoursesList()    
    // },[])

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 5000,
      variableWidth: true,
      nextArrow: <NextArrow type='course'/>,
      prevArrow: <PrevArrow type='course'/>,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            // slidesToShow: 1,
            // slidesToScroll: 2,
            arrows: false,
            rows: 1,
            slidesPerRow: 2,
            dots: true,
            centerMode: true,
            infinite: true,
            centerPadding: "-5px",
            slidesToShow: 2,
          }
        },
      ]
    };

    return (
        <div className={clsx(classes.sectionCreateClass,classes.dark)} style={{backgroundImage:`url(`+background_images[0].image_url+`)`}}>
            <Container>
                <div className={classes.titleSection}>
                  <Fade>
                  <Markdown source={ body }/>
                  </Fade>
                </div>
                
                
                <Slider {...settings} className={classes.carouselItem}>
                { courseList.lastFetched ?
                  courseList.data.records.categories.map(item =>
                    <Fade>
                    <CourseTopic item={item} history={history}/>
                    </Fade>
                ) 
                :
                <Skeleton variant="rect" width={210} height={118} />
                }
                </Slider>
                <Fade bottom>
                <div className={classes.viewAllCourses}>
                  <Link to='/course' className={classes.linkCourse}>
                    <Typography variant='h5'>
                      View All Courses
                    </Typography>
                  </Link>
                </div>
                </Fade>
            </Container>
        </div>
    )
}

function topicKeyFromProps(props) {

  let key = null
  if (props.match && props.match.params.topicKey !== undefined)
    key = props.match.params.topicKey
  return key
}

const mapStateToProps = (state, ownProps) => {
  let topicKey = topicKeyFromProps(ownProps)
  let indexKey = topicKey ? topicKey : 'default'
  return { 
    courseList: getCourseIndex(state, 'courses', indexKey),
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let topicKey = topicKeyFromProps(ownProps)
  let indexKey = topicKey ? topicKey : 'default'
  return {
    fetchCoursesList: (refresh=false) => {
      let params = topicKey ? { key_path: 'default/'+topicKey } : null
      dispatch(fetchCourseIndex('courses', indexKey, params, null, refresh))
    },
  }
}

CourseList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true})(CourseList))