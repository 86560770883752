import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from "react-slick";
import PropTypes from 'prop-types'
import Markdown from '../ui/Markdown'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import { Box } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import { Button, Grid } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sectionTrainerStep:{
    // background: '#acacac',
    // paddingTop: theme.spacing(9),
    // marginBottom: theme.spacing(3),
    backgroundSize:'cover',
    minHeight:'0vh',
    minWidth:'100%',
    // alignItems:"center",
    // display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    position: 'relative',
    // background: 'white'
  },
  titleSection:{
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]:{
      width:'100%'
    }
    // marginTop: '70px',
  },
  backdropClass:{
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '60vh',
    alignItems:"center",
    display:"flex",
    overflow: "hidden",
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
        display:'block',
        paddingBottom:0
    }
  },
  itemTraining:{
    width: 250, 
    height: 250,
    opacity: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      width: 110, 
      height: 110,
    },
  },
  trainingCard:{
    width: 200, 
    height: 200,
    boxShadow: '-5px 5px 0px 0px rgba(255,255,255,0.75)',
    position: 'relative',
    [theme.breakpoints.down('xs')]:{
      width: 100, 
      height: 100,
    }
  },
  categoriesData:{
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: '10px'
  },
  titleCategory:{
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]:{
      fontSize: '11px',
    }
  },
  countCategory:{
    marginBottom: 0
  },
  categoryName:{
    fontWeight: '100',
    marginBottom: theme.spacing(6)
    // paddingTop: '70px'
  },
  viewAll:{
    textDecoration: 'none',
    color: '#ff6308'
  },
  btnGetStarted:{
      marginTop: theme.spacing(6),
      width: '200px',
      height: '50px',
      fontSize: '16px'
  },
  descLanding:{
      display: 'flex',
      alignItems: 'center',
      color: '#6a6a6a',
      marginTop: theme.spacing(6)
  },
  iconLanding:{
      fontSize:'64px',
      marginBottom:theme.spacing(1),
  },
  titleStep:{
      fontWeight: 'bold',
      padding: '0 16px',
  },
  descStep:{
      padding: '0 40px'
  },
  boxItem:{
      width: '50%',
      [theme.breakpoints.down('xs')]:{
          width: '100%'
      }
  },
  boxDetail:{
    padding:'10vh 80px',
    [theme.breakpoints.down('xs')]:{
        padding:'5vh 16px'
    }
  }
});

const LeftRightContent = ({ classes, topicKey ,background_images, trainerList, history, body, body1, items, variant, link_to, link_to1, link_title, link_title1, openLink, openLink1 }) => {

    let title = '';
    let rootStyle = {}
    let rootColor = {}
    let rootDesc = {}
    let backdropStyle = {}
    let categoryName = topicKey !== undefined ? topicKey : 'all-trainer'; 
    let backgroundImage = background_images !== undefined ?  background_images : ''
    
    if(variant !== 'dark'){
      rootStyle['color'] = 'black'
      rootColor['color'] = 'black'
    //   rootDesc['color'] = '#999'
      if(backgroundImage){
        // rootStyle['background'] = 'url('+backgroundImage+')'
      }else{
        rootStyle['background'] = 'white'
      }
      rootStyle['backgroundRepeat'] = 'no-repeat'
      rootStyle['backgroundSize'] = 'cover'
      // backdropStyle['background'] = '#7473737a'
      backdropStyle['height'] = '100%'
  }else{
      rootStyle['color'] = 'white'
      rootColor['color'] = 'white !important'
    //   rootDesc['color'] = '#eee'
      if(backgroundImage){
        rootStyle['background'] = 'url('+backgroundImage+')'
      }else{
        rootStyle['background'] = 'white'
      }
      rootStyle['backgroundRepeat'] = 'no-repeat'
      rootStyle['backgroundSize'] = 'cover'
      backdropStyle['height'] = '100%'
      backdropStyle['background'] = '#0000007a'
  }
    
    return (
        <div className={classes.sectionTrainerStep} style={rootStyle}>
          <div className={classes.backdropClass} style={backdropStyle}>
            <Box className={classes.boxItem} alignItems='center' justifyContent='center'>
                <Box className={classes.boxDetail} style={{textAlign:'center'}}>
                    <Markdown source={body} style={rootColor}/>
                    { link_to && link_to !== undefined &&
                        <Box align="center">
                            <p/>
                            <Button variant="contained" color="primary" onClick={ openLink }  size='large'>
                            { link_title }
                            </Button>
                        </Box>
                    } 
                </Box>                
            </Box>  
            <Box className={classes.boxItem} alignItems='center' justifyContent='center'>
                <Box className={classes.boxDetail} style={{textAlign:'center'}}>
                    <Markdown source={body1}/>
                    { link_to1 && link_to1 !== undefined &&
                        <Box align="center">
                            <p/>
                            <Button variant="contained" color="primary" onClick={ openLink1 }  size='large'>
                            { link_title1 }
                            </Button>
                        </Box>
                        } 
                </Box>                
            </Box>  
          </div>
        </div>
    )
}

LeftRightContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LeftRightContent);